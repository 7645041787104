// import React, { useState, useEffect } from 'react';
// import { doc, updateDoc, collection, addDoc, getDocs, orderBy, limit, query, getDoc } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import { db, Storage, auth } from '../firebase';
// import '../style/NeckLabels.css';
// import Header from '../components/Header';
// import Sidebar from '../components/Sidebar';

// const NeckLabels = () => {
//   const [user, setUser] = useState(null);
//   const [isNeckLabelEnabled, setIsNeckLabelEnabled] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [uploadedDesignURL, setUploadedDesignURL] = useState(null);

//   // Authenticate user on component mount
//   useEffect(() => {
//     const currentUser = auth.currentUser;
//     if (currentUser) {
//       setUser(currentUser);
//     }
//   }, []);

//   // Fetch initial neck label status and uploaded design
//   useEffect(() => {
//     const fetchUserData = async () => {
//       if (!user) return;

//       try {
//         const userDocRef = doc(db, 'users', user.uid);
//         const userDoc = await getDoc(userDocRef);
//         const userData = userDoc.data();
        
//         // Set neck labels status
//         setIsNeckLabelEnabled(userData?.neckLabels || false);

//         // Fetch the most recent uploaded design
//         const brandingRef = collection(db, 'users', user.uid, 'branding');
//         const brandingQuery = await getDocs(query(brandingRef, orderBy('timestamp', 'desc'), limit(1)));
        
//         if (!brandingQuery.empty) {
//           const latestDesign = brandingQuery.docs[0].data();
//           setUploadedDesignURL(latestDesign.neckLabelDesign);
//         }
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     };

//     fetchUserData();
//   }, [user]);

//   // If user is not authenticated, show login prompt
//   if (!user) {
//     return (

      
//       <div className="neck-labels-container">
//         <div className="error-message">
//           <p>Please log in to access neck labels</p>
//         </div>
//       </div>
//     );
//   }

//   // Toggle neck labels in Firestore
//   const handleToggleNeckLabels = async () => {
//     try {
//       const userDocRef = doc(db, 'users', user.uid);
//       await updateDoc(userDocRef, {
//         neckLabels: !isNeckLabelEnabled
//       });
//       setIsNeckLabelEnabled(!isNeckLabelEnabled);
//     } catch (error) {
//       console.error("Error updating neck labels status:", error);
//     }
//   };

//   // Handle file selection
//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       // Create a preview of the file
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setSelectedFile({
//           file: file,
//           preview: reader.result
//         });
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   // Upload design to Firebase Storage and Firestore
//   const handleDesignUpload = async () => {
//     if (!selectedFile) {
//       alert('Please select a file first');
//       return;
//     }

//     try {
//       // Create a storage reference
//       const storageRef = ref(Storage, `neck_labels/${user.uid}/${selectedFile.file.name}`);
      
//       // Upload file
//       const uploadTask = uploadBytesResumable(storageRef, selectedFile.file);
      
//       uploadTask.on('state_changed', 
//         (snapshot) => {
//           // Track upload progress
//           const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//           setUploadProgress(progress);
//         },
//         (error) => {
//           console.error("Upload error:", error);
//         },
//         async () => {
//           // Get download URL after successful upload
//           const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          
//           // Store design metadata in Firestore
//           const brandingRef = collection(db, 'users', user.uid, 'branding');
//           await addDoc(brandingRef, {
//             productName: 'Neck Label',
//             neckLabelDesign: downloadURL,
//             timestamp: new Date()
//           });

//           // Update uploaded design URL
//           setUploadedDesignURL(downloadURL);

//           // Reset state
//           setSelectedFile(null);
//           setUploadProgress(0);
//           alert('Design uploaded successfully!');
//         }
//       );
//     } catch (error) {
//       console.error("Error uploading design:", error);
//     }
//   };

//   return (

//         <div className="contact-us">
//           <Header />
//           <div className="contact-container">
//             <Sidebar />
//             <div className="main-content">
//     <div className="neck-labels-container">
//       <div className="neck-labels-header">
//         <h2>Neck Labels</h2>
        
//         <div className="toggle-switch">
//           <input 
//             type="checkbox" 
//             id="neck-label-toggle"
//             checked={isNeckLabelEnabled} 
//             onChange={handleToggleNeckLabels} 
//           />
//           <label htmlFor="neck-label-toggle" className="toggle-slider"></label>
//           <span className="toggle-label">
//             {isNeckLabelEnabled ? 'Enabled' : 'Disabled'}
//           </span>
//         </div>
//       </div>
//       <p>We print your Neck labels On Demand</p>

//       {isNeckLabelEnabled && (
//         <div className="file-upload-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//           <div 
//             className="file-upload-container"
//             style={{
//               width: '2in',
//               height: '2in',
//               border: '1px dashed #ccc',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               position: 'relative',
//               overflow: 'hidden',
//               marginBottom: '10px'
//             }}
//           >
//             {/* Upload icon or uploaded image */}
//             {!selectedFile && !uploadedDesignURL ? (
//               <input 
//                 type="file" 
//                 id="file-upload"
//                 accept="image/*"
//                 onChange={handleFileChange}
//                 style={{
//                   position: 'absolute',
//                   width: '100%',
//                   height: '100%',
//                   opacity: 0,
//                   cursor: 'pointer'
//                 }}
//               />
//             ) : null}

//             {!selectedFile && !uploadedDesignURL && (
//               <svg 
//                 xmlns="http://www.w3.org/2000/svg" 
//                 width="24" 
//                 height="24" 
//                 viewBox="0 0 24 24" 
//                 fill="none" 
//                 stroke="currentColor" 
//                 strokeWidth="2" 
//                 strokeLinecap="round" 
//                 strokeLinejoin="round"
//               >
//                 <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
//                 <polyline points="17 8 12 3 7 8" />
//                 <line x1="12" x2="12" y1="3" y2="15" />
//               </svg>
//             )}

//             {/* Show selected file preview or uploaded design */}
//             {(selectedFile || uploadedDesignURL) && (
//               <img 
//                 src={selectedFile?.preview || uploadedDesignURL} 
//                 alt="Uploaded design" 
//                 style={{
//                   width: '100%',
//                   height: '100%',
//                   objectFit: 'cover'
//                 }}
//               />
//             )}
//           </div>

//           {/* Submit button moved below the upload box */}
//           {!uploadedDesignURL && (
//             <button 
//               onClick={handleDesignUpload}
//               disabled={!selectedFile}
//               style={{
//                 backgroundColor: selectedFile ? '#007bff' : '#cccccc',
//                 color: 'white',
//                 border: 'none',
//                 padding: '10px 20px',
//                 borderRadius: '5px',
//                 cursor: selectedFile ? 'pointer' : 'not-allowed'
//               }}
//             >
//               Submit
//             </button>
//           )}

//           {uploadProgress > 0 && (
//             <div className="file-progress">
//               <div 
//                 className="file-progress-bar" 
//                 style={{ width: `${uploadProgress}%` }}
//               ></div>
//               <span className="progress-text">
//                 {`Uploading: ${Math.round(uploadProgress)}%`}
//               </span>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//     </div>
//     </div>
//     </div>
 
//   );
// };

// export default NeckLabels;


import React, { useState, useEffect } from 'react';
import { doc, updateDoc, collection, addDoc, getDocs, orderBy, query, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, Storage, auth } from '../firebase';
import '../style/NeckLabels.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const NeckLabels = () => {
  const [user, setUser] = useState(null);
  const [isNeckLabelEnabled, setIsNeckLabelEnabled] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedDesigns, setUploadedDesigns] = useState([]);

  // Authenticate user on component mount
  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser(currentUser);
    }
  }, []);

  // Fetch initial neck label status and uploaded designs
  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;

      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
        
        // Set neck labels status
        setIsNeckLabelEnabled(userData?.neckLabels || false);

        // Fetch all uploaded designs
        const brandingRef = collection(db, 'users', user.uid, 'branding');
        const brandingQuery = await getDocs(query(brandingRef, orderBy('timestamp', 'desc')));
        
        const designs = brandingQuery.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setUploadedDesigns(designs.filter(design => design.neckLabelDesign));
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user]);

  // If user is not authenticated, show login prompt
  if (!user) {
    return (
      <div className="neck-labels-container">
        <div className="error-message">
          <p>Please log in to access neck labels</p>
        </div>
      </div>
    );
  }

  // Toggle neck labels in Firestore
  const handleToggleNeckLabels = async () => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        neckLabels: !isNeckLabelEnabled
      });
      setIsNeckLabelEnabled(!isNeckLabelEnabled);
    } catch (error) {
      console.error("Error updating neck labels status:", error);
    }
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a preview of the file
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile({
          file: file,
          preview: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // Upload design to Firebase Storage and Firestore
  const handleDesignUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first');
      return;
    }

    try {
      // Create a storage reference
      const storageRef = ref(Storage, `neck_labels/${user.uid}/${selectedFile.file.name}`);
      
      // Upload file
      const uploadTask = uploadBytesResumable(storageRef, selectedFile.file);
      
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Track upload progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          // Get download URL after successful upload
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          
          // Store design metadata in Firestore
          const brandingRef = collection(db, 'users', user.uid, 'branding');
          const docRef = await addDoc(brandingRef, {
            productName: 'Neck Label',
            neckLabelDesign: downloadURL,
            timestamp: new Date()
          });

          // Update uploaded designs
          const newDesign = {
            id: docRef.id,
            productName: 'Neck Label',
            neckLabelDesign: downloadURL,
            timestamp: new Date()
          };
          setUploadedDesigns(prev => [newDesign, ...prev]);

          // Reset state
          setSelectedFile(null);
          setUploadProgress(0);
          alert('Design uploaded successfully!');
        }
      );
    } catch (error) {
      console.error("Error uploading design:", error);
    }
  };

  return (
    <div className="contact-us">
      <Header />
      <div className="contact-container">
        <Sidebar />
        <div className="main-content">
          <div className="neck-labels-container">
            <div className="neck-labels-header">
              <h2>Neck Labels</h2>
              
              <div className="toggle-switch">
                <input 
                  type="checkbox" 
                  id="neck-label-toggle"
                  checked={isNeckLabelEnabled} 
                  onChange={handleToggleNeckLabels} 
                />
                <label htmlFor="neck-label-toggle" className="toggle-slider"></label>
                <span className="toggle-label">
                  {isNeckLabelEnabled ? 'Enabled' : 'Disabled'}
                </span>
              </div>
            </div>
            <p>We print your Neck labels On Demand <br></br>₹5 per piece</p>
            
     
            {isNeckLabelEnabled && (
              <div className="file-upload-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div 
                  className="file-upload-container"
                  style={{
                    width: '2in',
                    height: '2in',
                    border: '1px dashed #ccc',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                    marginBottom: '10px'
                  }}
                >
                  {/* Upload icon or uploaded image */}
                  {!selectedFile && (
                    <input 
                      type="file" 
                      id="file-upload"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer'
                      }}
                    />
                  )}

                  {!selectedFile && (
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="24" 
                      height="24" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                      <polyline points="17 8 12 3 7 8" />
                      <line x1="12" x2="12" y1="3" y2="15" />
                    </svg>
                  )}

                  {/* Show selected file preview */}
                  {selectedFile && (
                    <img 
                      src={selectedFile.preview} 
                      alt="Selected design" 
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  )}
                </div>

                <p style={{ textAlign: 'center', marginTop: '10px', fontSize: '17px', color: '#555' }}>
  2 X 2 inches
</p>

                {/* Submit button */}
                {selectedFile && (
                  <button 
                    onClick={handleDesignUpload}
                    style={{
                      backgroundColor: '#007bff',
                      color: 'white',
                      border: 'none',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      marginBottom: '15px'
                    }}
                  >
                    Submit
                  </button>
                )}

                {/* Upload progress */}
                {uploadProgress > 0 && (
                  <div 
                    className="file-progress" 
                    style={{ 
                      width: '100%', 
                      marginTop: '15px' 
                    }}
                  >
                    <div 
                      className="file-progress-bar" 
                      style={{ 
                        width: `${uploadProgress}%`, 
                        height: '10px', 
                        backgroundColor: '#007bff' 
                      }}
                    ></div>
                    <span className="progress-text">
                      {`Uploading: ${Math.round(uploadProgress)}%`}
                    </span>
                  </div>
                )}

                {/* Uploaded Designs List */}
                {uploadedDesigns.length > 0 && (
                  <div 
                    className="uploaded-designs-list" 
                    style={{ 
                      width: '100%', 
                      marginTop: '20px' 
                    }}
                  >
                    <h3>Uploaded Neck Labels</h3>
                    <div 
                      style={{ 
                        display: 'flex', 
                        flexWrap: 'wrap', 
                        gap: '10px' 
                      }}
                    >
                      {uploadedDesigns.map((design, index) => (
                        <div 
                          key={design.id} 
                          style={{ 
                            width: '100px', 
                            height: '100px', 
                            border: '1px solid #ddd' 
                          }}
                        >
                          <img 
                            src={design.neckLabelDesign} 
                            alt={`Neck Label ${index + 1}`} 
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeckLabels;