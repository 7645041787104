import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import '../style/products.css';

const CreateOrderList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopId = queryParams.get('shop');

  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const designsPerPage = 7;

  const navigate = useNavigate();
  const user = auth.currentUser;

  useEffect(() => {
    if (!user) {
      // If user is not logged in, show error and redirect to login
      setError('User not logged in. Please log in.');
      navigate('/login'); // Redirect to login page
    } else {
      // User is logged in, fetch data
      const fetchDesigns = async () => {
        try {
          const designsCollection = collection(db, 'users', user.uid, 'designs');
          const designSnapshot = await getDocs(designsCollection);
          const designList = designSnapshot.docs.map(doc => {
            const data = doc.data();
            // Check if the design has color variants
            const hasVariants = data.colorVariants && Array.isArray(data.colorVariants) && data.colorVariants.length > 0;
            return { 
              id: doc.id, 
              ...data,
              hasVariants,
              variantCount: hasVariants ? data.colorVariants.length : 0
            };
          });
          setDesigns(designList);
        } catch (error) {
          console.error("Error fetching designs:", error);
          setError("Failed to load designs. Please try again.");
        } finally {
          setLoading(false);
        }
      };

      fetchDesigns();
    }
  }, [user, navigate]);

  // Calculate pagination
  const totalPages = Math.ceil(designs.length / designsPerPage);
  const indexOfLastDesign = currentPage * designsPerPage;
  const indexOfFirstDesign = indexOfLastDesign - designsPerPage;
  const currentDesigns = designs.slice(indexOfFirstDesign, indexOfLastDesign);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePlaceOrder = (design, variant = null) => {
    if (variant) {
      // If a specific variant is selected, pass that variant data
      navigate('/create-order', { 
        state: { 
          design: {
            ...design,
            ...variant,
            frontImage: variant.images.front,
            backImage: variant.images.back,
            variantData: variant
          } 
        }
      });
    } else {
      // If no variant specified, pass the main design
      navigate('/create-order', { state: { design } });
    }
  };

  if (loading) {
    return <div className="loading-message">Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="products-page">
      <Header />
      <div className="products-container">
        <Sidebar />
        <div className="main-content">
          <Link to={'/selecttshirt'} className="create-button">Create Product</Link>
          {designs.length === 0 ? (
            <p>No designs uploaded yet.</p>
          ) : (
            <>
              <table className="design-table">
                <thead>
                  <tr>
                    <th>Design Name</th>
                    <th>Product</th>
                    <th>Front Image</th>
                    <th>Back Image</th>
                    <th>Total Price</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentDesigns.map(design => {
                    if (!design.hasVariants) {
                      // Regular design without variants
                      return (
                        <tr key={design.id} className="design-row">
                          <td>{design.designName}</td>
                          <td>{design.productName}</td>
                          <td><img src={design.images?.front} alt="Front Design" width="50" /></td>
                          <td><img src={design.images?.back} alt="Back Design" width="50" /></td>
                          <td>{design.totalPrice}</td>
                          <td>
                            <button onClick={() => handlePlaceOrder(design)} className='Book-a-sample'>
                              Place Order
                            </button>
                          </td>
                        </tr>
                      );
                    } else {
                      // Design with variants - show only variants with rowspan for design name
                      return design.colorVariants.map((variant, index) => (
                        <tr key={`${design.id}-variant-${index}`} className="variant-row">
                          {index === 0 ? (
                            // Only first variant row gets the design name with rowspan
                            <td rowSpan={design.colorVariants.length} className="design-name">
                              {design.designName}
                            </td>
                          ) : null}
                          <td>
                            {design.productName}  {variant.productName || `Color ${index + 1}`}
                          </td>
                          <td><img src={variant.images?.front} alt="Front Variant" width="50" /></td>
                          <td><img src={variant.images?.back} alt="Back Variant" width="50" /></td>
                          <td>{variant.totalPrice || design.totalPrice}</td>
                          <td>
                            <button 
                              onClick={() => handlePlaceOrder(design, variant)} 
                              className="Book-a-sample"
                            >
                              Place Order
                            </button>
                          </td>
                        </tr>
                      ));
                    }
                  })}
                </tbody>
              </table>
              <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateOrderList;