  import React, { useState, useEffect, useRef } from 'react';
  import { db, Storage, auth } from '../firebase';
  import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
  import { setDoc, doc, collection, serverTimestamp, getDoc } from 'firebase/firestore';
  import { useLocation, useNavigate } from 'react-router-dom';

  import 'react-circular-progressbar/dist/styles.css';
  import '../style/DesignUpload.css';
  import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { Canvas, Rect, Image as FabricImage } from 'fabric';
  import axios from 'axios';
  import Sidebar from '../components/Sidebar';
  import Header from '../components/Header';
  import Modal from '../components/Modal';
  import { FaTimes, FaTrash, FaUndo, FaShopify } from 'react-icons/fa';

  const DesignUpload = () => {
    const location = useLocation();
    const frontCanvasRef = useRef(null);
    const backCanvasRef = useRef(null);
    const [frontCanvas, setFrontCanvas] = useState(null);
    const [backCanvas, setBackCanvas] = useState(null);
    const [designImages, setDesignImages] = useState({ frontDesignImage: null, backDesignImage: null });
    const [view, setView] = useState(0); 
    const [designName, setDesignName] = useState('');
    const [description, setDescription] = useState('');
    const [designFileNames, setDesignFileNames] = useState({ frontDesignImage: '', backDesignImage: '' });
    const [frontDesignSize, setFrontDesignSize] = useState({ width: 0, height: 0 });
    const [backDesignSize, setBackDesignSize] = useState({ width: 0, height: 0 });
    const [frontDesignCost, setFrontDesignCost] = useState(0);
    const [backDesignCost, setBackDesignCost] = useState(0);
    const [progress, setProgress] = useState(0);
    const [sellingPrice, setSellingPrice] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [userShop, setUserShop] = useState(null);
    const [frontmockupImage, setfrontMockupImage] = useState(null);
    const [backmockupImage, setbackMockupImage] = useState(null);

      
    const { id, tShirts } = location.state || {}; 

    const selectedTShirt = tShirts ? tShirts.find((tShirt) => tShirt.id === id) : null;
    const [selectedProduct, setSelectedProduct] = useState(
      selectedTShirt ? selectedTShirt.products[0] : null
    );

    const [selectedPrintMethod, setSelectedPrintMethod] = useState(
      selectedProduct?.print_method[0] || ""
    );
    const [selectedProducts, setSelectedProducts] = useState([]);


    const [errors, setErrors] = useState({
      designName: '',
      description: '',
      sellingPrice: '',
      designImages: '',
      selectedMethod: '',
    });

    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [selectedMethod, setSelectedMethod] = useState(null);
    const navigate = useNavigate();
    const [availablePrintMethods, setAvailablePrintMethods] = useState([]);
    const [isSizeChartVisible, setIsSizeChartVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 

    const user = auth.currentUser;
    if (!user) {
      throw new Error("User not logged in");
    }


    const sizeChart = selectedProduct.sizeChart;

    useEffect(() => {
      const initCanvas = (ref) => {
        return new Canvas(ref.current, {
          width: 2200,
          height: 2700,
          backgroundColor: "#fff",
          enableRetinaScaling: false
        });
      };

      const frontFabricCanvas = initCanvas(frontCanvasRef);
      const backFabricCanvas = initCanvas(backCanvasRef);

      setFrontCanvas(frontFabricCanvas);
      setBackCanvas(backFabricCanvas);

      return () => {
        frontFabricCanvas.dispose();
        backFabricCanvas.dispose();
      };
    }, []);


    useEffect(() => {
      if (selectedProduct && selectedProduct.print_method) {
          setAvailablePrintMethods(selectedProduct.print_method);
      }
  }, [selectedProduct]);




    useEffect(() => {
      const loadMockupAndTexture = async (canvas, viewIndex) => {
        if (selectedProduct) {
          try {
            
            const img = await FabricImage.fromURL(selectedProduct.mockups[viewIndex]);
            canvas.set("backgroundImage", img);
            img.scaleToWidth(canvas.width);
            img.scaleToHeight(canvas.height);
            canvas.renderAll();

          

            const clippingRects = viewIndex === 0 ? [
              new Rect({  left: selectedProduct.clippingRects.front.left ,
                top: selectedProduct.clippingRects.front.top ,
                width: selectedProduct.clippingRects.front.width ,
                height: selectedProduct.clippingRects.front.height ,
                selectable: false,
                evented: false,
                stroke: 'red',
                strokeWidth: 5,
                strokeDashArray: [4, 4],
                fill: 'rgba(0,0,0,0)',
                id: 'clippingRectFront',
                absolutePositioned: true,

  })
            ] : [
              new Rect({ left: selectedProduct.clippingRects.back.left,
                top: selectedProduct.clippingRects.back.top,
                width: selectedProduct.clippingRects.back.width,
                height:selectedProduct.clippingRects.back.height,
                selectable: false,
                evented: false,
                stroke: 'blue',
                strokeWidth: 5,
                strokeDashArray: [4, 4],
                fill: 'rgba(0,0,0,0)',
                id: 'clippingRectBack', 
                absolutePositioned: true,
              }),
                
            ];

            clippingRects.forEach((rect) => canvas.add(rect));
            canvas.clipPaths = clippingRects;
            canvas.renderAll();
          } catch (error) {
            console.error("Error loading mockup image:", error);
          }
        }
      };

      if (frontCanvas) loadMockupAndTexture(frontCanvas, 0);
      if (backCanvas) loadMockupAndTexture(backCanvas, 1);
    }, [selectedProduct, frontCanvas, backCanvas,]);


      useEffect(() => {
        if (selectedTShirt) {
          setSelectedProduct(selectedTShirt.products[0]);
          setSelectedPrintMethod(selectedTShirt.products[0].print_method[0]);
        }
      }, [selectedTShirt]);


    useEffect(() => {
      const totalCost = frontDesignCost + backDesignCost;
      const calculatedTotalPrice = selectedProduct.BASE_COST + totalCost; 
      setTotalPrice(calculatedTotalPrice);
    }, [frontDesignCost, backDesignCost, selectedProduct.BASE_COST]);

    const handleSellingPriceChange = (e) => {
      setSellingPrice(e.target.value);
    };

    // const updateCostAndSize = (widthUnits, heightUnits, isFront) => {
    //   const area = widthUnits * heightUnits;
    //   let newCost = 0;
    
    //   // Ensure we only calculate costs if the width and height are non-zero
    //   if (widthUnits > 0 && heightUnits > 0) {
    //     if (selectedMethod && selectedProduct.costStructure[selectedMethod]) {
    //       const selectedCostStructure = selectedProduct.costStructure[selectedMethod];
    
    //       if (selectedProduct === "Black Baby Tee" || selectedProduct === "White Baby Tee") {
    //         if (area <= 25) {  // Equivalent to 5x5
    //           newCost = selectedCostStructure.logo || 0;
    //         } else if (area <= 60) {  // Custom condition for Baby Tee
    //           newCost = selectedCostStructure.LessthenA4 || 0;
    //         } else if (area <= 97.11) {
    //           newCost = selectedCostStructure.A4 || 0;  // Larger designs
    //         } else if (area <= 120) {
    //           newCost = selectedCostStructure.full || 0;  // Larger designs
    //         } else {
    //           // You can add a condition for any design larger than 15.5x19
    //           newCost = selectedCostStructure.full || 0;
    //         }
    //       } else {
    //       if (area <= 25) {  // Equivalent to 5x5
    //         newCost = selectedCostStructure.logo || 0;
    //       } else if (area <= 63) {  // Equivalent to 7x9 (A4)
    //         newCost = selectedCostStructure.LessthenA4 || 0;

    //       } else if (area <= 97.11) {  // Equivalent to 8.3x11.7 (A4)
    //         newCost = selectedCostStructure.A4 || 0;
    //       } else if (area <= 144) {  // Equivalent to 12x12 (A4)
    //         newCost = selectedCostStructure.A4less || 0;
    //       } else if (area <= 224) {  // Equivalent to 14x16 (A3)
    //         newCost = selectedCostStructure.A3 || 0;
    //       } else if (area <= 294.5) {  // Equivalent to 15.5x19 (Full)
    //         newCost = selectedCostStructure.full || 0;
    //       } else {
    //         // You can add a condition for any design larger than 15.5x19
    //         newCost = selectedCostStructure.full || 0;
    //       }
    //     }}
    //   }
    
    //   // Only update the cost and size for the front or back design if necessary
    //   if (isFront) {
    //     setFrontDesignSize({ width: widthUnits, height: heightUnits });
    //     setFrontDesignCost(newCost);
    //   } else  {
    //     // Only update back design if valid size is provided
    //     setBackDesignSize({ width: widthUnits, height: heightUnits });
    //     setBackDesignCost(newCost);
    //   }

    //   return newCost;
    // };

    const updateCostAndSize = (widthUnits, heightUnits, isFront, productToUse = null) => {
      const area = widthUnits * heightUnits;
      let newCost = 0;
      
      // Use the passed product or fall back to selected product
      const product = productToUse || selectedProduct;
    
      // Ensure we only calculate costs if the width and height are non-zero
      if (widthUnits > 0 && heightUnits > 0) {
        if (selectedMethod && product.costStructure[selectedMethod]) {
          const selectedCostStructure = product.costStructure[selectedMethod];
      
          if (product.name === "Black Baby Tee" || product.name === "White Baby Tee") {
            if (area <= 25) {  // Equivalent to 5x5
              newCost = selectedCostStructure.logo || 0;
            } else if (area <= 60) {  // Custom condition for Baby Tee
              newCost = selectedCostStructure.LessthenA4 || 0;
            } else if (area <= 97.11) {
              newCost = selectedCostStructure.A4 || 0;  // Larger designs
            } else if (area <= 120) {
              newCost = selectedCostStructure.full || 0;  // Larger designs
            } else {
              // You can add a condition for any design larger than 15.5x19
              newCost = selectedCostStructure.full || 0;
            }
          } else {
            if (area <= 25) {  // Equivalent to 5x5
              newCost = selectedCostStructure.logo || 0;
            } else if (area <= 63) {  // Equivalent to 7x9 (A4)
              newCost = selectedCostStructure.LessthenA4 || 0;
            } else if (area <= 97.11) {  // Equivalent to 8.3x11.7 (A4)
              newCost = selectedCostStructure.A4 || 0;
            } else if (area <= 144) {  // Equivalent to 12x12 (A4)
              newCost = selectedCostStructure.A4less || 0;
            } else if (area <= 224) {  // Equivalent to 14x16 (A3)
              newCost = selectedCostStructure.A3 || 0;
            } else if (area <= 320) {  // Equivalent to  16x20 (Full)
              newCost = selectedCostStructure.full || 0;
            } else {
              // You can add a condition for any design larger than 15.5x19
              newCost = selectedCostStructure.full || 0;
            }
          }
        }
      }
    
      // Only update the cost and size for the front or back design if necessary
      if (isFront) {
        setFrontDesignSize({ width: widthUnits, height: heightUnits });
        setFrontDesignCost(newCost);
      } else {
        // Only update back design if valid size is provided
        setBackDesignSize({ width: widthUnits, height: heightUnits });
        setBackDesignCost(newCost);
      }
    
      return newCost;
    };


    useEffect(() => {
      if (selectedProduct && selectedProduct.print_method) {
          setAvailablePrintMethods(selectedProduct.print_method);
          
          // Check if there is a saved print method in sessionStorage
          const savedPrintMethod = sessionStorage.getItem('selectedPrintMethod');
          
          // If a saved print method exists, set it as selected
          if (savedPrintMethod) {
              setSelectedMethod(savedPrintMethod);
          } 
          // If only one print method is available, set it automatically
          else if (selectedProduct.print_method.length === 1) {
              setSelectedMethod(selectedProduct.print_method[0]); // Automatically select the only available method
          }
          // Otherwise, set the first print method as default if multiple options exist
          else if (selectedProduct.print_method.length > 1) {
              setSelectedMethod(selectedProduct.print_method[0]);
          }
      }
  }, [selectedProduct]);

  useEffect(() => {
    // Handle back navigation
    const handleBackNavigation = () => {
      // Clear the selected print method when navigating back
      sessionStorage.removeItem('selectedPrintMethod');
      setSelectedMethod(null);
    };

    // Listen for back navigation event
    window.onpopstate = handleBackNavigation;
  }, []);

  // When the print method is selected
  const handlePrintMethodChange = (printMethod) => {
    setSelectedMethod(printMethod);

    // Save the selected print method to localStorage
  sessionStorage.setItem('selectedPrintMethod', printMethod);

    // Refresh the page
    window.location.reload();
  };

  // Load the selected print method from localStorage when the page loads
  useEffect(() => {
    const savedPrintMethod =  sessionStorage.getItem('selectedPrintMethod');
    if (savedPrintMethod) {
      setSelectedMethod(savedPrintMethod);
    }
  }, []);


  const handleProductChange = (product) => {
    setSelectedProduct(product);
  
    // Update selected products array
    setSelectedProducts((prevSelectedProducts) => {
      if (prevSelectedProducts.some((selectedProduct) => selectedProduct.id === product.id)) {
        return prevSelectedProducts.filter((selectedProduct) => selectedProduct.id !== product.id);
      } else {
        return [...prevSelectedProducts, product];
      }
    });
    
    // Recalculate costs for the new product using existing design sizes
    if (frontDesignSize.width > 0 && frontDesignSize.height > 0) {
      updateCostAndSize(frontDesignSize.width, frontDesignSize.height, true, product);
    }
    
    if (backDesignSize.width > 0 && backDesignSize.height > 0) {
      updateCostAndSize(backDesignSize.width, backDesignSize.height, false, product);
    }
  };


  // const handleProductChange = (product) => {
  //   setSelectedProduct(product);

  //   setSelectedProducts((prevSelectedProducts) => {
  //     if (prevSelectedProducts.some((selectedProduct) => selectedProduct.id === product.id)) {
  //       return prevSelectedProducts.filter((selectedProduct) => selectedProduct.id !== product.id);
  //     } else {
  //       return [...prevSelectedProducts, product];
  //     }
  //   });
    
  // };


  useEffect(() => {
    if (selectedProduct) {
      setfrontMockupImage(selectedProduct.images[0]); 
      setbackMockupImage(selectedProduct.images[1]); // Update mockup dynamically
    }
  }, [selectedProduct]);




  
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const activeCanvas = view === 0 ? frontCanvas : backCanvas;


    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const dpi = Math.round(img.width / (activeCanvas.width / 254));
        if (dpi < 100) {
          toast.error("Print quality bad, high resolution art required.");
          return;
        } else {
          toast.success("Print quality good.");
        }

        // Immediately add the image to the canvas without quality check
        const designType = view === 0 ? "front" : "back";  // Front or back based on view

        setDesignFileNames((prev) => ({
          ...prev,
          [`${designType}DesignImage`]: file.name,  // Dynamically set based on design type
        }));
        
        setDesignImages((prev) => ({
          ...prev,
          [`${designType}DesignImage`]: file,  // Dynamically set based on design type
        }));
        

        (async () => {
          try {

            const fabricImg = await FabricImage.fromURL(img.src);

            if (!fabricImg.width || !fabricImg.height) {
              throw new Error("Image dimensions are not available.");
            }

            let maxWidth = 16;
            let maxHeight = 20;

            const product = selectedProduct;

            if (product.name === "Black Baby Tee" || product.name === "White Baby Tee") {
              maxWidth = 10;
              maxHeight = 12;
            }

            // Define scale factors and position for front and back
            const frontScaleFactor = Math.min(selectedProduct.clippingRects.front.width / fabricImg.width, selectedProduct.clippingRects.front.height / fabricImg.height);
            const backScaleFactor = Math.min(selectedProduct.clippingRects.back.width / fabricImg.width, selectedProduct.clippingRects.back.height / fabricImg.height);
            
            

            const scaleFactor = designType === "front" ? frontScaleFactor : backScaleFactor;

            fabricImg.scale(scaleFactor);

            const frontLeft = selectedProduct.clippingRects.front.left;
            const frontTop = selectedProduct.clippingRects.front.top;
            const frontClipPath = new Rect({
              left: frontLeft,
              top: frontTop,
              width: selectedProduct.clippingRects.front.width,
              height: selectedProduct.clippingRects.front.height,
              absolutePositioned: true,
            });

            const backLeft = selectedProduct.clippingRects.back.left;
            const backTop = selectedProduct.clippingRects.back.top;
            const backClipPath = new Rect({
              left: backLeft,
              top: backTop,
              width: selectedProduct.clippingRects.back.width,
              height: selectedProduct.clippingRects.back.height,
              absolutePositioned: true,
            });

            fabricImg.set({
              left: designType === "front" ? frontLeft + (selectedProduct.clippingRects.front.width  - fabricImg.width * scaleFactor) / 2 : backLeft + (selectedProduct.clippingRects.back.width - fabricImg.width * scaleFactor) / 2,
              top: designType === "front" ? frontTop + (selectedProduct.clippingRects.front.height  - fabricImg.height * scaleFactor) / 2 : backTop + (selectedProduct.clippingRects.back.height - fabricImg.height * scaleFactor) / 2,
              clipPath: designType === "front" ? frontClipPath : backClipPath,
            });

            // Add the image to the canvas immediately
            activeCanvas.add(fabricImg);


            const initialWidthUnits = (fabricImg.width * scaleFactor) / (view === 0 ? selectedProduct.clippingRects.front.width / maxWidth : selectedProduct.clippingRects.back.width / maxWidth);
            const initialHeightUnits = (fabricImg.height * scaleFactor) / (view === 0 ? selectedProduct.clippingRects.front.height / maxHeight : selectedProduct.clippingRects.back.height / maxHeight);

      
            updateCostAndSize(initialWidthUnits, initialHeightUnits, designType === "front");

            fabricImg.setControlsVisibility({
              mt: false, // middle top
              mb: false, // middle bottom
              ml: false, // middle left
              mr: false, // middle right
              tl: true,  // top left
              tr: true,  // top right
              bl: true,  // bottom left
              br: true   // bottom right
            });

            fabricImg.set({
              cornerSize: 30, // Size of the control corners
              borderWidth: 10, // Thickness of the rectangle border
              borderColor: 'blue', // Optional, to customize the rectangle color
              cornerColor: 'blue', // Optional, to customize the corner color
              bordercorner: 'blue',
              cornerStrokeColor: 'black', // Optional, to customize the stroke color of corners
              transparentCorners: false // Makes corners opaque
            });

    
            fabricImg.on('scaling', () => {
              const object = fabricImg;

              const aspectRatio = object.width / object.height;
              let newWidth = object.width * object.scaleX;
              let newHeight = object.height * object.scaleY;

              const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
              const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;


              const adjustedWidthUnits = (object.width * object.scaleX) / (view === 0 ? selectedProduct.clippingRects.front.width / maxWidth : selectedProduct.clippingRects.back.width / maxWidth);
              const adjustedHeightUnits = (object.height * object.scaleY) / (view === 0 ? selectedProduct.clippingRects.front.height / maxHeight : selectedProduct.clippingRects.back.height / maxHeight);
              updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, view === 0, );
            });

            fabricImg.on('scaled', () => {
              const object = fabricImg;
              if (object) {
                object.set({
                  scaleX: 1,
                  scaleY: 1,
                  
                });
              }
            });

            const timestamp = Date.now();
            const fileRef = ref(Storage, `designs/${designType}-${timestamp}.png`);
            await uploadBytes(fileRef, file);
            
            const designImageUrl = await getDownloadURL(fileRef);
            setDesignImages((prev) => ({
              ...prev,
              [`${designType}DesignImageUrl`]: designImageUrl,  // Dynamically set based on design type
            }));
            

          } catch (error) {
            console.error("Error with Fabric Image: ", error);
          } finally {
            setIsLoading(false); // Stop loading
          }
        })();  // Immediately invoked async function

      };

    };

    reader.readAsDataURL(file);
    setDrawerOpen(false);
  };


  const handleSubmit = async (status) => {
    const frontImageUrl = designImages.frontDesignImageUrl || null;
    const backImageUrl = designImages.backDesignImageUrl || null;
  
    setErrors({
      designName: '',
      description: '',
      sellingPrice: '',
      designImages: '',
      selectedMethod: ''
    });
  
    // Validation checks
    let isValid = true;
    const newErrors = {};
  
    if (!designName) {
      newErrors.designName = 'Design Name is required';
      isValid = false;
    }
    if (!description) {
      newErrors.description = 'Description is required';
      isValid = false;
    }
    if (!sellingPrice) {
      newErrors.sellingPrice = 'Selling Price is required';
      isValid = false;
    }
    if (!designImages.frontDesignImage && !designImages.backDesignImage) {
      newErrors.designImages = 'Please upload at least one design image (front or back)';
      isValid = false;
    }
    if (!selectedMethod) {
      newErrors.selectedMethod = 'Please select a print method';
      isValid = false;
    }
  
    if (!isValid) {
      setErrors(newErrors);
      return;
    }
  
    setIsSubmitting(true);
    setIsLoading(true);
  
    let progress = 0;
    const updateProgress = (percentage) => setProgress(percentage);
  
    try {
      const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ia = new Uint8Array(byteString.split('').map(char => char.charCodeAt(0)));
        return new Blob([ia], { type: mimeString });
      };
  
      // Upload mockup with design overlay
      const uploadMockupImage = async (dataUrl, productName, productColorVariant, viewType) => {
        try {
          if (!dataUrl) {
            console.error(`No data URL for ${viewType} mockup`);
            return null;
          }
          
          const timestamp = Date.now();
          const imageBlob = dataURItoBlob(dataUrl);
          const fileRef = ref(Storage, `mockups/${productName}-${productColorVariant}-${viewType}-${timestamp}.jpeg`);
          
          await uploadBytes(fileRef, imageBlob);
          const url = await getDownloadURL(fileRef);
          console.log(`Successfully uploaded ${viewType} mockup:`, url);
          return url;
        } catch (error) {
          console.error(`Error uploading ${viewType} mockup:`, error);
          return null;
        }
      };
  
      const extractColorFromName = (productName) => {
        const descriptionKeywords = ["Regular", "Fit", "T-shirt", "Baby", "Oversized"];
        const nameParts = productName.split(" ");
        const colorEndIndex = nameParts.findIndex(word => descriptionKeywords.includes(word));
        const colorParts = nameParts.slice(0, colorEndIndex === -1 ? nameParts.length : colorEndIndex);
        return colorParts.join(" ");
      };
      
      const generateSKUs = (product) => {
        const randomNum = Math.floor(Math.random() * 1000000);
        const productSizes = product.sizes || [];
        const productColor = extractColorFromName(product.name).toLowerCase();
        const productColorFormatted = productColor.split(" ").join("");
        return productSizes.map(size => `u-${randomNum}-${size}-${productColorFormatted}`);
      };
      
      const colorVariants = [];
      
      // Get products to process
      const productsToProcess = selectedProducts.length > 0 ? selectedProducts : [selectedProduct];
      
      // Process each selected product
      for (const product of productsToProcess) {
        const productColorVariant = extractColorFromName(product.name);
        
        // Create temporary canvas copies for this product with correct mockups
        const frontTempCanvas = new Canvas(document.createElement('canvas'), {
          width: 2200,
          height: 2700,
          backgroundColor: "#fff",
          enableRetinaScaling: false
        });
        
        const backTempCanvas = new Canvas(document.createElement('canvas'), {
          width: 2200,
          height: 2700,
          backgroundColor: "#fff", 
          enableRetinaScaling: false
        });
        
        // Load product-specific mockups on temp canvases
        await (async () => {
          // Set the front mockup for this specific product
          try {
            const frontImg = await FabricImage.fromURL(product.mockups[0]);
            frontTempCanvas.set("backgroundImage", frontImg);
            frontImg.scaleToWidth(frontTempCanvas.width);
            frontImg.scaleToHeight(frontTempCanvas.height);
            
            // Clone design elements from the main canvas to this product's canvas
            if (frontCanvas && frontCanvas.getObjects) {
              const designObjects = frontCanvas.getObjects().filter(obj => 
                obj.type === 'image' && obj.id !== 'clippingRectFront');
                
              for (const obj of designObjects) {
                const clonedObj = await FabricImage.fromURL(obj.getSrc());
                clonedObj.set({
                  left: obj.left,
                  top: obj.top,
                  scaleX: obj.scaleX,
                  scaleY: obj.scaleY,
                  angle: obj.angle,
                  clipPath: new Rect({
                    left: product.clippingRects.front.left,
                    top: product.clippingRects.front.top,
                    width: product.clippingRects.front.width,
                    height: product.clippingRects.front.height,
                    absolutePositioned: true,
                  })
                });
                frontTempCanvas.add(clonedObj);
              }
            }
            
            // Set the back mockup for this specific product
            const backImg = await FabricImage.fromURL(product.mockups[1]);
            backTempCanvas.set("backgroundImage", backImg);
            backImg.scaleToWidth(backTempCanvas.width);
            backImg.scaleToHeight(backTempCanvas.height);
            
            // Clone design elements from the main canvas to this product's canvas
            if (backCanvas && backCanvas.getObjects) {
              const designObjects = backCanvas.getObjects().filter(obj => 
                obj.type === 'image' && obj.id !== 'clippingRectBack');
                
              for (const obj of designObjects) {
                const clonedObj = await FabricImage.fromURL(obj.getSrc());
                clonedObj.set({
                  left: obj.left,
                  top: obj.top,
                  scaleX: obj.scaleX,
                  scaleY: obj.scaleY,
                  angle: obj.angle,
                  clipPath: new Rect({
                    left: product.clippingRects.back.left,
                    top: product.clippingRects.back.top,
                    width: product.clippingRects.back.width,
                    height: product.clippingRects.back.height,
                    absolutePositioned: true,
                  })
                });
                backTempCanvas.add(clonedObj);
              }
            }
            
            frontTempCanvas.renderAll();
            backTempCanvas.renderAll();
          } catch (error) {
            console.error(`Error creating mockups for ${product.name}:`, error);
          }
        })();
        
        // Get the mockup data URLs for this specific product
        const frontMockupDataUrl = frontTempCanvas.toDataURL('image/jpeg', 0.95);
        const backMockupDataUrl = backTempCanvas.toDataURL('image/jpeg', 0.95);
        
        // Upload front mockup for this product
        updateProgress(40);
        const frontMockupUrl = await uploadMockupImage(
          frontMockupDataUrl,
          product.name,
          productColorVariant,
          'front'
        );
        
        // Upload back mockup for this product
        updateProgress(80);
        const backMockupUrl = await uploadMockupImage(
          backMockupDataUrl,
          product.name,
          productColorVariant,
          'back'
        );
        
        // Dispose of temporary canvases
        frontTempCanvas.dispose();
        backTempCanvas.dispose();


        let productFrontDesignCost = 0;
        let productBackDesignCost = 0;
        
        // Use the existing dimensions but calculate cost specifically for this product
        if (frontDesignSize.width > 0 && frontDesignSize.height > 0) {
          productFrontDesignCost = updateCostAndSize(
            frontDesignSize.width, 
            frontDesignSize.height, 
            true, 
            product
          );
        }
        
        if (backDesignSize.width > 0 && backDesignSize.height > 0) {
          productBackDesignCost = updateCostAndSize(
            backDesignSize.width, 
            backDesignSize.height, 
            false, 
            product
          );
        }
        
        // Add to color variants
        colorVariants.push({
          color: productColorVariant,
          productName: product.name,
          images: { 
            front: frontMockupUrl || product.mockups[0], // Fallback to original mockup
            back: backMockupUrl || product.mockups[1]  // Fallback to original mockup
          },
          costs: { 
            front: productFrontDesignCost,
            back: productBackDesignCost
          },
          SKUs: generateSKUs(product),
          baseCost: product.BASE_COST,
          PrintCost: productFrontDesignCost + productBackDesignCost,
          totalPrice: product.BASE_COST + productFrontDesignCost + productBackDesignCost,
          hasDesign: { 
            front: !!frontImageUrl, 
            back: !!backImageUrl 
          }
        });
      }
  
      // Save design to Firestore
      const designRef = doc(collection(db, 'users', user.uid, 'designs'));
      await setDoc(designRef, {
        designName,
        description,
        sellingPrice,
        frontDesign: frontImageUrl,
        backDesign: backImageUrl,
        Printsizes: { front: frontDesignSize, back: backDesignSize },
        productGSM: productsToProcess[0].GSM,
        timestamp: serverTimestamp(),
        printMethod: selectedMethod,
        userId: user.uid,
        colorVariants,
      });
  
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        toast.error("User data not found.");
        return;
      }
  
      const userShop = userDoc.data().shop;
      if (!userShop) {
        toast.error("Shop information is missing. Please check your user data.");
        return;
      }
  
      // Send a single API request with all color variants
      const payload = {
        productName: designName,
        shop: userShop,
        designName,
        description,
        sellingPrice,
        status,
        userId: user.uid,
        colorVariants,
      };
  
      console.log("Final Payload:", payload);
  
      await axios.post('https://ufb-1.onrender.com/api/create-active-product-hootpop', payload);
  
      toast.success('Design uploaded successfully!');
      setShowModal(true);
    } catch (error) {
      console.error("Error uploading design:", error);
      toast.error("Failed to upload design.");
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
      setProgress(100);
    }
  };


  useEffect(() => {
  const fetchUserShop = async () => {
    // Assuming db is the Firestore instance and user.uid is the current user's ID
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {
      toast.error("User data not found.");
      return;
    }

    const userData = userDoc.data();
    const userShop = userData.shop;
    setUserShop(userShop); // Update the userShop state
    
  };

  fetchUserShop();
  }, [user.uid]); // Fetch only when the user.uid changes



  const handleSaveProduct = async () => {
  const frontImageUrl = designImages.frontDesignImageUrl || null;
  const backImageUrl = designImages.backDesignImageUrl || null;

  setErrors({
    designName: '',
    description: '',
    sellingPrice: '',
    designImages: '',
    selectedMethod: ''
  });

  // Check if required fields are empty
  let isValid = true;
  const newErrors = {};

  if (!designName) {
    newErrors.designName = 'Design Name is required';
    isValid = false;
  }
  if (!description) {
    newErrors.description = 'Description is required';
    isValid = false;
  }
  if (!sellingPrice) {
    newErrors.sellingPrice = 'Selling Price is required';
    isValid = false;
  }
  if (!designImages.frontDesignImage && !designImages.backDesignImage) {
    newErrors.designImages = 'Please upload at least one design image (front or back)';
    isValid = false;
  }

  if (!selectedMethod) {
    newErrors.selectedMethod = 'Please select a print method';
    isValid = false;
  }

  // If validation fails, update error state and return
  if (!isValid) {
    setErrors(newErrors);
    return;
  }

  setIsSubmitting(true);
  setIsLoading(true);

  let progress = 0; // Initial progress

  // Function to update progress
  const updateProgress = (percentage) => {
    setProgress(percentage); // Update the progress state
  };

  try {
    // Function to convert Data URI to Blob
    const dataURItoBlob = (dataURI) => {
      const byteString = dataURI.split(',')[1];
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ia = new Uint8Array(atob(byteString).split('').map(char => char.charCodeAt(0)));
      return new Blob([ia], { type: mimeString });
    };

    // Save final canvas design to Firebase Storage
    const saveCanvasToStorage = async (canvas, canvasName) => {
      canvas.getObjects("rect").forEach((rect) => rect.set({ visible: false })); // Hide any visible rectangles
      canvas.renderAll();
      const timestamp = Date.now();
      const canvasPNG = canvas.toDataURL({ format: 'jpeg' }); // Save as PNG for quality
      const canvasRef = ref(Storage, `mockups/${designName}-${canvasName}-${timestamp}.jpeg`);


      updateProgress(30);

      await uploadBytes(canvasRef, dataURItoBlob(canvasPNG));
      const url = await getDownloadURL(canvasRef);
      return url;
    };



    // Generate SKUs for different sizes
  const generateSKUs = () => {
  const randomNum = Math.floor(Math.random() * 1000000);
  const productSizes = selectedProduct.sizes || []; // Get sizes from the product object

  // Generate SKUs for each size in the product.sizes array
  return productSizes.map(size => `u-${randomNum}-${size}`);
  };
    const skus = generateSKUs();

    // Export the final combined design for front and back mockups
    const frontMockupUrl = await saveCanvasToStorage(frontCanvas, 'front');
    updateProgress(50); // Update progress to 50% after front mockup
    const backMockupUrl = await saveCanvasToStorage(backCanvas, 'back');
    updateProgress(70); // Update progress to 70% after back mockup

    // Create design document in Firestore
    const designRef = doc(collection(db, 'users', user.uid, 'designs'));
    await setDoc(designRef, {
      designName,
      description,
      sellingPrice,
      totalPrice,
      frontDesign: frontImageUrl, // Use the URL from state
      backDesign: backImageUrl,   // Use the URL from state
      images: {
        front: frontMockupUrl,
        back: backMockupUrl,
      },
      costs: {
        front: frontDesignCost,
        back: backDesignCost,
      },
      Printsizes: {
        front: frontDesignSize,
        back: backDesignSize,
      },
      productName: selectedProduct.name, // Use product data
      productGSM: selectedProduct.GSM,
      SKUs: skus, // Use product GSM
      baseCost: selectedProduct.BASE_COST,
      PrintCost: frontDesignCost + backDesignCost,
      timestamp: serverTimestamp() ,
      printMethod: selectedMethod,
      userId: user.uid
    })
    // Redirect after successful submission
    toast.success('Design uploaded successfully!');
    setShowModal(true);
    // navigate(/products?shop=${shop});
  } catch (error) {
    console.error("Error uploading design:", error);
    toast.error("Failed to upload design.");
  } finally {
    setIsSubmitting(false);
    setIsLoading(false);
    setProgress(100);
  }
  };


  const handleGoToProducts = () => {
  // Navigate to the products page after clicking "Go"
  navigate('/Products-new');
  };

  const handleRotate = () => {
    // event.preventDefault(); 
    const activeCanvas = view === 0 ? frontCanvas : backCanvas;
    const activeObjects = activeCanvas.getActiveObjects();

    activeObjects.forEach((obj) => {
      obj.rotate(obj.angle + 90);
      obj.setCoords();
    });
    activeCanvas.renderAll();
  };

  const handleDelete = () => {
    // event.preventDefault(); 
    const activeCanvas = view === 0 ? frontCanvas : backCanvas;
    const activeObjects = activeCanvas.getActiveObjects();

    activeObjects.forEach((obj) => {
      activeCanvas.remove(obj);
    });
    activeCanvas.discardActiveObject();
    activeCanvas.renderAll();

    if (view === 0) {
      // Deleting front design, reset front size and cost to 0
      updateCostAndSize(0, 0, true);  // Reset front design size and cost
    } else {
      // Deleting back design, reset back size and cost to 0
      updateCostAndSize(0, 0, false); // Reset back design size and cost
    }

  };

  const handleShowSizeChart = () => {
    setIsSizeChartVisible(!isSizeChartVisible);
  };

  const setOpenDrawer = (isOpen) => {
    setDrawerOpen(isOpen);
  };  


  return (
    <div className="one">
      {/* <ToastContainer limit={1} closeOnClick/> */}
    <Header/>
    <div className="design-upload-container">
    <Sidebar />
    <div className={`drawer ${drawerOpen ? 'open' : ''}`}>

    <div className="drawer-header">
              <button className="close-drawer" onClick={() => setOpenDrawer(false)}>
                <FaTimes/>
              </button>
            </div>

      <div className="form-wrapper">

        <h2 className='hideee'>Design Upload</h2>

        <form onSubmit={handleSubmit}>
    <div className="form-group">
      <div className="print-method-container">
        <h3>PRINT METHOD</h3>
        <div className="print-method-buttons">
          {availablePrintMethods.map((printMethod) => (
            <button
              key={printMethod}
              className={`print-method-button ${selectedMethod === printMethod ? 'active' : ''}`}
              onClick={(event) => {
                event.preventDefault(); // Prevent any default behavior like form submission
                handlePrintMethodChange(printMethod); // Call the function to change print method
              }}
            >
              {printMethod}
            </button>
    ))}
  </div>
    {errors.selectedMethod && <p className="error">{errors.selectedMethod}</p>}
  </div>

  <div className="form-group">
            <label>Upload Design Image:</label>
            <input
              type="file"
              accept=".png"
              onChange={handleFileUpload}
              multiple
              required
              style={{ display: 'none' }} // Hide the default file input
              id="designImage"
            />
            <label htmlFor="designImage" className="custom-file-upload">
              + Add Image
            </label>

  {Object.keys(designFileNames).map((key) => {
  if (designFileNames[key]) {
    return (
      <p key={key}>Uploaded: {designFileNames[key]}</p>
    );
  }
  return null;
  })}
          </div>
            <label htmlFor="designName">Product Name:</label>
            <input
              type="text"
              id="designName"
              value={designName}
              onChange={(e) => setDesignName(e.target.value)}
              placeholder="Product Name"
              required            
            />
              {errors.designName && <p className="error">{errors.designName}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
              required
            />
            {errors.description && <p className="error">{errors.description}</p>}
          </div>

  <div className="color-swatches">
    {selectedTShirt.products.map((product) => (
      <div
        key={product.id}
        className={`color-swatch ${selectedProducts.some((selectedProduct) => selectedProduct.id === product.id) ? 'selected' : ''}`}
        onClick={() => handleProductChange(product)} // Update mockup dynamically
        style={{ backgroundColor: product.description.split(' ')[0].toLowerCase() }}
      />
    ))}
  </div>

          <div className="design-details">
          <div className="size-chart-link">
          <div className="size-chart-link">
  <span onClick={handleShowSizeChart}>View Size Chart</span>
  </div>
        </div>

        {/* Show the size chart image when the link is clicked */}
        {isSizeChartVisible && (
          <div className="size-chart">
            <h3>Size Chart:</h3>
            <img src={sizeChart} alt="Size Chart" />
          </div>
        )}
            <p><label>GSM: {selectedProduct.GSM}</label></p>
            <p><label>Front Design Size: {frontDesignSize.width.toFixed(1)} x {frontDesignSize.height.toFixed(1)} inches</label></p>
            <p><label>Front Design Cost: ₹{frontDesignCost}</label></p>
            <p><label>Back Design Size: {backDesignSize.width.toFixed(1)} x {backDesignSize.height.toFixed(1)} inches</label></p>
            <p><label>Back Design Cost: ₹{backDesignCost}</label></p>
            <p><label>Total Cost: ₹{totalPrice} + {selectedProduct.tax} + Shipping </label></p>
          </div>
            

          <div className="form-group">
            <label htmlFor="sellingPrice">Selling Price:</label>
            <input
              type="number"
              id="sellingPrice"
              value={sellingPrice}
              onChange={handleSellingPriceChange}
              placeholder="Enter Selling Price"
              required
            />
            {errors.sellingPrice && <p className="error">{errors.sellingPrice}</p>}
          </div>

      

          <div className="Beee">
      {/* Check if user has a shop, if not show the Save Product button */}
      {!userShop ? (
        <button type="button" disabled={isSubmitting} onClick={handleSaveProduct} className="save-submit">
          {isSubmitting ? 'Saving Product...' : 'Save Product'}
        </button>
      ) : (
        // If user has a shop, show the Make It Live and Save as Draft buttons
        <>
          <button type="button" disabled={isSubmitting} onClick={() => handleSubmit('active')} className="desktop-submit">
            {isSubmitting ? 'Submitting...' : (
              <>
                Make It Live <FaShopify className="shopifyi" />
              </>
            )}
          </button>
          <button type="button" disabled={isSubmitting} onClick={() => handleSubmit('draft')} className="desktop-draft">
            {isSubmitting ? 'Saving as Draft...' : (
              <>
                Save as Draft <FaShopify className="shopifyi" />
              </>
            )}
          </button>
        </>
      )}
    </div>
    {userShop && (
  <div className='skualert'>*Do not change the SKU of the Shopify product after creation.</div>
    )}
        </form>
        </div>

        {showModal && (
        <Modal>
          <div className="modal-content">
            <h2>Your products has been created successfully!</h2>
          
            <button onClick={handleGoToProducts}>Go to products page!</button>
          </div>
        </Modal>
      )}
    
      {isLoading && (
  <div className="loader-overlay">
    <img src="Video1.GIF" alt="Loading..." />
    <span></span>
    <div className="progress">
              <p>{progress}%</p> {/* Display progress */}
        </div>
  </div>
  )}

      </div>
      <div className="mockup">
      <div className="view-and-customize">
        <select onChange={(e) => setView(Number(e.target.value))}  className="view-select">
            <option value="0">Front</option>
            <option value="1">Back</option>
          </select>
      
          <button onClick={() => setOpenDrawer(true)} className="customize-button">
              Customize
          </button>
          <button onClick={handleRotate} className="control-button"><FaUndo/></button>
          <button  onClick={handleDelete} className="control-button"><FaTrash/></button>
  </div>
        <div style={{ display: view === 0 ? 'block' : 'none' }}>
        <div className="canvas-wrapper">
          <canvas ref={frontCanvasRef} />
          </div>
        </div>
        <div style={{ display: view === 1 ? 'block' : 'none' }}>
        <div className="canvas-wrapper">
          <canvas ref={backCanvasRef} />
          </div>
        </div>
        
      </div>
      
    </div>
    </div>
  );
  };
  export default DesignUpload; 

