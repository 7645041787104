// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase'; // Ensure you're importing auth if needed
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { useLocation } from 'react-router-dom'; // Use useLocation for query parameters
// import axios from 'axios'; // For making API requests
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const SalesInvoice = () => {
//     const [paidOrders, setPaidOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//         const [searchTerm, setSearchTerm] = useState("");
//     const [filteredOrders, setFilteredOrders] = useState([]);
//     const [trackingUrls, setTrackingUrls] = useState({}); // Store tracking URLs
//     const location = useLocation(); // Get the current location
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop'); // Retrieve shopId from query parameters

//     useEffect(() => {
//       const checkUser = () => {
//         const user = auth.currentUser;
//         if (!user) {
//           throw new Error("User not logged in");
//         }
//         return user;
//       };
      
//       // Fetch paid orders from Firestore
//       const fetchPaidOrders = async () => {
//         const user = checkUser();
          
//         try {
//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
//             const q = query(ordersCollection, where('isPaid', '==', true));
//             const ordersSnapshot = await getDocs(q);
//             const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             setPaidOrders(ordersList);

//             // Fetch tracking URLs for each order once orders are loaded
//             // const newTrackingUrls = {};
//             // for (const order of ordersList) {
//             //     const trackingUrl = await fetchTrackingUrl(order.id);
//             //     newTrackingUrls[order.id] = trackingUrl;
//             // }
//             // setTrackingUrls(newTrackingUrls);

//         } catch (error) {
//             console.error("Error fetching paid orders:", error);
//         } finally {
//             setLoading(false);
//         }
//       };
      
//       fetchPaidOrders();
//     }, [shopId]);


//         useEffect(() => {
//             const filtered = paidOrders.filter(order => 
//                 order.id.includes(searchTerm) || 
//                 (order.Storeorder_id && order.Storeorder_id.includes(searchTerm))
//             );
//             setFilteredOrders(filtered);
//         }, [searchTerm, paidOrders]);

//     // Fetch tracking URL from the backend
//     // const fetchTrackingUrl = async (orderId) => {
//     //     try {
//     //         const response = await axios.get(
//     //             `https://ufb-1.onrender.com/api/track-order?order_id=${orderId}`
//     //         );
//     //         if (response.data && response.data.tracking_url) {
//     //             return response.data.tracking_url;
//     //         }
//     //     } catch (error) {
//     //         console.error("Error fetching tracking URL for order", orderId, error);
//     //         return null;
//     //     }
//     //     return null;
//     // };

//     // Handle invoice download
//     const handleDownloadInvoice = (invoiceUrl) => {
//         if (invoiceUrl) {
//             window.open(invoiceUrl, '_blank');
//         } else {
//             alert('Invoice not available for this order.');
//         }
//     };

//     if (loading) {
//         return <div>Loading Sales invoices...</div>;
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>Sales Invoice</h1>
//                     <input 
//     type="text" 
//     placeholder="Search by Order ID or Store Order ID" 
//     value={searchTerm} 
//     onChange={(e) => setSearchTerm(e.target.value)} 
//     className="search-bar"
// />
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 <th>Unitee Order ID</th>
//                                 <th>SKU</th>
//                                 <th>Quantity</th>
//                                 <th>Invoice</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredOrders.map((order) => (
//                                 <tr key={order.id}>
//                                     <td>{order.Storeorder_id || 'NA'}</td>
//                                     <td>{order.id || 'NA'}</td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.sku}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.units}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {/* Check if SalesinvoiceUrl exists and add the button */}
//                                         {order.SalesinvoiceUrl ? (
//                                             <button 
//                                                 onClick={() => handleDownloadInvoice(order.SalesinvoiceUrl)} 
//                                                 className="download-invoice-btn"
//                                             >
//                                                 Download Invoice
//                                             </button>
//                                         ) : (
//                                             <span>NA</span>
//                                         )}
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SalesInvoice;



// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase';
// import { collection, query, where, onSnapshot } from 'firebase/firestore';
// import { useLocation } from 'react-router-dom';
// import Papa from 'papaparse';
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const SalesInvoice = () => {
//     const [paidOrders, setPaidOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState("");
//     const [filteredOrders, setFilteredOrders] = useState([]);

//     const [fromDate, setFromDate] = useState('');
//     const [toDate, setToDate] = useState('');

//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop');

//     const user = auth.currentUser;
//     if (!user) {
//         throw new Error("User not logged in");
//     }

//     // Function to fetch orders
//     const fetchPaidOrders = async () => {
//         const cachedOrders = sessionStorage.getItem("paidOrders");
//         if (cachedOrders) {
//             const ordersList = JSON.parse(cachedOrders);
//             setPaidOrders(ordersList);
//             setFilteredOrders(ordersList);
//             setLoading(false);
//             return;
//         }

//         try {
//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
//             const q = query(ordersCollection, where('isPaid', '==', true));

//             const unsubscribe = onSnapshot(q, (snapshot) => {
//                 const ordersList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//                 setPaidOrders(ordersList);
//                 setFilteredOrders(ordersList);
//                 sessionStorage.setItem("paidOrders", JSON.stringify(ordersList)); // Cache data
//                 setLoading(false);
//             });

//             return () => unsubscribe();
//         } catch (error) {
//             console.error("Error fetching paid orders:", error);
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchPaidOrders();
//     }, [shopId]);


//     useEffect(() => {
//         const filtered = paidOrders.filter(order => {
//             const orderMatchesSearch = 
//                 order.id.includes(searchTerm) ||
//                 (order.Storeorder_id && order.Storeorder_id.includes(searchTerm));
            
//             // 🟢 NEW DATE RANGE FILTERING LOGIC
//             const orderMatchesDateRange = 
//                 (!fromDate || new Date(order.order_date) >= new Date(fromDate)) &&
//                 (!toDate || new Date(order.order_date) <= new Date(toDate));
            
//             return orderMatchesSearch && orderMatchesDateRange;
//         });
//         setFilteredOrders(filtered);
//     }, [searchTerm, paidOrders, fromDate, toDate]);


//     // Helper function to calculate updated selling price with shipping and discount distribution
//     const calculateAdjustedSellingPrice = (order, item) => {
//         if (!item) return 0;
        
//         const originalPrice = item.selling_price || 0;
//         const orderItems = order.order_items || [];
//         const totalItems = orderItems.length;
        
//         if (totalItems === 0) return originalPrice;
        
//         // Calculate per-item shipping charge
//         const shippingPerItem = (order.shipping_charge || 0) / totalItems;
        
//         // Calculate per-item discount based on item's proportion of total value
//         let totalOrderValue = 0;
//         orderItems.forEach(orderItem => {
//             totalOrderValue += (orderItem.selling_price || 0) * (orderItem.units || 1);
//         });
        
//         let discountPerItem = 0;
//         if (totalOrderValue > 0 && order.total_discount > 0) {
//             const itemTotalValue = originalPrice * (item.units || 1);
//             const itemProportion = itemTotalValue / totalOrderValue;
//             discountPerItem = (order.total_discount * itemProportion) / (item.units || 1);
//         }
        
//         // Adjust selling price: Add shipping and subtract discount
//         const adjustedPrice = originalPrice + shippingPerItem - discountPerItem;
//         return adjustedPrice > 0 ? adjustedPrice : 0;
//     };

//     // Calculate tax based on the adjusted selling price (which already includes shipping and discount)
//     const calculateTax = (adjustedPrice, state) => {
//         const isMaharashtra = (state === 'Maharashtra');
//         let sgst = 'NA', cgst = 'NA', igst = 'NA';
        
//         if (isMaharashtra) {
//             // Extract SGST and CGST from the price (tax is already included)
//             if (adjustedPrice < 1000) {
//                 // 5% total tax (2.5% SGST + 2.5% CGST) means price includes tax at 1/1.05 ratio
//                 const preTaxPrice = adjustedPrice / 1.05;
//                 const totalTax = adjustedPrice - preTaxPrice;
//                 sgst = (totalTax / 2).toFixed(2);
//                 cgst = (totalTax / 2).toFixed(2);
//             } else {
//                 // 12% total tax (6% SGST + 6% CGST) means price includes tax at 1/1.12 ratio
//                 const preTaxPrice = adjustedPrice / 1.12;
//                 const totalTax = adjustedPrice - preTaxPrice;
//                 sgst = (totalTax / 2).toFixed(2);
//                 cgst = (totalTax / 2).toFixed(2);
//             }
//         } else {
//             // Extract IGST from the price (tax is already included)
//             if (adjustedPrice < 1000) {
//                 // 5% IGST means price includes tax at 1/1.05 ratio
//                 const preTaxPrice = adjustedPrice / 1.05;
//                 igst = (adjustedPrice - preTaxPrice).toFixed(2);
//             } else {
//                 // 12% IGST means price includes tax at 1/1.12 ratio
//                 const preTaxPrice = adjustedPrice / 1.12;
//                 igst = (adjustedPrice - preTaxPrice).toFixed(2);
//             }
//         }
        
//         return { sgst, cgst, igst };
//     };

//     const downloadCSV = () => {

//         const filteredOrdersForDownload = paidOrders.filter(order => 
//             (!fromDate || new Date(order.order_date) >= new Date(fromDate)) &&
//             (!toDate || new Date(order.order_date) <= new Date(toDate))
//         );
//         // Create rows for CSV, with multiple rows for orders with multiple items
//         let csvRows = [];
        
//         filteredOrdersForDownload.forEach(order => {
//             // Check if order has items
//             if (!order.order_items || order.order_items.length === 0) {
//                 // Add a single row with NA for item fields
//                 csvRows.push({
//                     'Store Order ID': order.Storeorder_id || 'NA',
//                     'Unitee Order ID': order.id || order.order_id || 'NA',
//                     'SKU': 'NA',
//                     'Product Name': 'NA',
//                     'Size': 'NA',
//                     'Quantity': 'NA',
//                     'Selling Price': 'NA',
//                     'SGST': 'NA',
//                     'CGST': 'NA',
//                     'IGST': 'NA',
//                     'Customer Name': order.billing_customer_name || 'NA',
//                     'Email': order.billing_email || 'NA',
//                     'Phone': order.billing_phone || 'NA',
//                     'Billing Address': order.billing_address || 'NA',
//                     'City': order.billing_city || 'NA',
//                     'State': order.billing_state || 'NA',
//                     'Pincode': order.billing_pincode || 'NA',
//                     'Country': order.billing_country || 'NA',
//                     'Payment Method': order.payment_method || 'NA',
//                     'Order Date': order.order_date || 'NA',
//                     'Order Status': order.status || 'NA',
//                     'Subtotal': order.sub_total || 'NA',
//                     'Total': order.UniteeTotalPrice || 'NA'
//                 });
//             } else {
//                 // Add separate row for each item in the order
//                 order.order_items.forEach(item => {
//                     // Calculate adjusted selling price with shipping and discount distribution
//                     const adjustedPrice = calculateAdjustedSellingPrice(order, item);
                    
//                     // Calculate tax based on adjusted price and state
//                     const { sgst, cgst, igst } = calculateTax(adjustedPrice, order.billing_state);
                    
//                     csvRows.push({
//                         'Store Order ID': order.Storeorder_id || 'NA',
//                         'Unitee Order ID': order.id || order.order_id || 'NA',
//                         'SKU': item.sku || 'NA',
//                         'Product Name': item.productName || 'NA',
//                         'Variant': item.name || 'NA',
//                         'Size': item.size || 'NA',
//                         'Quantity': item.units || 'NA',
//                         'Selling Price': adjustedPrice.toFixed(2) || 'NA',
//                         'SGST': sgst,
//                         'CGST': cgst,
//                         'IGST': igst,
//                         'Customer Name': order.billing_customer_name || 'NA',
//                         'Email': order.billing_email || 'NA',
//                         'Phone': order.billing_phone || 'NA',
//                         'Billing Address': order.billing_address || 'NA',
//                         'City': order.billing_city || 'NA',
//                         'State': order.billing_state || 'NA',
//                         'Pincode': order.billing_pincode || 'NA',
//                         'Country': order.billing_country || 'NA',
//                         'Payment Method': order.payment_method || 'NA',
//                         'Order Date': order.order_date || 'NA',
//                         'Order Status': order.status || 'NA',
//                         'Subtotal': order.sub_total || 'NA',
//                         'Total': order.total || 'NA'
//                     });
//                 });
//             }
//         });
        
//         // Generate CSV and trigger download
//         const csv = Papa.unparse(csvRows);
//         const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//         const url = URL.createObjectURL(blob);
//         const link = document.createElement('a');
//         link.href = url;

//         const fromDateStr = fromDate ? new Date(fromDate).toLocaleDateString('en-GB').replace(/\//g, '-') : 'AllTime';
//         const toDateStr = toDate ? new Date(toDate).toLocaleDateString('en-GB').replace(/\//g, '-') : 'AllTime';
//         link.setAttribute('download', `SalesReport_${fromDateStr}_to_${toDateStr}.csv`);

//         // link.setAttribute('download', 'SalesReport.csv');
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     };

//     if (loading) {
//         return <div>Loading paid orders...</div>;
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>Sales Invoice</h1>

//                     <div className="date-filter-container">
//                         <div className="date-input-group">
//                             <label htmlFor="fromDate">From Date:</label>
//                             <input 
//                                 type="date" 
//                                 id="fromDate"
//                                 value={fromDate} 
//                                 onChange={(e) => setFromDate(e.target.value)} 
//                                 className="date-input"
//                             />
//                         </div>
//                         <div className="date-input-group">
//                             <label htmlFor="toDate">To Date:</label>
//                             <input 
//                                 type="date" 
//                                 id="toDate"
//                                 value={toDate} 
//                                 onChange={(e) => setToDate(e.target.value)} 
//                                 className="date-input"
//                             />
//                         </div>
//                     <button onClick={downloadCSV} className="download-report-btn">
//                         Download Report
//                     </button>
//                     </div>
//                     <input 
//                         type="text" 
//                         placeholder="Search by Order ID or Store Order ID" 
//                         value={searchTerm} 
//                         onChange={(e) => setSearchTerm(e.target.value)} 
//                         className="search-bar"
//                     />
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 <th>Unitee Order ID</th>
//                                 <th>SKU</th>
//                                 <th>Quantity</th>
//                                 {/* <th>Price</th>
//                                 <th>Subtotal</th>
//                                 <th>Total</th> */}
//                                 <th>Invoice</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredOrders.map((order) => (
//                                 <tr key={order.id}>
//                                     <td>{order.Storeorder_id || 'NA'}</td>
//                                     <td>{order.id || 'NA'}</td>
//                                     <td>
//                                         {order.order_items && order.order_items.map((item, index) => (
//                                             <p key={index}>{item.sku}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {order.order_items && order.order_items.map((item, index) => (
//                                             <p key={index}>{item.units}</p>
//                                         ))}
//                                     </td>
//                                     {/* <td>
//                                         {order.order_items && order.order_items.map((item, index) => (
//                                             <p key={index}>₹{calculateAdjustedSellingPrice(order, item).toFixed(2)}</p>
//                                         ))}
//                                     </td>
//                                     <td>₹{order.sub_total || 'NA'}</td>
//                                     <td>₹{order.UniteeTotalPrice || 'NA'}</td> */}
//                                     <td>
//                                         {order.SalesinvoiceUrl ? (
//                                             <button 
//                                                 onClick={() => window.open(order.SalesinvoiceUrl, '_blank')}
//                                                 className="download-invoice-btn"
//                                             >
//                                                 Download Invoice
//                                             </button>
//                                         ) : (
//                                             <span>No Invoice</span>
//                                         )}
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
     
//     );
// };

// export default SalesInvoice;


import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import Papa from 'papaparse';
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const SalesInvoice = () => {
    const [paidOrders, setPaidOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOrders, setFilteredOrders] = useState([]);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop');

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }

    // Function to fetch orders
    const fetchPaidOrders = async () => {
        const cachedOrders = sessionStorage.getItem("paidOrders");
        if (cachedOrders) {
            const ordersList = JSON.parse(cachedOrders);
            setPaidOrders(ordersList);
            setFilteredOrders(ordersList);
            setLoading(false);
            return;
        }

        try {
            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            const q = query(ordersCollection, where('isPaid', '==', true));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const ordersList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPaidOrders(ordersList);
                setFilteredOrders(ordersList);
                sessionStorage.setItem("paidOrders", JSON.stringify(ordersList)); // Cache data
                setLoading(false);
            });

            return () => unsubscribe();
        } catch (error) {
            console.error("Error fetching paid orders:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPaidOrders();
    }, [shopId]);


    useEffect(() => {
        const filtered = paidOrders.filter(order => {
            const orderMatchesSearch = 
                order.id.includes(searchTerm) ||
                (order.Storeorder_id && order.Storeorder_id.includes(searchTerm));
            
            // 🟢 NEW DATE RANGE FILTERING LOGIC
            const orderMatchesDateRange = 
                (!fromDate || new Date(order.order_date) >= new Date(fromDate)) &&
                (!toDate || new Date(order.order_date) <= new Date(toDate));
            
            return orderMatchesSearch && orderMatchesDateRange;
        });
        setFilteredOrders(filtered);
    }, [searchTerm, paidOrders, fromDate, toDate]);


    // Helper function to calculate updated selling price with shipping and discount distribution
    // const calculateAdjustedSellingPrice = (order, item) => {
    //     if (!item) return 0;
        
    //     const originalPrice = item.selling_price || 0;
    //     const orderItems = order.order_items || [];
    //     const totalItems = orderItems.length;
        
    //     if (totalItems === 0) return originalPrice;
        
    //     // Calculate per-item shipping charge
    //     const shippingPerItem = (order.shipping_charge || 0) / totalItems;
        
    //     // Calculate per-item discount based on item's proportion of total value
    //     let totalOrderValue = 0;
    //     orderItems.forEach(orderItem => {
    //         totalOrderValue += (orderItem.selling_price || 0) * (orderItem.units || 1);
    //     });
        
    //     let discountPerItem = 0;
    //     if (totalOrderValue > 0 && order.total_discount > 0) {
    //         const itemTotalValue = originalPrice * (item.units || 1);
    //         const itemProportion = itemTotalValue / totalOrderValue;
    //         discountPerItem = (order.total_discount * itemProportion) / (item.units || 1);
    //     }
        
    //     // Adjust selling price: Add shipping and subtract discount
    //     const adjustedPrice = originalPrice + shippingPerItem - discountPerItem;
    //     return adjustedPrice > 0 ? adjustedPrice : 0;
    // };


    const calculateAdjustedSellingPrice = (order, item) => {
        if (!item) return 0;
        
        // Convert selling_price from string to number
        const originalPrice = parseFloat(item.selling_price || 0);
        const orderItems = order.order_items || [];
        const totalItems = orderItems.length;
        
        if (totalItems === 0) return originalPrice;
        
        // Convert shipping_charge from string to number
        const shippingPerItem = parseFloat(order.shipping_charge || 0) / totalItems;
        
        // Calculate per-item discount based on item's proportion of total value
        let totalOrderValue = 0;
        orderItems.forEach(orderItem => {
            totalOrderValue += parseFloat(orderItem.selling_price || 0) * (orderItem.units || 1);
        });
        
        let discountPerItem = 0;
        if (totalOrderValue > 0 && order.total_discount > 0) {
            const itemTotalValue = originalPrice * (item.units || 1);
            const itemProportion = itemTotalValue / totalOrderValue;
            discountPerItem = (parseFloat(order.total_discount) * itemProportion) / (item.units || 1);
        }
        
        // Adjust selling price: Add shipping and subtract discount
        const adjustedPrice = originalPrice + shippingPerItem - discountPerItem;
        return adjustedPrice > 0 ? adjustedPrice : 0;
    };

    // Calculate tax based on the adjusted selling price (which already includes shipping and discount)
    const calculateTax = (adjustedPrice, state) => {
        const isMaharashtra = (state === 'Maharashtra');
        let sgst = 'NA', cgst = 'NA', igst = 'NA';
        let taxRate = 0;
        let preTaxPrice = 0;
        
        if (adjustedPrice < 1000) {
            taxRate = 5;
            preTaxPrice = adjustedPrice / 1.05;
        } else {
            taxRate = 12;
            preTaxPrice = adjustedPrice / 1.12;
        }
        
        const totalTax = adjustedPrice - preTaxPrice;
        
        if (isMaharashtra) {
            sgst = (totalTax / 2).toFixed(2);
            cgst = (totalTax / 2).toFixed(2);
            igst = '0.00';
        } else {
            sgst = '0.00';
            cgst = '0.00';
            igst = totalTax.toFixed(2);
        }
        
        return { 
            sgst, 
            cgst, 
            igst, 
            taxRate, 
            preTaxPrice: preTaxPrice.toFixed(2), 
            totalTax: totalTax.toFixed(2) 
        };
    };

    // Extract invoice number from SalesinvoiceUrl
    const extractInvoiceNumber = (url) => {
        if (!url) return 'NA';
        
        // Parse the URL to extract the filename
        try {
            // The pattern we're looking for is something like "MOS_MH_24-25_043.pdf"
            const regex = /([A-Z]+_[A-Z]+_\d+-\d+_\d+)\.pdf/;
            const match = url.match(regex);
            
            if (match && match[1]) {
                return match[1];
            }
        } catch (error) {
            console.error("Error extracting invoice number:", error);
        }
        
        return 'NA';
    };

    // Convert number to words for Indian currency
    const numberToWords = (num) => {
        const single = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const double = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
        const formatTens = (num) => {
            if (num < 10) return single[num];
            else if (num < 20) return double[num - 10];
            else {
                return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + single[num % 10] : '');
            }
        };
        
        if (num === 0) return 'Zero Rupees Only';
        
        let words = '';
        
        // Handle crores
        if (Math.floor(num / 10000000) > 0) {
            words += numberToWords(Math.floor(num / 10000000)) + ' Crore ';
            num %= 10000000;
        }
        
        // Handle lakhs
        if (Math.floor(num / 100000) > 0) {
            words += formatTens(Math.floor(num / 100000)) + ' Lakh ';
            num %= 100000;
        }
        
        // Handle thousands
        if (Math.floor(num / 1000) > 0) {
            words += formatTens(Math.floor(num / 1000)) + ' Thousand ';
            num %= 1000;
        }
        
        // Handle hundreds
        if (Math.floor(num / 100) > 0) {
            words += single[Math.floor(num / 100)] + ' Hundred ';
            num %= 100;
        }
        
        if (num > 0) {
            words += formatTens(num);
        }
        
        return words.trim() + ' Rupees Only';
    };

    const downloadCSV = () => {
        const filteredOrdersForDownload = paidOrders.filter(order => 
            (!fromDate || new Date(order.order_date) >= new Date(fromDate)) &&
            (!toDate || new Date(order.order_date) <= new Date(toDate))
        );
        
        // Create rows for CSV, with multiple rows for orders with multiple items
        let csvRows = [];
        
        filteredOrdersForDownload.forEach(order => {
            // Format the order date
            const orderDate = order.order_date ? new Date(order.order_date).toLocaleDateString('en-GB') : 'NA';
            
            // Format the shipping address
            const shipToAddress = [
                order.shipping_address || order.billing_address || '',
                order.shipping_city || order.billing_city || '',
                order.shipping_pincode || order.billing_pincode || '',
                order.shipping_country || order.billing_country || ''
            ].filter(Boolean).join(', ');
            
            // Add state separately (for the new column)
            const state = order.shipping_state || order.billing_state || 'NA';
            
            // Extract invoice number from SalesinvoiceUrl if available
            const invoiceNumber = order.SalesinvoiceUrl 
                ? extractInvoiceNumber(order.SalesinvoiceUrl) 
                : (order.Storeorder_id || order.id || 'NA');
            
            // Fixed HSN code as requested
            const hsnCode = '610910';
            
            // Check if order has items
            if (!order.order_items || order.order_items.length === 0) {
                // Add a single row with NA for item fields
                csvRows.push({
                    'Date': orderDate,
                    'Invoice Number': invoiceNumber,
                    'Ship To Name': order.shipping_customer_name || order.billing_customer_name || 'NA',
                    'Ship To Address': shipToAddress || 'NA',
                    'State': state,
                    'Item Description': 'NA',
                    'HSN Code': hsnCode,
                    'Quantity': '0',
                    'Price': '0.00',
                    'Tax Rate': '0',
                    'Amount': '0.00',
                    'Subtotal (Excl. Tax)': '0.00',
                    'CGST': '0.00',
                    'SGST': '0.00',
                    'IGST': '0.00',
                    'Total Tax': '0.00',
                    'Total': '0.00',
                    'Amount in Words': 'Zero Rupees Only'
                });
            } else {
                // Calculate order totals
                let orderSubtotal = 0;
                let orderTotalTax = 0;
                let orderTotal = 0;
                let orderCGST = 0;
                let orderSGST = 0;
                let orderIGST = 0;
                
                // Add separate row for each item in the order
                order.order_items.forEach(item => {
                    // Calculate adjusted selling price with shipping and discount distribution
                    const adjustedPrice = calculateAdjustedSellingPrice(order, item);
                    
                    // Calculate tax based on adjusted price and state
                    const { sgst, cgst, igst, taxRate, preTaxPrice, totalTax } = 
                        calculateTax(adjustedPrice, state);
                    
                    // Calculate line item total (preTaxPrice * quantity)
                    const quantity = item.units || 1;
                    const itemPreTaxTotal = parseFloat(preTaxPrice) * quantity;
                    const itemTax = parseFloat(totalTax) * quantity;
                    const itemTotal = adjustedPrice * quantity;
                    
                    // Update order totals
                    orderSubtotal += itemPreTaxTotal;
                    orderTotalTax += itemTax;
                    orderTotal += itemTotal;
                    orderCGST += parseFloat(cgst) * quantity;
                    orderSGST += parseFloat(sgst) * quantity;
                    orderIGST += parseFloat(igst) * quantity;
                    
                    csvRows.push({
                        'Date': orderDate,
                        'Invoice Number': invoiceNumber,
                        'Ship To Name': order.shipping_customer_name || order.billing_customer_name || 'NA',
                        'Ship To Address': shipToAddress || 'NA',
                        'State': state,
                        'Item Description': `${item.productName || 'NA'}${item.name ? ' - ' + item.name : ''}${item.size ? ' - ' + item.size : ''}`,
                        'HSN Code': hsnCode,
                        'Quantity': quantity,
                        'Price': parseFloat(preTaxPrice).toFixed(2),
                        'Tax Rate': taxRate + '%',
                        'Amount': itemPreTaxTotal.toFixed(2),
                        'Subtotal (Excl. Tax)': orderSubtotal.toFixed(2),
                        'CGST': orderCGST.toFixed(2),
                        'SGST': orderSGST.toFixed(2),
                        'IGST': orderIGST.toFixed(2),
                        'Total Tax': orderTotalTax.toFixed(2),
                        'Total': orderTotal.toFixed(2),
                        'Amount in Words': numberToWords(Math.round(orderTotal))
                    });
                });
            }
        });
        
        // Generate CSV and trigger download
        const csv = Papa.unparse(csvRows);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const fromDateStr = fromDate ? new Date(fromDate).toLocaleDateString('en-GB').replace(/\//g, '-') : 'AllTime';
        const toDateStr = toDate ? new Date(toDate).toLocaleDateString('en-GB').replace(/\//g, '-') : 'AllTime';
        link.setAttribute('download', `SalesReport_${fromDateStr}_to_${toDateStr}.csv`);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    if (loading) {
        return <div>Loading paid orders...</div>;
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <h1>Sales Invoice</h1>

                    <div className="date-filter-container">
                        <div className="date-input-group">
                            <label htmlFor="fromDate">From Date:</label>
                            <input 
                                type="date" 
                                id="fromDate"
                                value={fromDate} 
                                onChange={(e) => setFromDate(e.target.value)} 
                                className="date-input"
                            />
                        </div>
                        <div className="date-input-group">
                            <label htmlFor="toDate">To Date:</label>
                            <input 
                                type="date" 
                                id="toDate"
                                value={toDate} 
                                onChange={(e) => setToDate(e.target.value)} 
                                className="date-input"
                            />
                        </div>
                    <button onClick={downloadCSV} className="download-report-btn">
                        Download Report
                    </button>
                    </div>
                    <input 
                        type="text" 
                        placeholder="Search by Order ID or Store Order ID" 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                        className="search-bar"
                    />
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Unitee Order ID</th>
                                <th>SKU</th>
                                <th>Quantity</th>
                                {/* <th>Price</th>
                                <th>Subtotal</th>
                                <th>Total</th> */}
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOrders.map((order) => (
                                <tr key={order.id}>
                                    <td>{order.Storeorder_id || 'NA'}</td>
                                    <td>{order.id || 'NA'}</td>
                                    <td>
                                        {order.order_items && order.order_items.map((item, index) => (
                                            <p key={index}>{item.sku}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items && order.order_items.map((item, index) => (
                                            <p key={index}>{item.units}</p>
                                        ))}
                                    </td>
                                    {/* <td>
                                        {order.order_items && order.order_items.map((item, index) => (
                                            <p key={index}>₹{calculateAdjustedSellingPrice(order, item).toFixed(2)}</p>
                                        ))}
                                    </td>
                                    <td>₹{order.sub_total || 'NA'}</td>
                                    <td>₹{order.UniteeTotalPrice || 'NA'}</td> */}
                                    <td>
                                        {order.SalesinvoiceUrl ? (
                                            <button 
                                                onClick={() => window.open(order.SalesinvoiceUrl, '_blank')}
                                                className="download-invoice-btn"
                                            >
                                                Download Invoice
                                            </button>
                                        ) : (
                                            <span>No Invoice</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
     
    );
};

export default SalesInvoice;


// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase';
// import { collection, query, where, onSnapshot } from 'firebase/firestore';
// import { useLocation } from 'react-router-dom';
// import Papa from 'papaparse';
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const SalesInvoice = () => {
//     const [paidOrders, setPaidOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState("");
//     const [filteredOrders, setFilteredOrders] = useState([]);

//     const [fromDate, setFromDate] = useState('');
//     const [toDate, setToDate] = useState('');

//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop');

//     const user = auth.currentUser;
//     if (!user) {
//         throw new Error("User not logged in");
//     }

//     // Function to fetch orders
//     const fetchPaidOrders = async () => {
//         const cachedOrders = sessionStorage.getItem("paidOrders");
//         if (cachedOrders) {
//             const ordersList = JSON.parse(cachedOrders);
//             setPaidOrders(ordersList);
//             setFilteredOrders(ordersList);
//             setLoading(false);
//             return;
//         }

//         try {
//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
//             const q = query(ordersCollection, where('isPaid', '==', true));

//             const unsubscribe = onSnapshot(q, (snapshot) => {
//                 const ordersList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//                 setPaidOrders(ordersList);
//                 setFilteredOrders(ordersList);
//                 sessionStorage.setItem("paidOrders", JSON.stringify(ordersList)); // Cache data
//                 setLoading(false);
//             });

//             return () => unsubscribe();
//         } catch (error) {
//             console.error("Error fetching paid orders:", error);
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchPaidOrders();
//     }, [shopId]);


//     useEffect(() => {
//         const filtered = paidOrders.filter(order => {
//             const orderMatchesSearch = 
//                 order.id.includes(searchTerm) ||
//                 (order.Storeorder_id && order.Storeorder_id.includes(searchTerm));
            
//             // 🟢 NEW DATE RANGE FILTERING LOGIC
//             const orderMatchesDateRange = 
//                 (!fromDate || new Date(order.order_date) >= new Date(fromDate)) &&
//                 (!toDate || new Date(order.order_date) <= new Date(toDate));
            
//             return orderMatchesSearch && orderMatchesDateRange;
//         });
//         setFilteredOrders(filtered);
//     }, [searchTerm, paidOrders, fromDate, toDate]);


//     // Helper function to calculate updated selling price with shipping and discount distribution
//     const calculateAdjustedSellingPrice = (order, item) => {
//         if (!item) return 0;
        
//         const originalPrice = item.selling_price || 0;
//         const orderItems = order.order_items || [];
//         const totalItems = orderItems.length;
        
//         if (totalItems === 0) return originalPrice;
        
//         // Calculate per-item shipping charge
//         const shippingPerItem = (order.shipping_charge || 0) / totalItems;
        
//         // Calculate per-item discount based on item's proportion of total value
//         let totalOrderValue = 0;
//         orderItems.forEach(orderItem => {
//             totalOrderValue += (orderItem.selling_price || 0) * (orderItem.units || 1);
//         });
        
//         let discountPerItem = 0;
//         if (totalOrderValue > 0 && order.total_discount > 0) {
//             const itemTotalValue = originalPrice * (item.units || 1);
//             const itemProportion = itemTotalValue / totalOrderValue;
//             discountPerItem = (order.total_discount * itemProportion) / (item.units || 1);
//         }
        
//         // Adjust selling price: Add shipping and subtract discount
//         const adjustedPrice = originalPrice + shippingPerItem - discountPerItem;
//         return adjustedPrice > 0 ? adjustedPrice : 0;
//     };

//     // Calculate tax based on the adjusted selling price (which already includes shipping and discount)
//     const calculateTax = (adjustedPrice, state) => {
//         const isMaharashtra = (state === 'Maharashtra');
//         let sgst = 'NA', cgst = 'NA', igst = 'NA';
//         let taxRate = 0;
//         let preTaxPrice = 0;
        
//         if (adjustedPrice < 1000) {
//             taxRate = 5;
//             preTaxPrice = adjustedPrice / 1.05;
//         } else {
//             taxRate = 12;
//             preTaxPrice = adjustedPrice / 1.12;
//         }
        
//         const totalTax = adjustedPrice - preTaxPrice;
        
//         if (isMaharashtra) {
//             sgst = (totalTax / 2).toFixed(2);
//             cgst = (totalTax / 2).toFixed(2);
//             igst = '0.00';
//         } else {
//             sgst = '0.00';
//             cgst = '0.00';
//             igst = totalTax.toFixed(2);
//         }
        
//         return { 
//             sgst, 
//             cgst, 
//             igst, 
//             taxRate, 
//             preTaxPrice: preTaxPrice.toFixed(2), 
//             totalTax: totalTax.toFixed(2) 
//         };
//     };

//     // Extract invoice number from SalesinvoiceUrl
//     const extractInvoiceNumber = (url) => {
//         if (!url) return 'NA';
        
//         // Parse the URL to extract the filename
//         try {
//             // The pattern we're looking for is something like "MOS_MH_24-25_043.pdf"
//             const regex = /([A-Z]+_[A-Z]+_\d+-\d+_\d+)\.pdf/;
//             const match = url.match(regex);
            
//             if (match && match[1]) {
//                 return match[1];
//             }
//         } catch (error) {
//             console.error("Error extracting invoice number:", error);
//         }
        
//         return 'NA';
//     };

//     // Convert number to words for Indian currency
//     const numberToWords = (num) => {
//         const single = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
//         const double = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
//         const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
//         const formatTens = (num) => {
//             if (num < 10) return single[num];
//             else if (num < 20) return double[num - 10];
//             else {
//                 return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + single[num % 10] : '');
//             }
//         };
        
//         if (num === 0) return 'Zero Rupees Only';
        
//         let words = '';
        
//         // Handle crores
//         if (Math.floor(num / 10000000) > 0) {
//             words += numberToWords(Math.floor(num / 10000000)) + ' Crore ';
//             num %= 10000000;
//         }
        
//         // Handle lakhs
//         if (Math.floor(num / 100000) > 0) {
//             words += formatTens(Math.floor(num / 100000)) + ' Lakh ';
//             num %= 100000;
//         }
        
//         // Handle thousands
//         if (Math.floor(num / 1000) > 0) {
//             words += formatTens(Math.floor(num / 1000)) + ' Thousand ';
//             num %= 1000;
//         }
        
//         // Handle hundreds
//         if (Math.floor(num / 100) > 0) {
//             words += single[Math.floor(num / 100)] + ' Hundred ';
//             num %= 100;
//         }
        
//         if (num > 0) {
//             words += formatTens(num);
//         }
        
//         return words.trim() + ' Rupees Only';
//     };

//     const downloadCSV = () => {
//         const filteredOrdersForDownload = paidOrders.filter(order => 
//             (!fromDate || new Date(order.order_date) >= new Date(fromDate)) &&
//             (!toDate || new Date(order.order_date) <= new Date(toDate))
//         );
        
//         // Create rows for CSV, with multiple rows for orders with multiple items
//         let csvRows = [];
        
//         filteredOrdersForDownload.forEach(order => {
//             // Format the order date
//             const orderDate = order.order_date ? new Date(order.order_date).toLocaleDateString('en-GB') : 'NA';
            
//             // Format the shipping address
//             const shipToAddress = [
//                 order.shipping_address || order.billing_address || '',
//                 order.shipping_city || order.billing_city || '',
//                 order.shipping_state || order.billing_state || '',
//                 order.shipping_pincode || order.billing_pincode || '',
//                 order.shipping_country || order.billing_country || ''
//             ].filter(Boolean).join(', ');
            
//             // Extract invoice number from SalesinvoiceUrl if available
//             const invoiceNumber = order.SalesinvoiceUrl 
//                 ? extractInvoiceNumber(order.SalesinvoiceUrl) 
//                 : (order.Storeorder_id || order.id || 'NA');
            
//             // Fixed HSN code as requested
//             const hsnCode = '610910';
            
//             // Check if order has items
//             if (!order.order_items || order.order_items.length === 0) {
//                 // Add a single row with NA for item fields
//                 csvRows.push({
//                     'Date': orderDate,
//                     'Invoice Number': invoiceNumber,
//                     'Ship To Name': order.shipping_customer_name || order.billing_customer_name || 'NA',
//                     'Ship To Address': shipToAddress || 'NA',
//                     'Item Description': 'NA',
//                     'HSN Code': hsnCode,
//                     'Quantity': '0',
//                     'Price': '0.00',
//                     'Tax Rate': '0',
//                     'Amount': '0.00',
//                     'Subtotal (Excl. Tax)': '0.00',
//                     'CGST': '0.00',
//                     'SGST': '0.00',
//                     'IGST': '0.00',
//                     'Total Tax': '0.00',
//                     'Total': '0.00',
//                     'Amount in Words': 'Zero Rupees Only'
//                 });
//             } else {
//                 // Calculate order totals
//                 let orderSubtotal = 0;
//                 let orderTotalTax = 0;
//                 let orderTotal = 0;
//                 let orderCGST = 0;
//                 let orderSGST = 0;
//                 let orderIGST = 0;
                
//                 // Add separate row for each item in the order
//                 order.order_items.forEach(item => {
//                     // Calculate adjusted selling price with shipping and discount distribution
//                     const adjustedPrice = calculateAdjustedSellingPrice(order, item);
                    
//                     // Calculate tax based on adjusted price and state
//                     const { sgst, cgst, igst, taxRate, preTaxPrice, totalTax } = 
//                         calculateTax(adjustedPrice, order.shipping_state || order.billing_state);
                    
//                     // Calculate line item total (preTaxPrice * quantity)
//                     const quantity = item.units || 1;
//                     const itemPreTaxTotal = parseFloat(preTaxPrice) * quantity;
//                     const itemTax = parseFloat(totalTax) * quantity;
//                     const itemTotal = adjustedPrice * quantity;
                    
//                     // Update order totals
//                     orderSubtotal += itemPreTaxTotal;
//                     orderTotalTax += itemTax;
//                     orderTotal += itemTotal;
//                     orderCGST += parseFloat(cgst) * quantity;
//                     orderSGST += parseFloat(sgst) * quantity;
//                     orderIGST += parseFloat(igst) * quantity;
                    
//                     csvRows.push({
//                         'Date': orderDate,
//                         'Invoice Number': invoiceNumber,
//                         'Ship To Name': order.shipping_customer_name || order.billing_customer_name || 'NA',
//                         'Ship To Address': shipToAddress || 'NA',
//                         'Item Description': `${item.productName || 'NA'}${item.name ? ' - ' + item.name : ''}${item.size ? ' - ' + item.size : ''}`,
//                         'HSN Code': hsnCode,
//                         'Quantity': quantity,
//                         'Price': parseFloat(preTaxPrice).toFixed(2),
//                         'Tax Rate': taxRate + '%',
//                         'Amount': itemPreTaxTotal.toFixed(2),
//                         'Subtotal (Excl. Tax)': itemPreTaxTotal.toFixed(2),
//                         'CGST': (parseFloat(cgst) * quantity).toFixed(2),
//                         'SGST': (parseFloat(sgst) * quantity).toFixed(2),
//                         'IGST': (parseFloat(igst) * quantity).toFixed(2),
//                         'Total Tax': itemTax.toFixed(2),
//                         'Total': itemTotal.toFixed(2),
//                         'Amount in Words': numberToWords(Math.round(itemTotal))
//                     });
//                 });
                
//                 // Add a summary row for the entire order
//                 csvRows.push({
//                     'Date': orderDate,
//                     'Invoice Number': invoiceNumber,
//                     'Ship To Name': order.shipping_customer_name || order.billing_customer_name || 'NA',
//                     'Ship To Address': shipToAddress || 'NA',
//                     'Item Description': '*** ORDER SUMMARY ***',
//                     'HSN Code': hsnCode,
//                     'Quantity': '',
//                     'Price': '',
//                     'Tax Rate': '',
//                     'Amount': '',
//                     'Subtotal (Excl. Tax)': orderSubtotal.toFixed(2),
//                     'CGST': orderCGST.toFixed(2),
//                     'SGST': orderSGST.toFixed(2),
//                     'IGST': orderIGST.toFixed(2),
//                     'Total Tax': orderTotalTax.toFixed(2),
//                     'Total': orderTotal.toFixed(2),
//                     'Amount in Words': numberToWords(Math.round(orderTotal))
//                 });
//             }
//         });
        
//         // Generate CSV and trigger download
//         const csv = Papa.unparse(csvRows);
//         const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//         const url = URL.createObjectURL(blob);
//         const link = document.createElement('a');
//         link.href = url;

//         const fromDateStr = fromDate ? new Date(fromDate).toLocaleDateString('en-GB').replace(/\//g, '-') : 'AllTime';
//         const toDateStr = toDate ? new Date(toDate).toLocaleDateString('en-GB').replace(/\//g, '-') : 'AllTime';
//         link.setAttribute('download', `SalesReport_${fromDateStr}_to_${toDateStr}.csv`);

//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     };

//     if (loading) {
//         return <div>Loading paid orders...</div>;
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>Sales Invoice</h1>

//                     <div className="date-filter-container">
//                         <div className="date-input-group">
//                             <label htmlFor="fromDate">From Date:</label>
//                             <input 
//                                 type="date" 
//                                 id="fromDate"
//                                 value={fromDate} 
//                                 onChange={(e) => setFromDate(e.target.value)} 
//                                 className="date-input"
//                             />
//                         </div>
//                         <div className="date-input-group">
//                             <label htmlFor="toDate">To Date:</label>
//                             <input 
//                                 type="date" 
//                                 id="toDate"
//                                 value={toDate} 
//                                 onChange={(e) => setToDate(e.target.value)} 
//                                 className="date-input"
//                             />
//                         </div>
//                     <button onClick={downloadCSV} className="download-report-btn">
//                         Download Report
//                     </button>
//                     </div>
//                     <input 
//                         type="text" 
//                         placeholder="Search by Order ID or Store Order ID" 
//                         value={searchTerm} 
//                         onChange={(e) => setSearchTerm(e.target.value)} 
//                         className="search-bar"
//                     />
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 <th>Unitee Order ID</th>
//                                 <th>SKU</th>
//                                 <th>Quantity</th>
//                                 {/* <th>Price</th>
//                                 <th>Subtotal</th>
//                                 <th>Total</th> */}
//                                 <th>Invoice</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredOrders.map((order) => (
//                                 <tr key={order.id}>
//                                     <td>{order.Storeorder_id || 'NA'}</td>
//                                     <td>{order.id || 'NA'}</td>
//                                     <td>
//                                         {order.order_items && order.order_items.map((item, index) => (
//                                             <p key={index}>{item.sku}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {order.order_items && order.order_items.map((item, index) => (
//                                             <p key={index}>{item.units}</p>
//                                         ))}
//                                     </td>
//                                     {/* <td>
//                                         {order.order_items && order.order_items.map((item, index) => (
//                                             <p key={index}>₹{calculateAdjustedSellingPrice(order, item).toFixed(2)}</p>
//                                         ))}
//                                     </td>
//                                     <td>₹{order.sub_total || 'NA'}</td>
//                                     <td>₹{order.UniteeTotalPrice || 'NA'}</td> */}
//                                     <td>
//                                         {order.SalesinvoiceUrl ? (
//                                             <button 
//                                                 onClick={() => window.open(order.SalesinvoiceUrl, '_blank')}
//                                                 className="download-invoice-btn"
//                                             >
//                                                 Download Invoice
//                                             </button>
//                                         ) : (
//                                             <span>No Invoice</span>
//                                         )}
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
     
//     );
// };

// export default SalesInvoice;