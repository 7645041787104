


// import React, { useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import Header from '../components/Header';
// import Sidebar from '../components/Sidebar';
// import '../style/Product.css';

// const products = [
//   {
//     id: 7,
//     name: 'Black Regular Fit T-shirt',
//     title: 'wlot',
//     colorCode: '#000000', // Black
//     images: ['BlackFitF.png', 'BlackFitB.png'],
//     description: 'Black Regular Fit T-shirt',
//     fabric: '100% Cotton',
//     BASE_COST: 180,
//     tax: 'tax',
//     mockups: ['BlackFitF.png', 'BlackFitB.png'],
//     texture: '21.png',
//     GSM: '180 GSM',
//     costStructure: {
//       'DTG Epson': {
//         logo: 60,
//         LessthenA4: 90,
//         A4less: 138,
//         A4: 114,
//         A3: 162,
//         full: 198,
//       },
//       'DTF Epson': {
//         logo: 36,
//         LessthenA4: 66,
//         A4less: 102,
//         A4: 90,
//         A3: 120,
//         full: 150,
//       },
//     },
//     clippingRects: {
//       front: { left: 600, top: 750, width: 1000, height: 1300 },
//       back: { left: 600, top: 680, width: 1000, height: 1300 },
//     },
//     print_method: ["DTG Epson", "DTF Epson"],
//     sizeChart: 'regularfit-tee.jpg',
//     sizes: ['S', 'M', 'L', 'XL', 'XXL']
//   },
//   {
//     id: 8,
//     name: 'White Regular Fit T-shirt',
//     title: 'wlot',
//     colorCode: '#FFFFFF', 
//     images: ['WhiteFitF.png', 'WhiteFitB.png'],
//     description: 'White Regular Fit T-shirt',
//     fabric: '100% Cotton',
//     BASE_COST: 180,
//     tax: 'tax',
//     mockups: ['WhiteFitF.png', 'WhiteFitB.png'],
//     texture: '21.png',
//     GSM: '180 GSM',
//     costStructure: {
//       'DTG Epson': {
//         logo: 36,
//         LessthenA4: 66,
//         A4less: 102,
//         A4: 90,
//         A3: 120,
//         full: 150,
//       },
//       'DTF Epson': {
//         logo: 36,
//         LessthenA4: 66,
//         A4less: 102,
//         A4: 90,
//         A3: 120,
//         full: 150,
//       },
//     },
//     clippingRects: {
//       front: { left: 600, top: 750, width: 1000, height: 1300 },
//       back: { left: 620, top: 680, width: 1000, height: 1300 },
//     },
//     print_method: ["DTG Epson", "DTF Epson"],
//     sizeChart: 'regularfit-tee.jpg',
//     sizes: ['S', 'M', 'L', 'XL', 'XXL']
//   },

//   {
//     id: 9,
//     name: 'Red Regular Fit T-shirt',
//     title: 'wlot',
//     colorCode: '#FF0000',
//     images: ['RedFitF.png', 'RedFitB.png'],
//     description: 'Red Regular Fit T-shirt',
//     fabric: '100% Cotton',
//     BASE_COST: 180,
//     tax: 'tax',
//     mockups: ['RedFitF.png', 'RedFitB.png'],
//     texture: '21.png',
//     GSM: '180 GSM',
//     costStructure: {
//       'DTG Epson': {
//         logo: 60,
//         LessthenA4: 90,
//         A4less: 138,
//         A4: 114,
//         A3: 162,
//         full: 198,
//       },
//       'DTF Epson': {
//         logo: 36,
//         LessthenA4: 66,
//         A4less: 102,
//         A4: 90,
//         A3: 120,
//        full: 150,
//       },
//     },
//     clippingRects: {
//       front: { left: 600, top: 700, width: 1000, height: 1300 },
//       back: { left: 600, top: 680, width: 1000, height: 1300 },
//     },
//     print_method: ["DTG Epson", "DTF Epson"],
//     sizeChart:'regularfit-tee.jpg',
//     sizes : ['S', 'M', 'L', 'XL', 'XXL']
//   },

//   {
//     id: 10,
//     name: 'Navy Blue Regular Fit T-shirt',
//     title: 'wlot',
//     colorCode: '#000080', // Navy Blue
//     images: ['NavyBlueFitF.png', 'NavyBlueFitB.png'],
//     description: 'Navy Blue Regular Fit T-shirt',
//     fabric: '100% Cotton',
//     BASE_COST: 180,
//     tax: 'tax',
//     mockups: ['NavyBlueFitF.png', 'NavyBlueFitB.png'],
//     texture: '21.png',
//     GSM: '180 GSM',
//     costStructure: {
//       'DTG Epson': {
//         logo: 60,
//         LessthenA4: 90,
//         A4less: 138,
//         A4: 114,
//         A3: 162,
//         full: 198,
//       },
//       'DTF Epson': {
//         logo: 36,
//         LessthenA4: 66,
//         A4less: 102,
//         A4: 90,
//         A3: 120,
//        full: 150,
//       },
//     },
//     clippingRects: {
//       front: { left: 600, top: 700, width: 1000, height: 1300 },
//       back: {left: 600, top: 680, width: 1000, height: 1300 },
//     },
//     print_method: ["DTG Epson", "DTF Epson"],
//     sizeChart:'regularfit-tee.jpg',
//     sizes : ['S', 'M', 'L', 'XL', 'XXL']
//   },

//   {
//     id: 11,
//     name: 'Blue Regular Fit T-shirt',
//     title: 'wlot',
//     colorCode: '#0000FF', // Blue
//     images: ['BlueFitF.png', 'BlueFitB.png'],
//     description: 'Blue Regular Fit T-shirt',
//     fabric: '100% Cotton',
//     BASE_COST: 180,
//     tax: 'tax',
//     mockups: ['BlueFitF.png', 'BlueFitB.png'],
//     texture: '21.png',
//     GSM: '180 GSM',
//     costStructure: {
//       'DTG Epson': {
//         logo: 60,
//         LessthenA4: 90,
//         A4less: 138,
//         A4: 114,
//         A3: 162,
//         full: 198,
//       },
//       'DTF Epson': {
//         logo: 36,
//         LessthenA4: 66,
//         A4less: 102,
//         A4: 90,
//         A3: 120,
//        full: 150,
//       },
//     },
//     clippingRects: {
//       front: { left: 580, top: 700, width: 1000, height: 1300 },
//       back: { left: 600, top: 680, width: 1000, height: 1300 },
//     },
//     print_method: ["DTG Epson", "DTF Epson"],
//     sizeChart:'regularfit-tee.jpg',
//     sizes : ['S', 'M', 'L', 'XL', 'XXL']
//   },

  
//   {
//     id: 12,
//     name: 'Maroon Regular Fit T-shirt',
//     title: 'wlot',
//     colorCode: '#800000', // Maroon
//     images: ['BrownFitF.png', 'BrownFitB.png'],
//     description: 'Maroon Regular Fit T-shirt',
//     fabric: '100% Cotton',
//     BASE_COST: 180,
//     tax: 'tax',
//     mockups: ['BrownFitF.png', 'BrownFitB.png'],
//     texture: '21.png',
//     GSM: '180 GSM',
//     costStructure: {
//       'DTG Epson': {
//         logo: 60,
//         LessthenA4: 90,
//         A4less: 138,
//         A4: 114,
//         A3: 162,
//         full: 198,
//       },
//       'DTF Epson': {
//         logo: 36,
//         LessthenA4: 66,
//         A4less: 102,
//         A4: 90,
//         A3: 120,
//        full: 150,
//       },
//     },
//     clippingRects: {
//       front: { left: 600, top: 700, width: 1000, height: 1300 },
//       back: { left: 600, top: 680, width: 1000, height: 1300 },
//     },
//     print_method: ["DTG Epson", "DTF Epson"],
//     sizeChart:'regularfit-tee.jpg',
//     sizes : ['S', 'M', 'L', 'XL', 'XXL']
//   }
// ];

// const SelectProduct = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const state = location.state;
//   const [selectedColor, setSelectedColor] = useState(products[0]); // Default to the first color (Black Regular Fit)

//   const handleProductClick = (product) => {
//     navigate('/design-uploadNew', { 
//       state: { 
//         product, 
//         costStructure: product.costStructure, 
//         BASE_COST: product.BASE_COST, 
//         name: product.name, 
//         GSM: product.GSM,
//         tax: product.tax, 
//         clippingRects: product.clippingRects, 
//         sizeChart: product.sizeChart, 
//         print_method: product.print_method, 
//         sizes: product.sizes 
//       } 
//     });
//   };

//   return (
//     <div className="dashboard">
//       <Header />
//       <div className="dashboard-container">
//         <Sidebar />
//         <div className="main-content">
//           <div className="product-selection">
//             <h2>Select Product</h2>
//             {/* Display selected color's mockup */}
//             <div className="product-card">
//               <img
//                 src={selectedColor.mockups[0]} // Front image of the selected color
//                 alt={selectedColor.name}
//                 className="product-image"
//               />
//             </div>

//             <p>{selectedColor.description}</p>
//             <p>{selectedColor.fabric}</p>
//             <p>Base Cost: ₹{selectedColor.BASE_COST} + {selectedColor.tax}</p>
//             <p>{selectedColor.GSM}</p>

//             {/* Color swatches */}
//             <div className="color-swatches">
//   {products.map((product) => (
//     <div
//       key={product.id}
//       className={`color-swatch ${selectedColor.id === product.id ? 'selected' : ''}`}
//       onClick={() => setSelectedColor(product)} // Change selected color
//       style={{ backgroundColor: product.colorCode }} // Set background color to the product's color code
//     >
//     </div>
//   ))}
// </div>

//             {/* Button to navigate to design upload page */}
//             <button onClick={() => handleProductClick(selectedColor)}>Go to Design Upload</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SelectProduct;


import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import '../style/Product.css';
import Sidebar from '../components/Sidebar';

const tShirts = [


  {
    id: 1,
    name: '190 GSM Oversized T-shirt',
    image: '190GSMWhite.jpg', // Replace with actual path
    Colors: ['Black', 'White'],
    fabric: '100% Cotton',
    products: [

      {
        id: 1,
        name: 'Black Oversized T-shirt',
        title: 'whot',
        images: ['190GSMBlack.jpg', '190GSMBlackB.jpg'],
        description: 'Black Oversized T-shirt',
        fabric: '100% Cotton',
        BASE_COST: 235,
        tax: 'tax',
        mockups: ['190GSMBlack.jpg', '190GSMBlackB.jpg'],
        texture: '21.png',
        GSM: '190 GSM',
        costStructure: {
          'DTG Epson': {
            logo: 60,
            LessthenA4: 90,
            A4less: 138,
            A4: 114,
            A3: 162,
            full: 198,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
        },
        clippingRects: {
          // front: { left: 600, top: 670, width: 1020, height: 1400 },
          front: { left: 540, top: 690, width: 1120, height: 1400 },
          back: { left: 540, top: 690, width: 1120, height: 1400 }
        },  
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'190-regular.jpg',
        sizes : ['S', 'M', 'L', 'XL', 'XXL']
      },
  

      {
        id: 2,
        name: 'White Oversized T-shirt',
        title: 'whot',
        images: ['190GSMWhite.jpg', '190GSMWhiteB.jpg'],
        description: 'White Oversized T-shirt',
        fabric: '100% Cotton',
        BASE_COST: 235,
        tax: 'tax',
        mockups: ['190GSMWhite.jpg', '190GSMWhiteB.jpg'],
        texture: '21.png',
        GSM: '190 GSM',
        costStructure: {
          'DTG Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
        },
  
        clippingRects: {
          front: { left: 540, top: 690, width: 1120, height: 1400 },
          back: { left: 540, top: 690, width: 1120, height: 1400 }
        },
  
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'190-regular.jpg',
        sizes : ['S', 'M', 'L', 'XL', 'XXL']
      }, 

    ]
  },



  {
    id: 2,
    name: '220 GSM Oversized T-shirt',
    image: '220GSMWhite.jpg', // Replace with actual path
    Colors: ['Black', 'White'],
    fabric: '100% Cotton',
    products: [

      {
        id: 1,
        name: 'Black Oversized T-shirt',
        title: 'whot',
        images: ['220GSMBlack.jpg', '220GSMBlackB.jpg'],
        description: 'Black Oversized T-shirt',
        fabric: '100% Cotton',
        BASE_COST: 250,
        tax: 'tax',
        mockups: ['220GSMBlack.jpg', '220GSMBlackB.jpg'],
        texture: '21.png',
        GSM: '220 GSM',
        costStructure: { 
          'DTG Epson': {
            logo: 60,
            LessthenA4: 90,
            A4less: 138,
            A4: 114,
            A3: 162,
            full: 198,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
        //   'DTG Brother':{
        //   logo: 60,
        //   LessthenA4: 104,
        //   A4: 175,
        //   A4less: 250,
        //   A3: 250,
        // full: 300,
        // }
      
      },
        clippingRects: {
          front: { left: 530, top: 690, width: 1120, height: 1400 },
          back: { left: 530, top: 690, width: 1120, height: 1400},
        },

        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'oversized-tee.jpg',
        sizes : ['S', 'M', 'L', 'XL']
      },
  

      {
        id: 2,
        name: 'White Oversized T-shirt',
        title: 'whot',
        images: ['220GSMWhite.jpg', '220GSMWhiteB.jpg'],
        description: 'White Oversized T-shirt',
        fabric: '100% Cotton',
        BASE_COST: 250,
        tax: 'tax',
        mockups: ['220GSMWhite.jpg', '220GSMWhiteB.jpg'],
        texture: '21.png',
        GSM: '220 GSM',
        costStructure:{
  
          'DTG Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
        //  'DTG Brother':{
        //   logo: 60,
        //   LessthenA4: 104,
        //   A4: 130,
        //   A4less: 200,
        //   A3: 200,
        // full: 250,
        // }
        } ,
        clippingRects: {
          front: { left: 530, top: 690, width: 1120, height: 1400 },
          back: { left: 530, top: 690, width: 1120, height: 1400},
        },    
        // print_method: ["DTG Epson", "DTF Epson","DTG Brother"],
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'oversized-tee.jpg',
        sizes : ['S', 'M', 'L', 'XL']
      },
    ]
  },




  {
    id: 3,
    name: '240 GSM Oversized T-shirt',
    image: 'blackF1.png', // Replace with actual path
    Colors: ['Off-White', 'Black'],
    fabric: '100% French terry cotton',
    products: [

      {
        id: 1,
        name: 'Off-White Oversized T-shirt',
        title: 'whot',
        images: ['240GSMOfwhiteFront.jpg', '240GSMOfwhiteBack.jpg'],
        description: 'FloralWhite Oversized T-shirt',
        fabric: '100% French terry cotton',
        BASE_COST: 280,
        tax: 'tax',
        mockups: ['240GSMOfwhiteFront.jpg', '240GSMOfwhiteBack.jpg'],
        texture: '21.png',
        GSM: '240 GSM',
        costStructure: {        
          'DTG Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
             A4: 90,
             A3: 120,
            full: 150,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
      },
        clippingRects: {
          front: { left: 530, top: 690, width: 1120, height: 1400 },
          back: { left: 530, top: 690, width: 1120, height: 1400},
        },
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'oversized-tee.jpg',
        sizes : ['S', 'M', 'L', 'XL']
      },

     {
      id: 2,
      name: 'Black Oversized T-shirt',
      title: 'whot',
      images: ['blackF1.png', '3.png'],
      description: 'Black Oversized T-shirt',
      fabric: '100% French terry cotton',
      BASE_COST: 280,
      tax: 'tax',
      mockups: ['blackF1.png', '3.png'],
      texture: '21.png',
      GSM: '240 GSM',
      costStructure: {        
        'DTG Epson': {
          logo: 60,
          LessthenA4: 90,
          A4less: 138,
          A4: 114,
          A3: 162,
          full: 198,
        },
        'DTF Epson': {
          logo: 36,
          LessthenA4: 66,
          A4less: 102,
          A4: 90,
          A3: 120,
         full: 150,
        },
    },
      clippingRects: {
        front: { left: 510, top: 690, width: 1120, height: 1400 },
        back: { left: 530, top: 690, width: 1120, height: 1400},
      },
      print_method: ["DTG Epson", "DTF Epson"],
      sizeChart:'oversized-tee.jpg',
      sizes : ['S', 'M', 'L', 'XL']
    },
    ]
  },



  {
    id: 4,
    name: 'Regular Fit T-shirt',
    image: 'BlackFitB.png', // Replace with actual path
    Colors: ['Black', 'White', 'Red', 'Navy Blue'],
    fabric: '100% Cotton',
    products :[
      {
        id: 1,
        name: 'Black Regular Fit T-shirt',
        title: 'wlot',
        images: ['BlackFitF.png', 'BlackFitB.png'],
        description: 'Black Regular Fit T-shirt',
        fabric: '100% Cotton',
        BASE_COST: 180,
        colors: '#000000',
        tax: 'tax',
        mockups: ['BlackFitF.png', 'BlackFitB.png'],
        texture: '21.png',
        GSM: '180 GSM',
        costStructure: {
          'DTG Epson': {
            logo: 60,
            LessthenA4: 90,
            A4less: 138,
            A4: 114,
            A3: 162,
            full: 198,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
        },
        clippingRects: {
          front: { left: 570, top: 750, width: 1067, height: 1333 },
          back: {  left: 570, top: 750, width: 1067, height: 1333 },
        },
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'regularfit-tee.jpg',
        sizes : ['S', 'M', 'L', 'XL', 'XXL']
      },
  
  
      {
        id: 2,
        name: 'White Regular Fit T-shirt',
        title: 'wlot',
        images: ['WhiteFitF.png', 'WhiteFitB.png'],
        description: 'White Regular Fit T-shirt',
        fabric: '100% Cotton',
        BASE_COST: 180,
        tax: 'tax',
        mockups: ['WhiteFitF.png', 'WhiteFitB.png'],
        texture: '21.png',
        GSM: '180 GSM',
        colors: '#FFFFFF', 
        costStructure: {
          'DTG Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
             A4: 90,
             A3: 120,
            full: 150,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
        },
        clippingRects: {
          front: { left: 570, top: 750, width: 1067, height: 1333 },
          back: {  left: 570, top: 750, width: 1067, height: 1333 },
        },
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'regularfit-tee.jpg',
        sizes : ['S', 'M', 'L', 'XL', 'XXL']
      },

      {
        id: 3,
        name: 'Red Regular Fit T-shirt',
        title: 'wlot',
        images: ['RedFitF.png', 'RedFitB.png'],
        description: 'Red Regular Fit T-shirt',
        fabric: '100% Cotton',
        BASE_COST: 180,
        tax: 'tax',
        mockups: ['RedFitF.png', 'RedFitB.png'],
        texture: '21.png',
        GSM: '180 GSM',
        colors: '#FF0000',
        costStructure: {
          'DTG Epson': {
            logo: 60,
            LessthenA4: 90,
            A4less: 138,
            A4: 114,
            A3: 162,
            full: 198,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
        },
        clippingRects: {
          front: { left: 570, top: 750, width: 1067, height: 1333 },
          back: {  left: 570, top: 750, width: 1067, height: 1333 },
        },
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'regularfit-tee.jpg',
        sizes : ['S', 'M', 'L', 'XL', 'XXL']
      },
  
      {
        id: 4,
        name: 'Navy Blue Regular Fit T-shirt',
        title: 'wlot',
        images: ['NavyBlueFitF.png', 'NavyBlueFitB.png'],
        description: 'Navy Blue Regular Fit T-shirt',
        fabric: '100% Cotton',
        BASE_COST: 180,
        tax: 'tax',
        mockups: ['NavyBlueFitF.png', 'NavyBlueFitB.png'],
        texture: '21.png',
        GSM: '180 GSM',
        colors: '#000080', 
        costStructure: {
          'DTG Epson': {
            logo: 60,
            LessthenA4: 90,
            A4less: 138,
            A4: 114,
            A3: 162,
            full: 198,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
        },
        clippingRects: {
          front: { left: 570, top: 750, width: 1067, height: 1333 },
          back: {  left: 570, top: 750, width: 1067, height: 1333 },
        },
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'regularfit-tee.jpg',
        sizes : ['S', 'M', 'L', 'XL', 'XXL']
      } ,

      {
        id: 5,
        name: 'Blue Regular Fit T-shirt',
        title: 'wlot',
        images: ['BlueFitF.png', 'BlueFitB.png'],
        description: 'Blue Regular Fit T-shirt',
        fabric: '100% Cotton',
        BASE_COST: 180,
        tax: 'tax',
        mockups: ['BlueFitF.png', 'BlueFitB.png'],
        texture: '21.png',
        GSM: '180 GSM',
        costStructure: {
          'DTG Epson': {
            logo: 60,
            LessthenA4: 90,
            A4less: 138,
            A4: 114,
            A3: 162,
            full: 198,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
        },
        clippingRects: {
          front: { left: 570, top: 750, width: 1067, height: 1333 },
          back: {  left: 570, top: 750, width: 1067, height: 1333 },
        },
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'regularfit-tee.jpg',
        sizes : ['S', 'M', 'L', 'XL', 'XXL']
      },
  
      {
        id: 6,
        name: 'Maroon Regular Fit T-shirt',
        title: 'wlot',
        images: ['BrownFitF.png', 'BrownFitB.png'],
        description: 'Maroon Regular Fit T-shirt',
        fabric: '100% Cotton',
        BASE_COST: 180,
        tax: 'tax',
        mockups: ['BrownFitF.png', 'BrownFitB.png'],
        texture: '21.png',
        GSM: '180 GSM',
        costStructure: {
          'DTG Epson': {
            logo: 60,
            LessthenA4: 90,
            A4less: 138,
            A4: 114,
            A3: 162,
            full: 198,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4less: 102,
            A4: 90,
            A3: 120,
           full: 150,
          },
        },
        clippingRects: {
          front: { left: 570, top: 750, width: 1067, height: 1333 },
          back: {  left: 570, top: 750, width: 1067, height: 1333 },
        },
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'regularfit-tee.jpg',
        sizes : ['S', 'M', 'L', 'XL', 'XXL']
      },  

    ]
  },

  {
    id: 5,
    name: 'Baby Tee',
    image: 'BabyTeeB.png', // Replace with actual path
    Colors: ['Black', 'White'],
    fabric: '95% cotton and 5% lycra',
    products: [
      {
        id: 1,
        name: 'Black Baby Tee',
        title: 'baby',
        images: ['BabyTeeB.jpg','BabyTeeB.jpg'],
        description: 'Black Baby Tee',
        fabric: '95% cotton and 5% lycra',
        BASE_COST: 205,
        tax: 'tax',
        mockups: ['BabyTeeB.jpg','BabyTeeB.jpg'],
        texture: '21.png',
        GSM: '190 GSM',
        colors: '#000000', 
        costStructure: {
          'DTG Epson': {
            logo: 60,
            LessthenA4: 90,
            A4: 114,
            full: 130,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4: 90,
            full: 102,
          },
        },
        clippingRects: {
          front: { left: 540, top: 800, width: 1111.3, height: 1333.3 },
          back: { left: 0, top: 0, width: 0, height: 0 },
        },
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'babyTee-SizeChart.jpg',
        sizes : ['XS','S', 'M', 'L', 'XL','XXL']
      },  

      {
        id: 2,
        name: 'White Baby Tee',
        title: 'baby',
        images: ['BabyTeeW.png','BabyTeeW.png' ],
        description: 'White Baby Tee',
        fabric: '95% cotton and 5% lycra',
        BASE_COST: 205,
        tax: 'tax',
        mockups: ['BabyTeeW.png','BabyTeeW.png'],
        texture: '21.png',
        GSM: '190 GSM',
        colors: '#FFFFFF', 
        costStructure: {
          'DTG Epson': {
            logo: 36,
            LessthenA4: 66,
            A4: 90,
            full: 102,
          },
          'DTF Epson': {
            logo: 36,
            LessthenA4: 66,
            A4: 90,
            full: 102,
          },
        },
        clippingRects: {
          front: { left: 540, top: 800, width: 1111.3, height: 1333.3 },
          back: { left: 0, top: 0, width: 0, height: 0 },
        },
        print_method: ["DTG Epson", "DTF Epson"],
        sizeChart:'babyTee-SizeChart.jpg',
        sizes : ['XS','S', 'M', 'L', 'XL','XXL']
      },  

    ]
  }
];

const SelectTShirt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shop = queryParams.get('shop');

  const handleTShirtClick = (id) => {
    const selectedTShirt = tShirts.find((tShirt) => tShirt.id === id);
    navigate(`/design-uploadNew`, { state: { id: id, tShirts: tShirts } }); // Pass tShirts array
  };
  

  return (
    <div className="dashboard">
      <Header />
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-content">
          <div className="product-list">
            {tShirts.map((tShirt) => (
              <div
                key={tShirt.id}
                className="product-card"
                onClick={() => handleTShirtClick(tShirt.id)}
              >
                <img src={tShirt.image} alt={tShirt.name} className="product-image" />
                <p className="product-description">{tShirt.name}</p>
                <p className="product-description">{tShirt.fabric}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTShirt;