// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase'; // Ensure you're importing auth if needed
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { useLocation } from 'react-router-dom'; // Use useLocation for query parameters
// import axios from 'axios'; // For making API requests
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const PaidOrdersPage = () => {
//     const [paidOrders, setPaidOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [trackingUrls, setTrackingUrls] = useState({}); // Store tracking URLs
//     const [searchTerm, setSearchTerm] = useState("");
// const [filteredOrders, setFilteredOrders] = useState([]);
//     const location = useLocation(); // Get the current location
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop'); // Retrieve shopId from query parameters

//       const user = auth.currentUser;
//       if (!user) {
//         throw new Error("User not logged in");
//       }

//     // Fetch paid orders from Firestore
//     const fetchPaidOrders = async () => {
//         // if (!shopId) {
//         //     console.error("Shop ID is missing");
//         //     setLoading(false);
//         //     return;
//         // }

//         try {
//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
//             const q = query(ordersCollection, where('isPaid', '==', true));
//             const ordersSnapshot = await getDocs(q);
//             const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             setPaidOrders(ordersList);

//             // Fetch tracking URLs for each order once orders are loaded
//             const newTrackingUrls = {};
//             for (const order of ordersList) {
//                 const trackingUrl = await fetchTrackingUrl(order.id);
//                 newTrackingUrls[order.id] = trackingUrl;
//             }
//             setTrackingUrls(newTrackingUrls);

//         } catch (error) {
//             console.error("Error fetching paid orders:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Fetch tracking URL from the backend
//     const fetchTrackingUrl = async (orderId) => {
//         try {
//             const response = await axios.get(
//                 `https://ufb-1.onrender.com/api/track-order?order_id=${orderId}`
//             );
//             if (response.data && response.data.tracking_url) {
//                 return response.data.tracking_url;
//             }
//         } catch (error) {
//             console.error("Error fetching tracking URL for order", orderId, error);
//             return null;
//         }
//         return null;
//     };

//     // Handle invoice download
//     const handleDownloadInvoice = (invoiceUrl) => {
//         if (invoiceUrl) {
//             window.open(invoiceUrl, '_blank');
//         } else {
//             alert('Invoice not available for this order.');
//         }
//     };

//     // Load paid orders on component mount
//     useEffect(() => {
//         fetchPaidOrders();
//     }, [shopId]);

//     useEffect(() => {
//         const filtered = paidOrders.filter(order => 
//             order.id.includes(searchTerm) || 
//             (order.Storeorder_id && order.Storeorder_id.includes(searchTerm))
//         );
//         setFilteredOrders(filtered);
//     }, [searchTerm, paidOrders]);

//     if (loading) {
//         return <div>Loading paid orders...</div>;
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>Paid Orders</h1>
//                     <input 
//     type="text" 
//     placeholder="Search by Order ID or Store Order ID" 
//     value={searchTerm} 
//     onChange={(e) => setSearchTerm(e.target.value)} 
//     className="search-bar"
// />
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 <th>Unitee Order ID</th>
//                                 <th>SKU</th>
//                                 <th>Quantity</th>
//                                 <th>Invoice</th>
//                                 <th>Tracking URL</th> {/* New column for Tracking URL */}
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredOrders.map((order) => (
//                                 <tr key={order.id}>

//                              <td>{order.Storeorder_id || 'NA'}</td>
//                                     <td>{order.id || 'NA'}</td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.sku}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.units}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {/* Check if invoice URL exists and add the button */}
//                                         {order.invoiceUrl ? (
//                                             <button 
//                                                 onClick={() => handleDownloadInvoice(order.invoiceUrl)} 
//                                                 className="download-invoice-btn"
//                                             >
//                                                 Download Invoice
//                                             </button>
//                                         ) : (
//                                             <span>No Invoice</span>
//                                         )}
//                                     </td>
//                                     <td>
                                     
//                                         {trackingUrls[order.id] ? (
//                                             <a href={trackingUrls[order.id]} target="_blank" rel="noopener noreferrer">
//                                                 Track Order
//                                             </a>
//                                         ) : (
//                                             <span>Printing Started</span>
//                                         )}
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PaidOrdersPage;



import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const PaidOrdersPage = () => {
    const [paidOrders, setPaidOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [trackingUrls, setTrackingUrls] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editedOrderData, setEditedOrderData] = useState({});
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop');

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }

    // Fetch paid orders from Firestore
    const fetchPaidOrders = async () => {
        try {
            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            const q = query(ordersCollection, where('isPaid', '==', true));
            const ordersSnapshot = await getDocs(q);
            const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPaidOrders(ordersList);

            // Fetch tracking URLs for each order once orders are loaded
            const newTrackingUrls = {};
            for (const order of ordersList) {
                const trackingUrl = await fetchTrackingUrl(order.id);
                newTrackingUrls[order.id] = trackingUrl;
            }
            setTrackingUrls(newTrackingUrls);

        } catch (error) {
            console.error("Error fetching paid orders:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch tracking URL from the backend
    const fetchTrackingUrl = async (orderId) => {
        try {
            const response = await axios.get(
                `https://ufb-1.onrender.com/api/track-order?order_id=${orderId}`
            );
            if (response.data && response.data.tracking_url) {
                return response.data.tracking_url;
            }
        } catch (error) {
            console.error("Error fetching tracking URL for order", orderId, error);
            return null;
        }
        return null;
    };

    // Handle invoice download
    const handleDownloadInvoice = (invoiceUrl) => {
        if (invoiceUrl) {
            window.open(invoiceUrl, '_blank');
        } else {
            alert('Invoice not available for this order.');
        }
    };

    // Open order details modal
    const openOrderModal = (order) => {
        setSelectedOrder(order);
        setEditedOrderData({ ...order });
        setIsModalOpen(true);
    };

    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
        setEditedOrderData({});
    };

    // Handle input change for order editing
    const handleOrderDataChange = (field, value) => {
        setEditedOrderData(prevData => ({
            ...prevData,
            [field]: value
        }));
    };

    // Handle line item change
    // const handleLineItemChange = (index, field, value) => {
    //     const updatedItems = [...editedOrderData.order_items];
    //     updatedItems[index] = {
    //         ...updatedItems[index],
    //         [field]: field === 'units' ? Number(value) : value
    //     };
        
    //     setEditedOrderData(prevData => ({
    //         ...prevData,
    //         order_items: updatedItems
    //     }));
    // };

    // Save updated order
    const saveOrderChanges = async () => {
        try {
            const orderRef = doc(db, 'users', user.uid, 'orders', selectedOrder.id);
            await updateDoc(orderRef, editedOrderData);
            
            // Update the order in the local state as well
            setPaidOrders(prevOrders => 
                prevOrders.map(order => 
                    order.id === selectedOrder.id ? editedOrderData : order
                )
            );
            
            alert("Order updated successfully!");
            closeModal();
        } catch (error) {
            console.error("Error updating order:", error);
            alert("Failed to update order. Please try again.");
        }
    };

    // Load paid orders on component mount
    useEffect(() => {
        fetchPaidOrders();
    }, [shopId]);

    // Filter orders based on search term and date range
    useEffect(() => {
        let filtered = paidOrders;
        
        // Filter by search term
        if (searchTerm) {
            filtered = filtered.filter(order => 
                order.id.includes(searchTerm) || 
                (order.Storeorder_id && order.Storeorder_id.includes(searchTerm))
            );
        }
        
        // Filter by date range
        if (startDate && endDate) {
            filtered = filtered.filter(order => {
                if (!order.order_date) return false;
                
                const orderDate = new Date(order.order_date);
                const start = new Date(startDate);
                const end = new Date(endDate);
                
                // Set end date to end of day
                end.setHours(23, 59, 59, 999);
                
                return orderDate >= start && orderDate <= end;
            });
        }
        
        setFilteredOrders(filtered);
    }, [searchTerm, startDate, endDate, paidOrders]);

    if (loading) {
        return <div>Loading paid orders...</div>;
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <h1>Paid Orders</h1>
                    
                    <div className="filters-container">
                        <input 
                            type="text" 
                            placeholder="Search by Order ID or Store Order ID" 
                            value={searchTerm} 
                            onChange={(e) => setSearchTerm(e.target.value)} 
                            className="search-bar"
                        />
                        
                        <div className="date-filters">
                            <div className="date-filter">
                                <label>From Date:</label>
                                <input 
                                    type="date" 
                                    value={startDate} 
                                    onChange={(e) => setStartDate(e.target.value)} 
                                />
                            </div>
                            
                            <div className="date-filter">
                                <label>To Date:</label>
                                <input 
                                    type="date" 
                                    value={endDate} 
                                    onChange={(e) => setEndDate(e.target.value)} 
                                />
                            </div>
                        </div>
                    </div>
                    
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Unitee Order ID</th>
                                <th>Order Date</th>
                                <th>SKU</th>
                                <th>Quantity</th>
                                <th>Invoice</th>
                                <th>Tracking URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOrders.map((order) => (
                                <tr key={order.id}>
                                    <td 
                                        className="order-id-cell" 
                                        onClick={() => openOrderModal(order)}
                                    >
                                        {order.Storeorder_id || 'NA'}
                                    </td>
                                    <td>{order.id || 'NA'}</td>
                                    <td>{order.order_date ? new Date(order.order_date).toLocaleDateString() : 'NA'}</td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.sku}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.units}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.invoiceUrl ? (
                                            <button 
                                                onClick={() => handleDownloadInvoice(order.invoiceUrl)} 
                                                className="download-invoice-btn"
                                            >
                                                Download Invoice
                                            </button>
                                        ) : (
                                            <span>No Invoice</span>
                                        )}
                                    </td>
                                    <td>
                                        {trackingUrls[order.id] ? (
                                            <a href={trackingUrls[order.id]} target="_blank" rel="noopener noreferrer">
                                                Track Order
                                            </a>
                                        ) : (
                                            <span>Printing Started</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            
            {/* Order Details Modal */}
            {isModalOpen && selectedOrder && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Order Details</h2>
                            <button className="close-btn" onClick={closeModal}>×</button>
                        </div>
                        
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Store Order ID:</label>
                                <input 
                                    type="text" 
                                    value={editedOrderData.Storeorder_id || ''} 
                                    // onChange={(e) => handleOrderDataChange('Storeorder_id', e.target.value)}
                                />
                            </div>
                            
                            <div className="form-group">
                                <label>Unitee Order ID:</label>
                                <span>{selectedOrder.id}</span>
                            </div>
                            
                            <div className="form-row">
                                {/* <div className="form-group">
                                    <label>Shipping:</label>
                                    <input 
                                        type="number" 
                                        value={editedOrderData.UniteeShipping || 0} 
                                        onChange={(e) => handleOrderDataChange('UniteeShipping', Number(e.target.value))}
                                    />
                                </div> */}
                                
                                {/* <div className="form-group">
                                    <label>Total Price:</label>
                                    <input 
                                        type="number" 
                                        value={editedOrderData.UniteeTotalPrice || 0} 
                                        onChange={(e) => handleOrderDataChange('UniteeTotalPrice', Number(e.target.value))}
                                    />
                                </div> */}
                            </div>
                            
                            <h3>Billing Information</h3>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Customer Name:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_customer_name || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_customer_name', e.target.value)}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>Last Name:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_last_name || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_last_name', e.target.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Email:</label>
                                    <input 
                                        type="email" 
                                        value={editedOrderData.billing_email || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_email', e.target.value)}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>Phone:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_phone || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_phone', e.target.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <label>Address:</label>
                                <input 
                                    type="text" 
                                    value={editedOrderData.billing_address || ''} 
                                    onChange={(e) => handleOrderDataChange('billing_address', e.target.value)}
                                />
                            </div>
                            
                            <div className="form-row">
                                <div className="form-group">
                                    <label>City:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_city || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_city', e.target.value)}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>State:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_state || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_state', e.target.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Pincode:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_pincode || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_pincode', e.target.value)}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>Country:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_country || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_country', e.target.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <label>Payment Method:</label>
                                <input 
                                    type="text" 
                                    value={editedOrderData.payment_method || ''} 
                                    // onChange={(e) => handleOrderDataChange('payment_method', e.target.value)}
                                />
                            </div>
                            
                            <h3>Order Items</h3>
                            {editedOrderData.order_items && editedOrderData.order_items.map((item, index) => (
                                <div key={index} className="order-item">
                                    <h4>Item {index + 1}</h4>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>SKU:</label>
                                            <input 
                                                type="text" 
                                                value={item.sku || ''} 
                                                // onChange={(e) => handleLineItemChange(index, 'sku', e.target.value)}
                                            />
                                        </div>
                                        
                                        <div className="form-group">
                                            <label>Name:</label>
                                            <input 
                                                type="text" 
                                                value={item.name || ''} 
                                                // onChange={(e) => handleLineItemChange(index, 'name', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>Units:</label>
                                            <input 
                                                type="number" 
                                                value={item.units || 0} 
                                                // onChange={(e) => handleLineItemChange(index, 'units', e.target.value)}
                                            />
                                        </div>
                                        
                                        <div className="form-group">
                                            <label>Price:</label>
                                            <input 
                                                type="text" 
                                                value={item.selling_price || ''} 
                                                // onChange={(e) => handleLineItemChange(index, 'selling_price', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                        <div className="modal-footer">
                            <button className="cancel-btn" onClick={closeModal}>Cancel</button>
                            <button className="save-btn" onClick={saveOrderChanges}>Save Changes</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PaidOrdersPage;




// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase';
// import { collection, getDocs, query, where, onSnapshot } from 'firebase/firestore';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const PaidOrdersPage = () => {
//     const [paidOrders, setPaidOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [trackingUrls, setTrackingUrls] = useState({});
//     const [searchTerm, setSearchTerm] = useState("");
//     const [filteredOrders, setFilteredOrders] = useState([]);
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop');

//     const user = auth.currentUser;
//     if (!user) {
//         throw new Error("User not logged in");
//     }

//     // Function to fetch orders
//     const fetchPaidOrders = async () => {
//         const cachedOrders = sessionStorage.getItem("paidOrders");
//         if (cachedOrders) {
//             const ordersList = JSON.parse(cachedOrders);
//             setPaidOrders(ordersList);
//             setFilteredOrders(ordersList);
//             setLoading(false);
//             return;
//         }

//         try {
//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
//             const q = query(ordersCollection, where('isPaid', '==', true));

//             const unsubscribe = onSnapshot(q, (snapshot) => {
//                 const ordersList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//                 setPaidOrders(ordersList);
//                 setFilteredOrders(ordersList);
//                 sessionStorage.setItem("paidOrders", JSON.stringify(ordersList)); // Cache data
//                 setLoading(false);
//             });

//             return () => unsubscribe();
//         } catch (error) {
//             console.error("Error fetching paid orders:", error);
//             setLoading(false);
//         }
//     };

//     // Fetch tracking URL from backend only if not cached
//     const fetchTrackingUrl = async (orderId) => {
//         if (trackingUrls[orderId]) return trackingUrls[orderId]; // Use cached tracking URL

//         try {
//             const response = await axios.get(
//                 `http://localhost:8080/api/track-order?order_id=${orderId}`
//             );
//             if (response.data?.tracking_url) {
//                 setTrackingUrls(prev => ({ ...prev, [orderId]: response.data.tracking_url }));
//                 return response.data.tracking_url;
//             }
//         } catch (error) {
//             console.error("Error fetching tracking URL for order", orderId, error);
//         }
//         return null;
//     };

//     useEffect(() => {
//         fetchPaidOrders();
//     }, [shopId]);

//     useEffect(() => {
//         const filtered = paidOrders.filter(order =>
//             order.id.includes(searchTerm) ||
//             (order.Storeorder_id && order.Storeorder_id.includes(searchTerm))
//         );
//         setFilteredOrders(filtered);
//     }, [searchTerm, paidOrders]);

//     if (loading) {
//         return <div>Loading paid orders...</div>;
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>Paid Orders</h1>
//                     <input 
//                         type="text" 
//                         placeholder="Search by Order ID or Store Order ID" 
//                         value={searchTerm} 
//                         onChange={(e) => setSearchTerm(e.target.value)} 
//                         className="search-bar"
//                     />
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 <th>Unitee Order ID</th>
//                                 <th>SKU</th>
//                                 <th>Quantity</th>
//                                 <th>Invoice</th>
//                                 <th>Tracking URL</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredOrders.map((order) => (
//                                 <tr key={order.id}>
//                                     <td>{order.Storeorder_id || 'NA'}</td>
//                                     <td>{order.id || 'NA'}</td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.sku}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.units}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {order.invoiceUrl ? (
//                                             <button 
//                                                 onClick={() => window.open(order.invoiceUrl, '_blank')}
//                                                 className="download-invoice-btn"
//                                             >
//                                                 Download Invoice
//                                             </button>
//                                         ) : (
//                                             <span>No Invoice</span>
//                                         )}
//                                     </td>
//                                     <td>
//                                         {trackingUrls[order.id] ? (
//                                             <a href={trackingUrls[order.id]} target="_blank" rel="noopener noreferrer">
//                                                 Track Order
//                                             </a>
//                                         ) : (
//                                             <span>Printing Started</span>
//                                         )}
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PaidOrdersPage;


