

// import React, { useEffect, useState } from 'react';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
// import { getAuth } from 'firebase/auth'; // Import Firebase Authentication
// import '../style/RTOinventory.css';

// const UserRTOInventoryPanel = () => {
//     const [inventory, setInventory] = useState({});
//     const [loading, setLoading] = useState(true);
//     const [user, setUser] = useState(null); // State to store user data
//     const [designs, setDesigns] = useState([]); // State to store designs data
//     const [loadingDesigns, setLoadingDesigns] = useState(true); // Loading state for designs

//     // Fetch current user data
//     const fetchCurrentUser = () => {
//         const auth = getAuth();
//         const currentUser = auth.currentUser; // Get the current user from Firebase Auth
//         console.log('Current User:', currentUser);

//         if (currentUser) {
//             setUser(currentUser); // Store the user data if authenticated
//         } else {
//             setUser(null); // If no user is authenticated, set user to null
//         }
//     };

//     // Fetch designs data
//     const fetchDesignsData = async () => {
//         try {
//             console.log('Fetching designs data...');
//             const designsRef = collection(db, 'users', user?.uid, 'designs');
//             const querySnapshot = await getDocs(designsRef);
//             const designsData = [];

//             querySnapshot.forEach(docSnap => {
//                 const designData = docSnap.data();
//                 // Log the fetched design data to check if images are included
//                 console.log('Design data for SKU:', docSnap.id, designData);
//                 // Add the design and its SKUs
//                 if (designData.SKUs) {
//                     designsData.push({
//                         designId: docSnap.id, // Store design ID
//                         frontImage: designData.images?.front, // Store front image URL
//                         backImage: designData.images?.back,  // Store back image URL
//                         skus: designData.SKUs, // Store SKU list
//                     });
//                 }
//             });

//             console.log('Fetched designs:', designsData);
//             setDesigns(designsData);
//             setLoadingDesigns(false); // Set designs loading state to false
//         } catch (error) {
//             console.error('Error fetching design data:', error);
//             setLoadingDesigns(false); // In case of error, stop loading state
//         }
//     };

//     // Fetch RTO inventory data based on current user's brandId
//     const fetchRTOInventory = async () => {
//         try {
//             console.log('Fetching RTO inventory...');
//             if (!user) {
//                 console.log('No user found, skipping RTO inventory fetch.');
//                 return;
//             }

//             // Query Firestore for RTO inventory where the brandId matches the current user's UID
//             const inventoryRef = collection(db, 'RTO_inventory');
//             const q = query(inventoryRef, where('brandId', '==', user?.uid));

//             const inventorySnapshot = await getDocs(q);
//             const userInventory = {};

//             // Iterate over inventory data
//             inventorySnapshot.forEach(docSnap => {
//                 const skuData = docSnap.data();
//                 const skuPrefix = docSnap.id; // SKU like 'u-948639'

//                 // Initialize inventory object for this SKU
//                 userInventory[skuPrefix] = {
//                     sku: skuPrefix,
//                     brandId: skuData.brandId,  // Save brand name here
//                     counts: {},
//                     frontImage: null, // Initialize front image as null
//                     backImage: null,  // Initialize back image as null
//                 };

//                 // Loop through the sizes and add counts
//                 Object.keys(skuData).forEach(size => {
//                     if (size !== 'brandId') {
//                         userInventory[skuPrefix].counts[size] = skuData[size];
//                     }
//                 });
//             });

//             console.log('RTO Inventory:', userInventory);

//             // Match SKU with designs and update inventory with images
//             Object.keys(userInventory).forEach(sku => {
//                 // Extract the base part of the SKU (get everything before the first '-')
//                 const skuBase = sku.split('-')[0] + '-' + sku.split('-')[1]; // Example: u-948639
//                 console.log(`Matching base SKU: ${skuBase}`);

//                 designs.forEach(design => {
//                     // Iterate over the design SKUs and extract the base part of each design SKU
//                     design.skus.forEach(designSKU => {
//                         const designBase = designSKU.split('-')[0] + '-' + designSKU.split('-')[1]; // Get base SKU like u-948639
//                         console.log(`Design base SKU: ${designBase} comparing with inventory SKU: ${skuBase}`);

//                         if (skuBase === designBase) {
//                             console.log(`Matching SKU found: ${skuBase} matches ${designBase}`);
//                             // If found, add front and back images to the inventory
//                             userInventory[sku].frontImage = design.frontImage;
//                             userInventory[sku].backImage = design.backImage;

//                             console.log(`Assigned Front Image: ${design.frontImage}`);
//                             console.log(`Assigned Back Image: ${design.backImage}`);
//                         }
//                     });
//                 });
//             });

//             setInventory(userInventory);
//             setLoading(false);
//         } catch (error) {
//             console.error('Error fetching RTO inventory:', error);
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchCurrentUser();  // Only run this once when the component mounts

//         // Fetch RTO inventory and designs once the user is available
//         if (user) {
//             console.log('User is authenticated, fetching data...');
//             fetchDesignsData();  // Fetch designs when user is available
//         }
//     }, [user]); // Only run when user changes (login/logout)

//     // Fetch RTO inventory when designs are ready
//     useEffect(() => {
//         if (!loadingDesigns && user) {
//             console.log('Designs loaded, fetching inventory...');
//             fetchRTOInventory(); // Fetch RTO inventory data once designs are loaded
//         }
//     }, [loadingDesigns, user]);

//     return (
//         <div className="user-inventory-panel">
//             <h1>RTO Inventory</h1>

//             {loading ? (
//                 <p>Loading inventory...</p>
//             ) : (
//                 <div>
//                     {/* If inventory is available */}
//                     {Object.keys(inventory).length > 0 ? (
//                         <table className="inventory-table">
//                             <thead>
//                                 <tr>
//                                     <th>SKU</th>
//                                     <th>Brand Name</th> {/* Add the brandName column */}
//                                     <th>Size</th>
//                                     <th>Inventory Count</th>
//                                     <th>Front Image</th>
//                                     <th>Back Image</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {/* Map over the inventory and display items */}
//                                 {Object.values(inventory).map(item => (
//                                     Object.keys(item.counts).map(size => (
//                                         <tr key={`${item.sku}-${size}`}>
//                                             <td>{item.sku}</td>
//                                             <td>{item.brandId}</td> {/* Show the brand name */}
//                                             <td>{size}</td>
//                                             <td>{item.counts[size]}</td>
//                                             <td>
//                                                 {item.frontImage ? (
//                                                     <img src={item.frontImage} alt="front" width="100" />
//                                                 ) : (
//                                                     <p>No front image for SKU {item.sku}</p> // Added SKU to help debug
//                                                 )}
//                                             </td>
//                                             <td>
//                                                 {item.backImage ? (
//                                                     <img src={item.backImage} alt="back" width="100" />
//                                                 ) : (
//                                                     <p>No back image for SKU {item.sku}</p> // Added SKU to help debug
//                                                 )}
//                                             </td>
//                                         </tr>
//                                     ))
//                                 ))}
//                             </tbody>
//                         </table>
//                     ) : (
//                         <p>No RTO inventory found for this brand.</p>
//                     )}
//                 </div>
//             )}
//         </div>
//     );
// };

// export default UserRTOInventoryPanel;




import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
import { getAuth } from 'firebase/auth'; // Import Firebase Authentication
import '../style/RTOinventory.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const UserRTOInventoryPanel = () => {
    const [inventory, setInventory] = useState({});
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null); // State to store user data
    const [designs, setDesigns] = useState([]); // State to store designs data
    const [loadingDesigns, setLoadingDesigns] = useState(true); // Loading state for designs

    const fetchCurrentUser = () => {
        const auth = getAuth();
        const currentUser = auth.currentUser; // Get the current user from Firebase Auth
        if (currentUser) {
            setUser(currentUser); // Store the user data if authenticated
        } else {
            setUser(null); // If no user is authenticated, set user to null
        }
    };

    const fetchDesignsData = async () => {
        try {
            const designsRef = collection(db, 'users', user?.uid, 'designs');
            const querySnapshot = await getDocs(designsRef);
            const designsData = [];

            querySnapshot.forEach(docSnap => {
                const designData = docSnap.data();
                if (designData.SKUs) {
                    designsData.push({
                        designId: docSnap.id,
                        frontImage: designData.images?.front,
                        backImage: designData.images?.back,
                        skus: designData.SKUs,
                    });
                }
            });

            setDesigns(designsData);
            setLoadingDesigns(false);
        } catch (error) {
            console.error('Error fetching design data:', error);
            setLoadingDesigns(false);
        }
    };

    const fetchRTOInventory = async () => {
        try {
            if (!user) return;

            const inventoryRef = collection(db, 'RTO_inventory');
            const q = query(inventoryRef, where('brandId', '==', user?.uid));

            const inventorySnapshot = await getDocs(q);
            const userInventory = {};

            inventorySnapshot.forEach(docSnap => {
                const skuData = docSnap.data();
                const skuPrefix = docSnap.id;
                userInventory[skuPrefix] = {
                    sku: skuPrefix,
                    brandId: skuData.brandId,
                    counts: {},
                    frontImage: null,
                    backImage: null,
                };

                Object.keys(skuData).forEach(size => {
                    if (size !== 'brandId') {
                        userInventory[skuPrefix].counts[size] = skuData[size];
                    }
                });
            });

            Object.keys(userInventory).forEach(sku => {
                const skuBase = sku.split('-')[0] + '-' + sku.split('-')[1];

                designs.forEach(design => {
                    design.skus.forEach(designSKU => {
                        const designBase = designSKU.split('-')[0] + '-' + designSKU.split('-')[1];

                        if (skuBase === designBase) {
                            userInventory[sku].frontImage = design.frontImage;
                            userInventory[sku].backImage = design.backImage;
                        }
                    });
                });
            });

            setInventory(userInventory);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching RTO inventory:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCurrentUser();
        if (user) {
            fetchDesignsData();
        }
    }, [user]);

    useEffect(() => {
        if (!loadingDesigns && user) {
            fetchRTOInventory();
        }
    }, [loadingDesigns, user]);

    return (
        <div className="dashboard">
            <Header />
            <div className="dashboard-container">
                <Sidebar />
                <div className="main-content">
                    <h1>RTO Inventory</h1>

                    {loading ? (
                        <p>Loading inventory...</p>
                    ) : (
                        <div className="inventory-cards">
                            {Object.keys(inventory).length > 0 ? (
                                Object.values(inventory).map(item => (
                                    <div key={item.sku} className="inventory-card">
                                        <div className="inventory-images">
                                            <div className="image-container">
                                                {item.frontImage ? (
                                                    <img src={item.frontImage} alt="front" className="image" />
                                                ) : (
                                                    <p>No front image</p>
                                                )}
                                            </div>
                                            <div className="image-container">
                                                {item.backImage ? (
                                                    <img src={item.backImage} alt="back" className="image" />
                                                ) : (
                                                    <p>No back image</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="inventory-details">
                                            <p><strong>SKU:</strong> {item.sku}</p>
                                            {/* <p><strong>Brand:</strong> {item.brandId}</p>  */}
                                            <div className="size-counts">
                                                {Object.keys(item.counts).map(size => (
                                                    <p key={size}>
                                                        <strong>{size}:</strong> {item.counts[size]}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No RTO inventory found for this brand.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserRTOInventoryPanel;
