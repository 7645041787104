import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, query, where, onSnapshot, doc, getDocs } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import Papa from 'papaparse';
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const PurchaseInvoice = () => {
    const [paidOrders, setPaidOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOrders, setFilteredOrders] = useState([]);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop');

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }

    // Function to fetch orders
    const fetchPaidOrders = async () => {
        const cachedOrders = sessionStorage.getItem("paidOrders");
        if (cachedOrders) {
            const ordersList = JSON.parse(cachedOrders);
            setPaidOrders(ordersList);
            setFilteredOrders(ordersList);
            setLoading(false);
            return;
        }

        try {
            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            const q = query(ordersCollection, where('isPaid', '==', true));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const ordersList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPaidOrders(ordersList);
                setFilteredOrders(ordersList);
                sessionStorage.setItem("paidOrders", JSON.stringify(ordersList)); // Cache data
                setLoading(false);
            });

            return () => unsubscribe();
        } catch (error) {
            console.error("Error fetching paid orders:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPaidOrders();
    }, [shopId]);


    useEffect(() => {
        const filtered = paidOrders.filter(order => {
            const orderMatchesSearch = 
                order.id.includes(searchTerm) ||
                (order.Storeorder_id && order.Storeorder_id.includes(searchTerm));
            
            // 🟢 NEW DATE RANGE FILTERING LOGIC
            const orderMatchesDateRange = 
                (!fromDate || new Date(order.order_date) >= new Date(fromDate)) &&
                (!toDate || new Date(order.order_date) <= new Date(toDate));
            
            return orderMatchesSearch && orderMatchesDateRange;
        });
        setFilteredOrders(filtered);
    }, [searchTerm, paidOrders, fromDate, toDate]);

    // useEffect(() => {
    //     const filtered = paidOrders.filter(order =>
    //         order.id.includes(searchTerm) ||
    //         (order.Storeorder_id && order.Storeorder_id.includes(searchTerm))
    //     );
    //     setFilteredOrders(filtered);
    // }, [searchTerm, paidOrders]);

    // Function to fetch design details by SKU
    const fetchDesignDetailsBySKU = async (sku) => {
        if (!sku) {
            console.error("No SKU provided");
            return null;
        }
    
        try {
            console.log(`Fetching design data for SKU: ${sku}`);
            const designsCollection = collection(db, 'users', user.uid, 'designs');
            
            // Try to find designs with SKU in the top-level SKUs array (existing structure)
            const q = query(designsCollection, where('SKUs', 'array-contains', sku));
            const designSnapshot = await getDocs(q);
    
            if (!designSnapshot.empty) {
                const designData = designSnapshot.docs[0].data();
                console.log("Design data found:", designData);
    
                let pickupLocation = 'Printing Unit'; // Default location if no match
                if (designData.printMethod === 'DTG Epson' || designData.printMethod === 'DTF Epson') {
                    pickupLocation = 'Printing Unit 2';
                } else if (designData.printMethod === 'DTG Brother') {
                    pickupLocation = 'Printing Unit';
                }
                return {
                    ...designData,
                    frontImage: designData.images.front,
                    backImage: designData.images.back,
                    pickupLocation,
                };
            } else {
                // If not found in top-level SKUs, look in all designs for the SKU in colorVariants
                const allDesignsQuery = query(designsCollection);
                const allDesignsSnapshot = await getDocs(allDesignsQuery);
                
                let matchedDesign = null;
                let matchedVariant = null;
                
                for (const doc of allDesignsSnapshot.docs) {
                    const designData = doc.data();
                    
                    if (designData.colorVariants && Array.isArray(designData.colorVariants)) {
                        for (const variant of designData.colorVariants) {
                            if (variant.SKUs && Array.isArray(variant.SKUs) && variant.SKUs.includes(sku)) {
                                matchedDesign = designData;
                                matchedVariant = variant;
                                break;
                            }
                        }
                    }
                    
                    if (matchedDesign) break;
                }
                
                if (matchedDesign) {
                    console.log("Design data found in colorVariants:", matchedDesign);
                    
                    let pickupLocation = 'Printing Unit'; // Default location if no match
                    if (matchedDesign.printMethod === 'DTG Epson' || matchedDesign.printMethod === 'DTF Epson') {
                        pickupLocation = 'Printing Unit 2';
                    } else if (matchedDesign.printMethod === 'DTG Brother') {
                        pickupLocation = 'Printing Unit';
                    }
                    
                    return {
                        ...matchedDesign,
                        ...matchedVariant,
                        frontImage: matchedVariant.images.front,
                        backImage: matchedVariant.images.back,
                        pickupLocation,
                        // Include the variant data
                        variantData: matchedVariant
                    };
                } else {
                    console.warn(`No design found for SKU: ${sku}`);
                    return null;
                }
            }
        } catch (error) {
            console.error("Error fetching design details by SKU:", error);
            return null;
        }
    };

    const downloadCSV = async () => {
        // Create rows for CSV, with multiple rows for orders with multiple items

        const filteredOrdersForDownload = paidOrders.filter(order => 
            (!fromDate || new Date(order.order_date) >= new Date(fromDate)) &&
            (!toDate || new Date(order.order_date) <= new Date(toDate))
        );

        let csvRows = [];
        
        // Process each order
        for (const order of filteredOrdersForDownload) {
            // Check if order has items
            if (!order.order_items || order.order_items.length === 0) {
                // Add a single row with NA for item fields
                csvRows.push({
                    'Store Order ID': order.Storeorder_id || 'NA',
                    'Unitee Order ID': order.id || order.order_id || 'NA',
                    'SKU': 'NA',
                    'Product Name': 'NA',
                    'Variant': 'NA',
                    'Quantity': 'NA',
                    'Item Price': 'NA',
                    'SGST': 'NA',
                    'CGST': 'NA',
                    'IGST': 'NA',
                    'Item Total With Tax': 'NA',
                    'RTOInventory': 'NA',
                    'RTO Charges': 'NA',
                    'SubTotal': 'NA',
                    'Shipping (without tax)': (order.UniteeShipping ? (order.UniteeShipping / 1.18).toFixed(2) : 'NA'),
                    'Shipping Tax (18%)': (order.UniteeShipping ? (order.UniteeShipping - (order.UniteeShipping / 1.18)).toFixed(2) : 'NA'),
                    'Shipping Total': order.UniteeShipping || 'NA',
                    'Total Price': order.UniteeTotalPrice || 'NA',
                    'Customer Name': order.billing_customer_name || 'NA',
                    'Email': order.billing_email || 'NA',
                    'Phone': order.billing_phone || 'NA',
                    'Billing Address': order.billing_address || 'NA',
                    'City': order.billing_city || 'NA',
                    'State': order.billing_state || 'NA',
                    'Pincode': order.billing_pincode || 'NA',
                    'Country': order.billing_country || 'NA',
                    'Payment Method': order.payment_method || 'NA',
                    'Order Date': order.order_date || 'NA',
                    'Order Status': order.status || 'NA',
                });
            } else {
                // Calculate total item price with tax for the order
                let orderItemsTotal = 0;
                let totalRTOCharges = 0;
                
                // Process each item in the order
                for (const item of order.order_items) {
                    // Fetch design details to get the total price
                    const designData = await fetchDesignDetailsBySKU(item.sku);
                    // Check if item is RTO inventory
                    const isRTOInventory = item.RTOInventory === true;
                    const rtoCharges = isRTOInventory ? 50 : 0;
                    // Set item price to 0 if it's RTO inventory
                    const itemPrice = isRTOInventory ? 0 : (designData?.totalPrice || 0);
                    const itemQuantity = item.units || 1;
                    
                    // Calculate tax based on price and state
                    const isMaharashtra = (order.billing_state === 'Maharashtra');
                    let sgst = 0, cgst = 0, igst = 0;
                    let totalTax = 0;
                    
                    if (isMaharashtra) {
                        // Apply SGST and CGST
                        if (itemPrice < 1000) {
                            sgst = itemPrice * 0.025;
                            cgst = itemPrice * 0.025;
                        } else {
                            sgst = itemPrice * 0.06;
                            cgst = itemPrice * 0.06;
                        }
                        totalTax = sgst + cgst;
                    } else {
                        // Apply IGST
                        if (itemPrice < 1000) {
                            igst = itemPrice * 0.05;
                        } else {
                            igst = itemPrice * 0.12;
                        }
                        totalTax = igst;
                    }
                    
                    // Calculate item total with tax
                    const itemTotalWithTax = (itemPrice + totalTax) * itemQuantity;
                    totalRTOCharges += isRTOInventory ? rtoCharges : 0;
                    orderItemsTotal += itemTotalWithTax + (isRTOInventory ? rtoCharges : 0);
                    
                    csvRows.push({
                        'Store Order ID': order.Storeorder_id || 'NA',
                        'Unitee Order ID': order.id || order.order_id || 'NA',
                        'SKU': item.sku || 'NA',
                        'Product Name': designData?.designName || item.productName || 'NA',
                        'Variant': item.name || 'NA',
                        'Quantity': itemQuantity,
                        'Item Price': itemPrice.toFixed(2),
                        'SGST': sgst.toFixed(2),
                        'CGST': cgst.toFixed(2),
                        'IGST': igst.toFixed(2),
                        'Item Total With Tax': itemTotalWithTax.toFixed(2),
                        'RTOInventory': isRTOInventory ? 'Yes' : 'No',
                        'RTO Charges': rtoCharges.toFixed(2),
                        'SubTotal': orderItemsTotal.toFixed(2),
                        'Shipping (without tax)': (order.UniteeShipping ? (order.UniteeShipping / 1.18).toFixed(2) : 'NA'),
                        'Shipping Tax (18%)': (order.UniteeShipping ? (order.UniteeShipping - (order.UniteeShipping / 1.18)).toFixed(2) : 'NA'),
                        'Shipping Total': order.UniteeShipping || 'NA',
                        'Total Price': order.UniteeTotalPrice || orderItemsTotal.toFixed(2),
                        'Customer Name': order.billing_customer_name || 'NA',
                        'Email': order.billing_email || 'NA',
                        'Phone': order.billing_phone || 'NA',
                        'Billing Address': order.billing_address || 'NA',
                        'City': order.billing_city || 'NA',
                        'State': order.billing_state || 'NA',
                        'Pincode': order.billing_pincode || 'NA',
                        'Country': order.billing_country || 'NA',
                        'Payment Method': order.payment_method || 'NA',
                        'Order Date': order.order_date || 'NA',
                        'Order Status': order.status || 'NA',
                    });
                }
            }
        }

        // Generate CSV and trigger download
        const csv = Papa.unparse(csvRows);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const fromDateStr = fromDate ? new Date(fromDate).toLocaleDateString('en-GB').replace(/\//g, '-') : 'AllTime';
        const toDateStr = toDate ? new Date(toDate).toLocaleDateString('en-GB').replace(/\//g, '-') : 'AllTime';
        link.setAttribute('download', `PurchaseReport_${fromDateStr}_to_${toDateStr}.csv`);

        // link.setAttribute('download', 'PurchaseReport.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    if (loading) {
        return <div>Loading paid orders...</div>;
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <h1>Purchase Invoice</h1>

                    <div className="date-filter-container">
                        <div className="date-input-group">
                            <label htmlFor="fromDate">From Date:</label>
                            <input 
                                type="date" 
                                id="fromDate"
                                value={fromDate} 
                                onChange={(e) => setFromDate(e.target.value)} 
                                className="date-input"
                            />
                        </div>
                        <div className="date-input-group">
                            <label htmlFor="toDate">To Date:</label>
                            <input 
                                type="date" 
                                id="toDate"
                                value={toDate} 
                                onChange={(e) => setToDate(e.target.value)} 
                                className="date-input"
                            />
                        </div>

                    <button onClick={downloadCSV} className="download-report-btn">
                        Download Report
                    </button>
                    </div>
                    <input 
                        type="text" 
                        placeholder="Search by Order ID or Store Order ID" 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                        className="search-bar"
                    />
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Unitee Order ID</th>
                                <th>SKU</th>
                                <th>Quantity</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOrders.map((order) => (
                                <tr key={order.id}>
                                    <td>{order.Storeorder_id || 'NA'}</td>
                                    <td>{order.id || 'NA'}</td>
                                    <td>
                                        {order.order_items && order.order_items.map((item, index) => (
                                            <p key={index}>{item.sku}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items && order.order_items.map((item, index) => (
                                            <p key={index}>{item.units}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.invoiceUrl ? (
                                            <button 
                                                onClick={() => window.open(order.invoiceUrl, '_blank')}
                                                className="download-invoice-btn"
                                            >
                                                Download Invoice
                                            </button>
                                        ) : (
                                            <span>No Invoice</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PurchaseInvoice;