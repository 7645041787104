// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { db, auth } from '../firebase.js';
// import { doc, updateDoc, getDoc } from 'firebase/firestore';
// import Sidebar from '../components/Sidebar.jsx';
// import Header from '../components/Header.jsx';

// const SettingsPage = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const queryParams = new URLSearchParams(location.search);
//     const shop = queryParams.get('shop'); // Assuming shop name is passed in the URL as a query parameter

//     const [ifscCode, setIfscCode] = useState('');
//     const [branch, setBranch] = useState('');
//     const [name, setName] = useState('');
//     const [accountNumber, setAccountNumber] = useState('');
//     const [error, setError] = useState('');
//     const [success, setSuccess] = useState('');
//     const [isLoading, setIsLoading] = useState(true);


//         const user = auth.currentUser;
//         if (!user) {
//           throw new Error("User not logged in");
//         }

//     // Fetch existing shop details if available
//     useEffect(() => {
//         const fetchShopDetails = async () => {
//             try {
//                 const userRef = doc(db, 'users', user.uid);
//                 const userDoc = await getDoc(userRef);

//                 if (userDoc.exists()) {
//                     // You can prefill the form with existing account info if it exists
//                     const userData = userDoc.data();
//                     setName(userData.name || '');
//                     setIfscCode(userData.ifscCode || '');
//                     setBranch(userData.branch || '');
//                     setAccountNumber(userData.accountNumber || '');
//                 }

//                 setIsLoading(false);
//             } catch (err) {
//                 console.error('Error fetching shop details:', err);
//                 setIsLoading(false);
//             }
//         };

//         if (user) {
//             fetchShopDetails();
//         } else {
//             setError('user not found.');
//             setIsLoading(false);
//         }
//     }, [user]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError('');
//         setSuccess('');

//         if (!user) {
//             setError('User name is required.');
//             return;
//         }

//         try {
//             const userRef = doc(db, 'users', user.uid);

//             // Update the shop document with the new account information
//             await updateDoc(userRef, {
//                 name,
//                 ifscCode,
//                 branch,
//                 accountNumber,
//             });

//             setSuccess('Account info updated successfully!');
//         } catch (err) {
//             console.error('Error updating account info:', err);
//             setError('An error occurred. Please try again.');
//         }
//     };

//     if (isLoading) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <div className="products-page">
//             <Header />
//             <div className="products-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>Account Settings</h1>
//                     <form onSubmit={handleSubmit} className="settings-form">

//                     <label>
//                            Name:
//                             <input
//                                 type="text"
//                                 value={name}
//                                 onChange={(e) => setName(e.target.value)}
//                                 required
//                             />
//                         </label>
//                         <label>
//                             IFSC Code:
//                             <input
//                                 type="text"
//                                 value={ifscCode}
//                                 onChange={(e) => setIfscCode(e.target.value)}
//                                 required
//                             />
//                         </label>
//                         <label>
//                             Branch:
//                             <input
//                                 type="text"
//                                 value={branch}
//                                 onChange={(e) => setBranch(e.target.value)}
//                                 required
//                             />
//                         </label>
//                         <label>
//                             Account Number:
//                             <input
//                                 type="text"
//                                 value={accountNumber}
//                                 onChange={(e) => setAccountNumber(e.target.value)}
//                                 required
//                             />
//                         </label>

//                         <button type="submit" className="save-button">Save</button>

//                         {error && <p className="error">{error}</p>}
//                         {success && <p className="success">{success}</p>}
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SettingsPage;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from '../firebase.js';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import Sidebar from '../components/Sidebar.jsx';
import Header from '../components/Header.jsx';
import '../style/setting.css'

const SettingsPage = () => {
    const [activeTab, setActiveTab] = useState('account'); // Manage active tab
    const [ifscCode, setIfscCode] = useState('');
    const [branch, setBranch] = useState('');
    const [name, setName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [codCharges, setCodCharges] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const userRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setName(userData.name || '');
                    setIfscCode(userData.ifscCode || '');
                    setBranch(userData.branch || '');
                    setAccountNumber(userData.accountNumber || '');
                    setCodCharges(userData.codCharges || '');
                }
                setIsLoading(false);
            } catch (err) {
                console.error('Error fetching user details:', err);
                setIsLoading(false);
            }
        };

        fetchUserDetails();
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const userRef = doc(db, 'users', user.uid);
            await updateDoc(userRef, {
                name,
                ifscCode,
                branch,
                accountNumber,
                codCharges: Number(codCharges)
            });
            setSuccess('Settings updated successfully!');
        } catch (err) {
            console.error('Error updating settings:', err);
            setError('An error occurred. Please try again.');
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="products-page">
            <Header />
            <div className="products-container">
                <Sidebar />
                <div className="main-content">
                    <h1>Settings</h1>
                    <div className="tabs">
                        <button onClick={() => setActiveTab('account')} className={activeTab === 'account' ? 'active' : ''}>Bank Account</button>
                        <button onClick={() => setActiveTab('codCharges')} className={activeTab === 'codCharges' ? 'active' : ''}>COD Charges</button>
                    </div>
                    
                    {activeTab === 'account' && (
                        
                        <form onSubmit={handleSubmit} className="settings-form">
                            <p>Enter your bank details to receive COD payouts directly to your account.</p>
                            <label>
                                Name:
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                            </label>
                            <label>
                                IFSC Code:
                                <input type="text" value={ifscCode} onChange={(e) => setIfscCode(e.target.value)} required />
                            </label>
                            <label>
                                Branch:
                                <input type="text" value={branch} onChange={(e) => setBranch(e.target.value)} required />
                            </label>
                            <label>
                                Account Number:
                                <input type="text" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} required />
                            </label>
                            <button type="submit" className="save-button">Save</button>
                        </form>
                    )}
                    
                    {activeTab === 'codCharges' && (
                        <form onSubmit={handleSubmit} className="settings-form">
                            <p>Charge an extra amount on manual COD orders and have it collected at delivery.</p>
                            <label>
                                COD Charges:
                                <input
    type="number"
    value={codCharges}
    onChange={(e) => setCodCharges(Number(e.target.value))} // Convert input value to number
    required
/>
                            </label>
                            <button type="submit" className="save-button">Save</button>
                        </form>
                    )}

                    {error && <p className="error">{error}</p>}
                    {success && <p className="success">{success}</p>}
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;
