import React, { useState, useEffect } from "react";
import {doc, getDoc} from "firebase/firestore";
import { db, auth } from '../firebase';
import "../style/Sidebar.css";
import {
  FaTimes,
  FaBars,
  FaChartLine,
  FaShapes,
  FaTshirt,
  FaRocket,
  FaShoppingCart,
  FaCheckCircle,
  FaMoneyCheckAlt,
  FaFileContract,
  FaLock,
  FaUndo,
  FaWarehouse,
  FaShippingFast,
  FaEnvelope,
  FaPhoneAlt,
  FaCog,
  FaSearch,
  FaStore,
  FaShopify,
  FaTags,
  FaChevronUp, 
  FaChevronDown,
  FaFileInvoice
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFounderOpen, setIsFounderOpen] = useState(false);
  const [userShop, setUserShop] = useState(null);
  
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not logged in");
  }

  // const location = useLocation();

  // const queryParams = new URLSearchParams(location.search);
  // const shop = queryParams.get('shop');

  // State to track if it's desktop or mobile
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [isPolicyOpen, setIsPolicyOpen] = useState(false); // State for dropdown toggle

  // Adjust layout on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    const fetchUserShop = async () => {
      // Assuming db is the Firestore instance and user.uid is the current user's ID
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
  
      // if (!userDoc.exists()) {
      //   toast.error("User data not found.");
      //   return;
      // }
  
      const userData = userDoc.data();
      const userShop = userData.shop;
      setUserShop(userShop); // Update the userShop state
      
      // setLoading(false); // Mark as done loading
    };
  
    fetchUserShop();
  }, [user.uid]); // Fetch only when the user.uid changes

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleFounderInfo = () => {
    setIsFounderOpen(!isFounderOpen);
  };


  const togglePolicyDropdown = () => {
    setIsPolicyOpen(!isPolicyOpen); // Toggle the dropdown menu
  };

    const [selectedOption, setSelectedOption] = useState("");
  
    const handleChange = (event) => {
      setSelectedOption(event.target.value);
    };

  return (
    <>
      {/* Hamburger Menu (Mobile Only) */}
      {!isDesktop && (
        <div className="hamburger-menu" onClick={toggleSidebar}>
          <FaBars />
        </div>
      )}

      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen || isDesktop ? "open" : "closed"}`}>
        {/* Close Button (Mobile Only) */}
        <div className="sidebar-header">
          <div className="logo">
            <Link to={'/dashboard'}>
              <img src="logo.png" alt="Logo" />
            </Link>
          </div>
          {!isDesktop && (
            <div className="close-icon" onClick={toggleSidebar}>
              <FaTimes />
            </div>
          )}
        </div>

        {/* Menu Items */}
        <div className="menu">
          <div className="menu-item">
            <Link to={'/dashboard'}>
              <FaChartLine className="icon" />
              <span>Dashboard</span>
            </Link>
          </div>

          {/* <div className="menu-item">
            <Link to={'/SelectTshirt'}>
            <FaTshirt className="icon" />
              <span>Create Product</span>
            </Link>
          </div> */}

<div className="menu-item">
<Link to={user.uid === "hl6gcaUDIFXQkYUxppfAAHycLtO2" || user.uid === "Ge9xCYPWAqcixxj6wzfpHd1XWPF2" ? '/select-tshirt' : '/SelectTshirt'}>
            <FaTshirt className="icon" />
              <span>Create Product</span>
            </Link>
          </div>

          <div className="menu-item">
          <Link to={user.uid === "hl6gcaUDIFXQkYUxppfAAHycLtO2" || user.uid === "Ge9xCYPWAqcixxj6wzfpHd1XWPF2" ? '/products-new' : '/Products'}>
              <FaShapes className="icon" />
              <span>Products</span>
            </Link>
          </div>
          <div className="menu-item">
            <Link to={'/Book-a-sample'}>
              <FaSearch className="icon" />
              <span>Book a Sample</span>
            </Link>
          </div>

          <div className="menu-item">
            <Link to={'/branding'}>
              <FaTags className="icon" />
              <span>Branding</span>
            </Link>
          </div>


{/* <div className="menu-item" onClick={togglePolicyDropdown}>
            <div className="flex justify-between items-center w-full cursor-pointer">
              <div className="flex items-center">
              <FaTags className="icon" />
                <span>Branding  {isPolicyOpen ? <FaChevronUp style={{ marginLeft: '90px' }} /> : <FaChevronDown style={{ marginLeft: '90px' }}/>} </span>
              </div>
           
            </div>
          </div>

          {isPolicyOpen && (
            <div className="policy-dropdown">
              <div className="menu-item">
                <Link to={'/branding/necklabels'}>
                 
                  <span>Neck Labels</span>
                </Link>
              </div>

              <div className="menu-item">
                <Link to={'/branding/branding-elements'}>
                
                  <span>Branding Elements</span>
                </Link>
              </div>

            </div>

            
          )} */}
           
          <div className="menu-item">
            <Link to={'/create-order-list'}>
              <FaShoppingCart className="icon" />
              <span>Create Order</span>
            </Link>
          </div>




     {userShop && (
            <div className="menu-item">
             <Link to={user.uid === "hl6gcaUDIFXQkYUxppfAAHycLtO2" || user.uid === "Ge9xCYPWAqcixxj6wzfpHd1XWPF2" ? '/Orders-new' : '/OrdersPage'}>
                <FaShopify className="icon" />
                <span>Shopify Orders</span>
              </Link>
            </div>
          )}



          <div className="menu-item">
            <Link to={'/PaidOrders'}>
              <FaCheckCircle className="icon" />
              <span>Confirmed Orders</span>
            </Link>
          </div>


          <div className="menu-item" onClick={togglePolicyDropdown}>
            <div className="flex justify-between items-center w-full cursor-pointer">
              <div className="flex items-center">
              <FaFileInvoice className="icon" />
                <span>Invoices  {isPolicyOpen ? <FaChevronUp style={{ marginLeft: '90px' }} /> : <FaChevronDown style={{ marginLeft: '90px' }}/>} </span>
              </div>
           
            </div>
          </div>

          {isPolicyOpen && (
            <div className="policy-dropdown">
              <div className="menu-item">
                <Link to={'/Sales-invoice'}>
                 
                  <span>Sales Invoice</span>
                </Link>
              </div>

              <div className="menu-item">
                <Link to={'/Purchase-invoice'}>
                
                  <span>Purchase Invoice</span>
                </Link>
              </div>

            </div>

            
          )}

          {/* <div className="menu-item">
            <Link to={'/Sales-invoice'}>
              <FaFileInvoice className="icon" />
              <span>Sales Invoices</span>
            </Link>
          </div> */}

          {/* <div className="menu-item">
            <Link to={'/RTo-inventory'}>
              <FaWarehouse className="icon" />
              <span>Returns</span>
            </Link>
          </div> */}



<div className="menu-item" onClick={togglePolicyDropdown}>
            <div className="flex justify-between items-center w-full cursor-pointer">
              <div className="flex items-center">
              <FaShippingFast className="icon" />
                <span>Manage Orders  {isPolicyOpen ? <FaChevronUp style={{ marginLeft: '30px' }} /> : <FaChevronDown style={{ marginLeft: '30px' }}/>} </span>
              </div>
           
            </div>
          </div>

          {isPolicyOpen && (
            <div className="policy-dropdown">

<div className="menu-item">
                <Link to={'/intransit'}>
                 
                  <span>In Transit</span>
                </Link>
              </div>


              <div className="menu-item">
                <Link to={'/delivered'}>
                 
                  <span>Delivered</span>
                </Link>
              </div>



              <div className="menu-item">
                <Link to={'/rto-orders'}>
                 
                  <span>RTOs</span>
                </Link>
              </div>

              <div className="menu-item">
                <Link to={'/ndr-reports'}>
                
                  <span>NDR</span>
                </Link>
              </div>

              <div className="menu-item">
                <Link to={'/RTo-inventory'}>
                 
                  <span>RTO Inventory</span>
                </Link>
              </div>



              {/* <div className="menu-item">
                <Link to={'/returns'}>
               
                  <span>Returns</span>
                </Link>
              </div> */}

            </div>

            
          )}






          <div className="menu-item">
            <Link to={'/Transaction'}>
              <FaMoneyCheckAlt className="icon" />
              <span>Transactions</span>
            </Link>
          </div>

          <div className="menu-item">
            <Link to={'/store-connect'}>
              <FaStore className="icon" />
              <span>My Store</span>
            </Link>
          </div>


          <div className="menu-item">
            <Link to={'/Setting'}>
              <FaCog className="icon" />
              <span>Settings</span>
            </Link>
          </div>



          <div className="menu-item">
            <Link to={'/contact-us'}>
              <FaEnvelope className="icon" />
              <span>Contact Us</span>
            </Link>
          </div>



<div className="menu-item" onClick={toggleFounderInfo}>
  <div className="flex justify-between items-center w-full cursor-pointer">
    <div className="flex items-center">
      <FaPhoneAlt className="icon" />
      <span>Call a Founder  {isFounderOpen ? <FaChevronUp style={{ marginLeft: '30px' }} /> : <FaChevronDown style={{ marginLeft: '30px' }} />}</span>
    </div>
  </div>
</div>

{isFounderOpen && (
  <div className="policy-dropdown">
    <div className="menu-item" style={{ padding: '5px 2px' }}>
      <p style={{ marginBottom: '5px' }}><strong>Name:</strong> Rajprit Sahni</p>
    </div>
    <div className="menu-item" style={{ padding: '5px 2px' }}>
      <p><strong>Phone:</strong> +91 81040 38187</p>
    </div>
  </div>
)}


<div className="menu-item" onClick={togglePolicyDropdown}>
            <div className="flex justify-between items-center w-full cursor-pointer">
              <div className="flex items-center">
                <FaFileContract className="icon" />
                <span>Our Policy  {isPolicyOpen ? <FaChevronUp style={{ marginLeft: '65px' }}/> : <FaChevronDown style={{ marginLeft: '65px' }}/>} </span>
              </div>
              {/* {isPolicyOpen ? <FaChevronUp /> : <FaChevronDown />} */}
            </div>
          </div>

          {isPolicyOpen && (
            <div className="policy-dropdown">
              <div className="menu-item">
                <Link to={'/privacy-policy'}>
                  <FaLock className="icon" />
                  <span>Privacy Policy</span>
                </Link>
              </div>

              <div className="menu-item">
                <Link to={'/shipping-policy'}>
                  <FaShippingFast className="icon" />
                  <span>Shipping and Delivery</span>
                </Link>
              </div>

              <div className="menu-item">
                <Link to={'/terms-and-conditions'}>
                  <FaFileContract className="icon" />
                  <span>Terms and Conditions</span>
                </Link>
              </div>
            </div>
          )}

          
        </div>
      </div>
    </>
  );
};

export default Sidebar;

