import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const InTransit = () => {
    const [rtoOrders, setRtoOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop');

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }

    const fetchRtoOrders = async () => {
        try {
            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            const ordersSnapshot = await getDocs(ordersCollection);
            const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
            const filteredOrders = [];
            for (const order of ordersList) {
                const trackingInfo = await fetchTrackingData(order.id);
                if ((trackingInfo.current_status === "In Transit" || 
                    trackingInfo.current_status.includes("Transit") ||
                    trackingInfo.current_status.includes("transit") ||
                    trackingInfo.current_status.includes("TRANSIT") ||
                    trackingInfo.current_status.includes("Transit-En-Route"))
                    &&
                    !trackingInfo.current_status.includes("RTO") // Exclude RTO          
                ) {
                    filteredOrders.push({ ...order, tracking_status: trackingInfo.current_status });
                }
            }
            
            setRtoOrders(filteredOrders);
        } catch (error) {
            console.error("Error fetching RTO orders:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchTrackingData = async (orderId) => {
        try {
            const response = await axios.get(`https://ufb-1.onrender.com/api/track-order?order_id=${orderId}`);
            if (response.data && response.data.current_status) {
                return {
                    tracking_url: response.data.tracking_url,
                    current_status: response.data.current_status
                };
            }
        } catch (error) {
            console.error("Error fetching tracking data for order", orderId, error);
        }
        return { tracking_url: null, current_status: "Status not available" };
    };

    useEffect(() => {
        fetchRtoOrders();
    }, [shopId]);


        useEffect(() => {
            const filtered = rtoOrders.filter(order => 
                order.id.includes(searchTerm) || 
                (order.Storeorder_id && order.Storeorder_id.includes(searchTerm))
            );
            setFilteredOrders(filtered);
        }, [searchTerm, rtoOrders]);

    if (loading) {
        return <div>Loading InTransit orders</div>;
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <h1>In Transit</h1>
                    <input 
                        type="text" 
                        placeholder="Search by Order ID or Store Order ID" 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                        className="search-bar"
                    />
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Unitee Order ID</th>
                                <th>SKU</th>
                                <th>Quantity</th>
                                <th>Current Status</th>
                                {/* <th>Actions</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {filteredOrders
                                .map(order => (
                                <tr key={order.id}>
                                    <td>{order.Storeorder_id || 'NA'}</td>
                                    <td>{order.id || 'NA'}</td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.sku}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.units}</p>
                                        ))}
                                    </td>
                                    <td>{order.tracking_status}</td>
                                    {/* <td>
                                        <button className="action-button" onClick={() => console.log('Take Action', order.id)}>Take Action</button>
                                        <button className="details-button" onClick={() => console.log('Show Details', order.id)}>Show Details</button>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default InTransit;