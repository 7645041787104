import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { Storage } from '../firebase';
import { collection, getDocs, updateDoc, doc as firestoreDoc , query, where, getDoc, addDoc, Timestamp, deleteDoc, orderBy } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import '../style/OrdersPage.css';
import axios from 'axios';
import ImageModal from '../components/ImageModal'; // Import the ImageModal component
import { jsPDF } from 'jspdf';
import autoTable from "jspdf-autotable";
import { ref, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Wallet from '../components/Wallet';
import { useModal } from '../components/ModalContext';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { numberToWords } from "amount-to-words";
import { ToWords } from "to-words";

    const OrdersPage = () => {
        const [orders, setOrders] = useState([]);
        const [loading, setLoading] = useState(true);
        const [selectedImage, setSelectedImage] = useState(null); // State to track selected image for modal
        const [confirmedOrders, setConfirmedOrders] = useState([]); // To track confirmed orders
        const [selectedOrder, setSelectedOrder] = useState(null);   // Order to confirm
        const [confirmBalance, setConfirmBalance] = useState(null); // Balance after deduction
        const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal state
        const { isModalOpen, setIsModalOpen } = useModal();
        const [showCancelModal, setShowCancelModal] = useState(false); // State for cancel confirmation modal
        const [orderToCancel, setOrderToCancel] = useState(null); // Store the order ID to cancel
        const [currentBalance, setCurrentBalance] = useState(null); 
        const [rtoInventory, setRtoInventory] = useState(null);
            const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
            const [editedOrderData, setEditedOrderData] = useState({});

             const [isLoading, setIsLoading] = useState(false); 
               const [progress, setProgress] = useState(0);

        const [rtoMatch, setRtoMatch] = useState(false);
        const location = useLocation();
        const queryParams = new URLSearchParams(location.search);
        const shopId = queryParams.get('shop');
        const [currentPage, setCurrentPage] = useState(1);
        const ordersPerPage = 7; // Orders per page
        
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }



        useEffect(() => {
            const fetchBalance = async () => {
                const balance = await getCurrentBalance();
                setCurrentBalance(balance);
    // Stop loading after fetching balance
            };

            fetchBalance();
        }, []);

        useEffect(() => {
            if (!loading && orders.length > 0) {
                // For each order, check if its items' SKU matches with the RTO inventory
                orders.forEach(order => {
                    checkSKUInRTOInventory(order.order_items); // Check SKU matching for each order's items
                });
            }
        }, [loading, orders]);

        const fetchDesignDetailsBySKU = async (sku) => {
            if (!sku) {
                console.error("No SKU provided");
                return null;
            }

            try {
                console.log(`Fetching design data for SKU: ${sku}`);
                const designsCollection = collection(db, 'users', user.uid, 'designs');
                const q = query(designsCollection, where('SKUs', 'array-contains', sku));
                const designSnapshot = await getDocs(q);

                

                if (!designSnapshot.empty) {
                    const designData = designSnapshot.docs[0].data();
                    console.log("Design data found:", designData);

                    let pickupLocation = 'Printing Unit'; // Default location if no match
                    if (designData.printMethod === 'DTG Epson' || designData.printMethod === 'DTF Epson') {
                        pickupLocation = 'Printing Unit 2';
                    } else if (designData.printMethod === 'DTG Brother') {
                        pickupLocation = 'Printing Unit';
                    }
                    return {
                        ...designData,
                        frontImage: designData.images.front,
                        backImage: designData.images.back,
                        pickupLocation,
                    };
                } else {
                    console.warn(`No design found for SKU: ${sku}`);
                    return null;
                }
            } catch (error) {
                console.error("Error fetching design details by SKU:", error);
                return null;
            }
        };



        const openOrderModal = (order) => {
            setSelectedOrder(order);
            setEditedOrderData({ ...order });
            setIsOrderModalOpen(true);
        };
    
        // Close the modal
        const closeModal = () => {
            setIsOrderModalOpen(false);
            setSelectedOrder(null);
            setEditedOrderData({});
        };
    
        // Handle input change for order editing
        const handleOrderDataChange = (field, value) => {
            setEditedOrderData(prevData => ({
                ...prevData,
                [field]: value
            }));
        };


         const saveOrderChanges = async () => {
                try {
                    const orderRef = firestoreDoc(db, 'users', user.uid, 'orders', selectedOrder.id);
                    await updateDoc(orderRef, editedOrderData);
                    
                    // Update the order in the local state as well
                    setOrders(prevOrders => 
                        prevOrders.map(order => 
                            order.id === selectedOrder.id ? editedOrderData : order
                        )
                    );
                    
                    alert("Order updated successfully!");
                    closeModal();
                } catch (error) {
                    console.error("Error updating order:", error);
                    alert("Failed to update order. Please try again.");
                }
            };




        const fetchRTOInventory = async () => {
            try {
                if (!user) return;
        
                const inventoryRef = collection(db, 'RTO_inventory');
                const q = query(inventoryRef, where('brandId', '==', user?.uid)); // Query for user's RTO inventory
        
                const inventorySnapshot = await getDocs(q);
                const userInventory = {};
        
                inventorySnapshot.forEach(docSnap => {
                    const skuData = docSnap.data();
                    const skuPrefix = docSnap.id;
                    userInventory[skuPrefix] = { sku: skuPrefix, counts: {} };
        
                    // Add all sizes from the RTO inventory
                    Object.keys(skuData).forEach(size => {
                        if (size !== 'brandId') {
                            userInventory[skuPrefix].counts[size] = skuData[size];
                        }
                    });
                });
        
                return userInventory; // Return the RTO inventory
            } catch (error) {
                console.error('Error fetching RTO inventory:', error);
                return {};
            }
        };
        

    const checkSKUInRTOInventory = (orderItems) => {
        if (!rtoInventory) {
            console.log("RTO inventory is not yet available");
            return false; // Return false if inventory is not available yet
        }
    
        // Iterate over order items and check for SKU match
        return orderItems.some(item => {
            const orderSKU = item.sku.split('-'); // Split the SKU into base and size
            const orderBaseSKU = orderSKU[0] + '-' + orderSKU[1]; // Base SKU without size
            const orderSize = orderSKU[2]; // SKU size
    
            // Check if the base SKU exists in the RTO inventory
            if (rtoInventory[orderBaseSKU]) {
                const inventorySizes = Object.keys(rtoInventory[orderBaseSKU].counts); // Get available sizes from RTO inventory
                if (inventorySizes.includes(orderSize)) {
                    const inventoryCount = rtoInventory[orderBaseSKU].counts[orderSize]; // Get the count for the size
                    if (inventoryCount > 0) {
                        console.log(`SKU ${orderBaseSKU}-${orderSize} matches with RTO inventory.`);
                        return true; // Return true if SKU matches and the count is greater than 0
                    } else {
                        console.log(`SKU ${orderBaseSKU}-${orderSize} exists but the count is 0 in RTO inventory.`);
                    }
                } else {
                    console.log(`SKU ${orderBaseSKU}-${orderSize} does not match with RTO inventory.`);
                }
            } else {
                console.log(`Base SKU ${orderBaseSKU} does not exist in RTO inventory.`);
            }
    
            return false; // Default return false if no match or count is 0
        });
    };
        
    
    useEffect(() => {
        const fetchInventory = async () => {
            const inventory = await fetchRTOInventory();
            if (inventory) {
                setRtoInventory(inventory);
            }
        };
    
        fetchInventory();
    }, []);
    

// Fetch RTO inventory function remains unchanged

    

        const fetchOrders = async () => {

            try {
                const ordersCollection = collection(db, 'users', user.uid, 'orders');
                const q = query(ordersCollection, orderBy('createdAt', 'desc'));
                const ordersSnapshot = await getDocs(q);

                console.log("Orders snapshot:", ordersSnapshot);
        
                if (ordersSnapshot.empty) {
                    console.warn("No orders found in Firestore.");
                    setOrders([]);
                    return;
                }
        
                const ordersList = await Promise.all(
                    ordersSnapshot.docs.map(async (firestoreDoc) => {
                        const orderData = firestoreDoc.data();
                        const isPaid = orderData.isPaid || false;
        
                        // Skip paid orders
                        if (isPaid) return null;
        
                        console.log(`Fetched order data for order ID: ${firestoreDoc.id}`, orderData);
        
                        if (!orderData.order_items || orderData.order_items.length === 0) {
                            console.warn(`Order ID ${firestoreDoc.id} has no line items.`);
                            return { id: firestoreDoc.id, ...orderData, order_items: [], isPaid };
                        }
        
                        const lineItemsWithDesigns = await Promise.all(
                            orderData.order_items.map(async (item) => {
                                const designData = await fetchDesignDetailsBySKU(item.sku);
                                return { ...item, design: designData };
                            })
                        );
        
                        return { id: firestoreDoc.id, ...orderData, order_items: lineItemsWithDesigns };
                    })
                );
        
                // Filter out null entries (i.e., paid orders)
                setOrders(ordersList.filter(order => order !== null));
                console.log("Orders List:", ordersList);
            } catch (error) {
                console.error("Error fetching orders:", error);
            } finally {
                setLoading(false);
            }
        };


        const handleConfirmOrder = async (orderId) => {

            try {
                const order = orders.find(order => order.id === orderId);
                if (!order) {
                    toast.error('Order not found');
                }
        
                // Check if the order is already confirmed
                if (confirmedOrders.includes(orderId)) {
                    toast.info('This order is already paid.');
                }

                if (order.isPaid) {
                    toast.info('This order is already paid.'); // Notify if already paid
                }
                


                // Fetch user's wallet balance
                const walletRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
                const walletSnap = await getDoc(walletRef);
        
                if (!walletSnap.exists()) {
                    toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
                }
        
                const walletData = walletSnap.data();
                const currentBalance = walletData.balance;
        
                // Check if the balance is sufficient
                if (currentBalance < totalCost) {
                    toast.error('Insufficient balance. Recharge the amount.', { onClose: () => setIsModalOpen(true) });
                }

                
        
                // Set selected order and show the confirmation modal
                setSelectedOrder(order);
                setConfirmBalance(currentBalance - calculateCostWithGST(order)); // Calculate balance after deduction
                setShowConfirmModal(true);
        
            } catch (error) {
                console.error('Error confirming order:', error);
                toast.error('Error confirming order:', error);
            }
        };

        const handleCancelOrderClick = (orderId) => {
            setOrderToCancel(orderId); // Set the order to cancel
            setShowCancelModal(true); // Show the cancel confirmation modal
        };
        

        const confirmCancelOrder = async () => {
            try {
                const orderRef = firestoreDoc(db, 'users', user.uid, 'orders', orderToCancel);
                await deleteDoc(orderRef); // Use deleteDoc to remove the order
                fetchOrders(); // Refresh the order list
                toast.success('Order canceled successfully.');
            } catch (error) {
                console.error("Error canceling order:", error);
                toast.error('Error canceling order.');
            } finally {
                setShowCancelModal(false); // Close the cancel modal
                setOrderToCancel(null); // Clear the order to cancel
            }
        };



    //towords used in invoice

    const toWords = new ToWords({
        localeCode: "en-IN",
        converterOptions: {
          currency: true,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
          currencyOptions: {
            name: "Rupee",
            plural: "Rupees",
            symbol: "₹",
            fractionalUnit: {
              name: "Paisa",
              plural: "Paise",
              symbol: "",
            },
          },
        },
      });


//Shipping Price calculation

    // const calculateShippingCharge = (order) => {
    //     // Safeguard for when 'items' might not be passed or is not an array
    //     if (!order || !Array.isArray(order.order_items) || order.order_items.length === 0) {
    //         throw new Error("Order items are missing or invalid.");
    //     }
    
    //     const specialStates = [
    //         "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
    //         "Himachal Pradesh", "Sikkim", "Lakshadweep",
    //         "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
    //         "Mizoram", "Nagaland", "Tripura"
    //     ];
    
    //     const billingState = order.billing_state;
    //     const paymentMethod = order.payment_method;
    //     const sellingPrice = order.sub_total;
    //     const items = order.order_items; // Assuming items is an array with the order items
    //     const itemWeight = 0.25; // Each item weighs 0.25 kg
    
    //     let shippingCharge = 0;
    
    //     // If the billing state is a special state, set specific shipping charges based on payment method
    //     if (billingState && specialStates.includes(billingState)) {
    //         if (paymentMethod === "Prepaid") {
    //             shippingCharge = 85;
    //         } else if (paymentMethod === "COD") {
    //             shippingCharge = 85;
    
    //             // Add 2% of selling price to shipping if selling price > 2000
    //             if (sellingPrice > 2000) {
    //                 shippingCharge += sellingPrice * 0.025;
    //             }
    //             else {
    //                 // If the sub_total is less than or equal to 2000, add 50 to the shipping charge
    //                 shippingCharge += 50;
    //             }
    //         }
    //     } else {
    //         // Default shipping charges based on payment method for non-special states
    //         if (paymentMethod === "Prepaid") {
    //             shippingCharge = 65;
    //         } else if (paymentMethod === "COD") {
    //             shippingCharge = 65;
    
    //             // Add 2.5% of selling price to shipping if selling price > 2000
    //             if (sellingPrice > 2000) {
    //                 shippingCharge += sellingPrice *  0.025;
    //             }
    //             else {
    //                 // If the sub_total is less than or equal to 2000, add 50 to the shipping charge
    //                 shippingCharge += 50;
    //             }
    //         }
    //     }
    
    //     // Calculate the number of "weight groups" (each group is 0.5 kg, which is 2 items)
    //     const totalItems = items.length;
    //     const weightGroups = Math.ceil(totalItems * itemWeight / 0.5); // Number of 0.5 kg groups
    
    //     // Increase shipping charge based on the number of weight groups
    //     // Every group (0.5kg) increases the shipping price
    //     shippingCharge *= weightGroups;
    
    //     return shippingCharge;
    // };
    
    const calculateShippingCharge = (order) => {
        // Safeguard for when 'items' might not be passed or is not an array
        if (!order || !Array.isArray(order.order_items) || order.order_items.length === 0) {
            throw new Error("Order items are missing or invalid.");
        }


        const userId = auth.currentUser?.uid;

        if (userId === "hl6gcaUDIFXQkYUxppfAAHycLtO2") {
        


            const specialStates = [
                "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
                "Himachal Pradesh", "Sikkim", "Lakshadweep",
                "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
                "Mizoram", "Nagaland", "Tripura"
            ];
        
            const billingState = order.billing_state;
            const paymentMethod = order.payment_method;
            const sellingPrice = order.sub_total;
            const items = order.order_items; // Assuming items is an array with the order items
            const itemWeight = 0.25; // Each item weighs 0.25 kg
            
            let shippingCharge = 0;
        
            // Calculate the total number of items by summing up their quantities
            let totalItemQuantity = 0;
            items.forEach(item => {
                totalItemQuantity += item.units; // Assuming each item has a 'quantity' field
            });
        
            // Calculate the total weight of the order
            const totalWeight = totalItemQuantity * itemWeight;
        
            // Calculate the number of "weight groups" (each group is 0.5 kg, which is 2 items)
            const weightGroups = Math.ceil(totalWeight / 0.5); // Number of 0.5 kg groups
        
            // If the billing state is a special state, set specific shipping charges based on payment method
            if (billingState && specialStates.includes(billingState)) {
                if (paymentMethod === "Prepaid") {
                    shippingCharge = 85;
                } else if (paymentMethod === "COD") {
                    shippingCharge = 85;
        
                }
            } else {
                // Default shipping charges based on payment method for non-special states
                if (paymentMethod === "Prepaid") {
                    shippingCharge = 55;
                } else if (paymentMethod === "COD") {
                    shippingCharge = 65;
        
                }
            }
        
            // Increase shipping charge based on the number of weight groups
            shippingCharge *= weightGroups;
    
            if (paymentMethod === "COD") {
                if (sellingPrice > 2000) {
                    shippingCharge += sellingPrice * 0.025; // Add 2.5% of selling price
                } else {
                    // If the selling price is less than or equal to 2000, add 50
                    shippingCharge += 50;
                }
            }
        
            return shippingCharge;
        }
    
        const specialStates = [
            "North East", "J & K", "Ladakh", "Andaman & Nicobar", 
            "Himachal Pradesh", "Sikkim", "Lakshadweep",
            "Arunachal Pradesh", "Assam", "Manipur", "Meghalaya", 
            "Mizoram", "Nagaland", "Tripura"
        ];
    
        const billingState = order.billing_state;
        const paymentMethod = order.payment_method;
        const sellingPrice = order.sub_total;
        const items = order.order_items; // Assuming items is an array with the order items
        const itemWeight = 0.25; // Each item weighs 0.25 kg
        
        let shippingCharge = 0;
    
        // Calculate the total number of items by summing up their quantities
        let totalItemQuantity = 0;
        items.forEach(item => {
            totalItemQuantity += item.units; // Assuming each item has a 'quantity' field
        });
    
        // Calculate the total weight of the order
        const totalWeight = totalItemQuantity * itemWeight;
    
        // Calculate the number of "weight groups" (each group is 0.5 kg, which is 2 items)
        const weightGroups = Math.ceil(totalWeight / 0.5); // Number of 0.5 kg groups
    
        // If the billing state is a special state, set specific shipping charges based on payment method
        if (billingState && specialStates.includes(billingState)) {
            if (paymentMethod === "Prepaid") {
                shippingCharge = 85;
            } else if (paymentMethod === "COD") {
                shippingCharge = 85;
    
            }
        } else {
            // Default shipping charges based on payment method for non-special states
            if (paymentMethod === "Prepaid") {
                shippingCharge = 65;
            } else if (paymentMethod === "COD") {
                shippingCharge = 65;
    
            }
        }
    
        // Increase shipping charge based on the number of weight groups
        shippingCharge *= weightGroups;

        if (paymentMethod === "COD") {
            if (sellingPrice > 2000) {
                shippingCharge += sellingPrice * 0.025; // Add 2.5% of selling price
            } else {
                // If the selling price is less than or equal to 2000, add 50
                shippingCharge += 50;
            }
        }
    
        return shippingCharge;
    };
    
    
const calculateShippingWithGST = (order) => {
    // Calculate the shipping charge first using the existing logic
    let shippingCharge = calculateShippingCharge(order);
    
    // Apply 18% GST on the shipping charge
    const gst = shippingCharge * 0.18; // 18% of shipping charge
    
    // Add the GST to the original shipping charge
    const totalShippingWithGST = shippingCharge + gst;
    
    return totalShippingWithGST;
};

    // Function to calculate total cost
    const calculateTotalCost = (order) => {
        // Ensure that order_items exists and is an array
        if (!Array.isArray(order.order_items)) {
            console.error('order_items is not an array or is undefined');
            return 0; // Return 0 if there's an error
        }
    
        const shippingCharge = calculateShippingCharge(order);
    
        // Use reduce to calculate the total item cost, ensuring the item data is valid
        const totalItemCost = order.order_items.reduce((sum, item) => {
            // Check if item.design exists and has a totalPrice
            if (item.design && typeof item.design.totalPrice === 'number') {
                const itemTotal = (item.design.totalPrice || 0) * item.units;
                return sum + itemTotal;
            } else {
                // If no valid item design or totalPrice, log the issue and return the sum unchanged
                console.warn('Invalid item or missing totalPrice in item:', item);
                return sum;
            }
        }, 0);
    
        // return totalItemCost + shippingCharge;

        return totalItemCost;
        
    };
    

    const CostWithShipping = (order) => {
        // Ensure that order_items exists and is an array
        if (!Array.isArray(order.order_items)) {
            console.error('order_items is not an array or is undefined');
            return 0; // Return 0 if there's an error
        }
    
        const shippingCharge = calculateShippingCharge(order);
    
        // Use reduce to calculate the total item cost, ensuring the item data is valid
        const totalItemCost = order.order_items.reduce((sum, item) => {
            // Check if item.design exists and has a totalPrice
            if (item.design && typeof item.design.totalPrice === 'number') {
                const itemTotal = (item.design.totalPrice || 0) * item.units;
                return sum + itemTotal;
            } else {
                // If no valid item design or totalPrice, log the issue and return the sum unchanged
                console.warn('Invalid item or missing totalPrice in item:', item);
                return sum;
            }
        }, 0);
    
        return totalItemCost + shippingCharge;
        
    };

    

    const calculateCostWithGST = (order) => {
        // Initialize the total cost for the entire order
        let totalCost = 0;
        
        // Iterate over each item in the order
        order.order_items.forEach(item => {
            // Check if the item matches the RTO inventory
            const isRTOInventoryMatched = checkSKUInRTOInventory([item]);
            
            if (isRTOInventoryMatched) {
                // If SKU matches RTO inventory, apply RTO charges (₹50)
                const rtoCharges = 50; // Fixed RTO charge for matched items
                totalCost += rtoCharges;
            } else {
                // Otherwise, calculate the total cost for the item with GST
                const itemTotalCost = calculateTotalCostForItem(item); // Use your method to calculate item cost
                const gstAmount = itemTotalCost * 0.05; // Apply 5% GST
                totalCost += itemTotalCost + gstAmount;
            }
        });
        
        return totalCost;
    };
    
    // Helper function to calculate the total cost for each item
    const calculateTotalCostForItem = (item) => {
        // Calculate the total cost of the item based on its price and units
        if (item.design && typeof item.design.totalPrice === 'number') {
            return item.design.totalPrice * item.units; // Calculate item cost based on units and total price
        }
        return 0; // If no price exists, return 0
    };
    
    
// const calculateTotalCostForItem = async (item) => {
//     console.log('Starting calculation for item:', item);
//     // Calculate the total cost of the item based on its price and units
//     if (item.design && typeof item.design.totalPrice === 'number') {
//         let itemTotal = item.design.totalPrice * item.units;
//         console.log(`Base calculation: ${item.design.totalPrice} × ${item.units} = ${itemTotal}`);
        
//         // Check neck labels and add 5 rupees if true
//         try {
//             console.log('Checking for neck labels, user ID:', user.uid);
//             const userRef = firestoreDoc(db, 'users', user.uid); // Assuming `shopId` is available
//             const userSnap = await getDoc(userRef);
            
//             console.log('User document exists:', userSnap.exists());
//             if (userSnap.exists()) {
//                 const userData = userSnap.data();
//                 console.log('User data neckLabels value:', userData.neckLabels);
                
//                 if (userData.neckLabels === true) {
//                     const neckLabelCost = 5 * item.units;
//                     itemTotal += neckLabelCost;
//                     console.log(`Adding neck label cost: ${neckLabelCost}, new total: ${itemTotal}`);
//                 }
//             }
//         } catch (error) {
//             console.warn('Error checking neckLabels:', error);
//         }
        
//         console.log('Final calculated total:', itemTotal);
//         return itemTotal;
//     }
//     console.log('No valid price found, returning 0');
//     return 0; // If no price exists, return 0
// };

    const getCurrentBalance = async () => {
        try {
            // Fetch the wallet document for the current user
            const walletRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
            const walletSnap = await getDoc(walletRef);
            
            // Check if the wallet document exists
            if (!walletSnap.exists()) {
                toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
                return null;  // Return null if the wallet document doesn't exist
            }
    
            // Extract the current balance from the wallet document
            const walletData = walletSnap.data();
            const currentBalance = walletData.balance;
            
            // Return the current balance
            return currentBalance;
    
        } catch (error) {
            console.error('Error fetching current balance:', error);
            toast.error('Error fetching balance');
            return null;  // Return null if there's an error fetching the data
        }
    };
    



        const handleImageClick = (imageSrc) => {
            setSelectedImage(imageSrc); // Set the clicked image as the selected one
        };

        const handleCloseModal = () => {
            setSelectedImage(null); // Close the modal by clearing the selected image
        };

        useEffect(() => {
            fetchOrders();
        }, );

        if (loading) {
            return <div>Loading orders...</div>;
        }


        const totalPages = Math.ceil(orders.length / ordersPerPage);
        const startIndex = (currentPage - 1) * ordersPerPage;
        const paginatedOrders = orders.slice(startIndex, startIndex + ordersPerPage);



        const updateInventory = async (orders) => {
            try {
                // Loop through each ordered item
                for (const item of orders) {
                    const orderSKU = item.sku.split('-');
                    
                    // Check if SKU format is correct
                    if (orderSKU.length !== 3) {
                        console.error(`Invalid SKU format for ${item.sku}. Skipping this item.`);
                        continue;
                    }
                    
                    const orderBaseSKU = orderSKU[0] + '-' + orderSKU[1]; // Base SKU without size
                    const orderSize = orderSKU[2]; // Size (e.g., M, L)
        
                    // Fetch the RTO inventory for this base SKU from Firestore
                    const inventoryRef = firestoreDoc(db, 'RTO_inventory', orderBaseSKU);
                    const inventorySnap = await getDoc(inventoryRef);
        
                    if (inventorySnap.exists()) {
                        const inventoryData = inventorySnap.data(); // Get the inventory data from Firestore
                        console.log("Fetched inventory data:", inventoryData); // Debug log
        
                        if (inventoryData) {
                            // Check if the size exists directly in the inventory data (e.g., M, L)
                            if (inventoryData[orderSize] !== undefined) {
                                const availableQuantity = inventoryData[orderSize];
        
                                // Check if enough quantity is available for this order item
                                if (availableQuantity >= item.units) {
                                    console.log(`SKU ${orderBaseSKU}-${orderSize} is available. Reducing quantity...`);
        
                                    // Reduce the quantity in Firestore
                                    const newQuantity = availableQuantity - item.units;
                                    console.log(`Reducing inventory for ${orderBaseSKU}-${orderSize}: ${newQuantity} left.`); // Debug log
        
                                    await updateDoc(inventoryRef, {
                                        [orderSize]: newQuantity
                                    });
        
                                    console.log(`Updated inventory for ${orderBaseSKU}-${orderSize}: ${newQuantity} left.`);
                                } else {
                                    console.log(`Not enough quantity for SKU ${orderBaseSKU}-${orderSize}. Requested: ${item.units}, Available: ${availableQuantity}`);
                                    return false; // Return false if there isn't enough stock
                                }
                            } else {
                                console.log(`Size ${orderSize} does not exist for SKU ${orderBaseSKU}.`);
                                return false; // Return false if the size doesn't exist
                            }
                        } else {
                            console.log(`No inventory data found for base SKU ${orderBaseSKU}.`);
                            return false; // Return false if base SKU doesn't exist in the inventory
                        }
                    } else {
                        console.log(`No inventory document found for base SKU ${orderBaseSKU}.`);
                        return false; // Return false if no document is found for the base SKU
                    }
                }
        
                // If everything passes, return true (indicating the inventory was updated successfully)
                console.log("Inventory updated successfully for all items.");
                return true;
        
            } catch (error) {
                console.error("Error updating inventory:", error.message || error);
                return false; // Return false in case of an error
            }
        };
        
        
        
        const confirmOrder = async () => {
            try {
                // Initialize references
                const walletRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
        
                // Fetch the user's wallet details
                const walletSnap = await getDoc(walletRef);
                if (!walletSnap.exists()) {
                    toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
                    return;
                }
        
                const walletData = walletSnap.data();
                const currentBalance = walletData.balance;
        
                // Check if the balance is sufficient
                if (currentBalance < (calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder))) {
                    toast.error('Insufficient balance. Recharge the amount.', { onClose: () => setIsModalOpen(true) });
                    return;
                }

                const skuMatchesInventory = await checkSKUInRTOInventory(selectedOrder.order_items);

                const result = await updateInventory(selectedOrder.order_items);

                if (result) {
                    console.log("Inventory successfully updated!");
                } else {
                    console.log("Failed to update inventory.");
                }
        



                // Fetch shop data (brand details) from Firestore
                
                const userRef = firestoreDoc(db, 'users', user.uid); // Assuming `shopId` is available
                const userSnap = await getDoc(userRef);
                if (!userSnap.exists()) {
                    toast.error('Shop information not found.');
                    return;
                }
                const userData = userSnap.data();
                const brandingRef = collection(db, 'users', auth.currentUser.uid, 'branding');
                const brandingSnap = await getDocs(brandingRef);
                const brandingItems = brandingSnap.docs.map(doc => ({ brandingId: doc.id, ...doc.data() }));
                
                // Loop through selected order items and calculate total ordered quantity
                let totalOrderedQuantity = selectedOrder.order_items.reduce((sum, item) => sum + item.units, 0);
                
                // Loop through branding items to reduce stock
                for (let item of selectedOrder.order_items) {
                    let remainingQuantity = totalOrderedQuantity; // Track remaining units to reduce
                    
                    for (let brandingItem of brandingItems) {
                        const currentQuantity = brandingItem.quantity;
                
                        // Check if the current branding item has enough stock
                        if (currentQuantity >= remainingQuantity) {
                            // Reduce the stock for the full ordered quantity
                            const itemRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'branding', brandingItem.brandingId);
                            await updateDoc(itemRef, { quantity: currentQuantity - remainingQuantity });
                            break;  // Stop once the ordered quantity has been fulfilled
                        } else if (currentQuantity > 0) {
                            // If not enough stock, reduce what we can
                            const itemRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'branding', brandingItem.brandingId);
                            await updateDoc(itemRef, { quantity: 0 });
                            remainingQuantity -= currentQuantity;  // Deduct the available stock
                        }
                    }
                }
                





                const ordersRef = firestoreDoc(db, 'users', user.uid, 'orders', selectedOrder.id); // Assuming `shopId` is available
                const ordersSnap = await getDoc(ordersRef);
                if (!ordersSnap.exists()) {
                    toast.error('Shop information not found.');
                    return;
                }
                const ordersData = ordersSnap.data();


                const CustomerInfo = {
                    Name: ordersData.billing_customer_name || 'Default Brand Name',
                    address: ordersData.billing_address || 'N/A',
                    city: ordersData.billing_city || 'N/A',         
                    state: ordersData.billing_state || 'N/A',     
                    pincode:ordersData.billing_pincode || 'N/A',
                    country: ordersData.billing_country || 'N/A',
                };
        

                
                const brandInfo = {
                    brandName: userData.brandName || 'Default Brand Name',
                    address: userData.address || 'N/A',
                    state: userData.state || 'N/A',         
                    pinCode: userData.pincode || 'N/A',     
                    gstNumber: userData.gstNumber || 'N/A',
                    contact: userData.phoneNumber || 'N/A',
                };
        
                const mainInfo = {
                    name: 'UNITEE STUDIOS PRIVATE LIMITED',
                };
        
                const invoiceDate = new Date().toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: '2-digit',
                });
        

                const storageRef = ref(Storage, 'invoices/');

                // Get the list of items (invoices) in the 'invoices/' folder
                const listResult = await listAll(storageRef);
                let nextInvoiceNumber = 1;  // Default to 1 if no invoices exist
                
                if (listResult.items.length > 0) {
                    // Extract the numeric part of each file name (e.g., 'invoice_001.pdf')
                    const invoiceNumbers = listResult.items
                        .map(item => item.name.match(/invoice_(\d+)\.pdf$/))  // Adjusting regex to capture invoice number
                        .filter(match => match)  // Filter out invalid names
                        .map(match => parseInt(match[1], 10));  // Parse the numeric part of the invoice number
                
                    // Find the highest invoice number and increment it
                    if (invoiceNumbers.length > 0) {
                        nextInvoiceNumber = Math.max(...invoiceNumbers) + 1;  // Increment the highest invoice number by 1
                    }
                }
                
                // Format the invoice number (e.g., 'UC/2024-25/001')
                const invoiceNumber = `UC/2025-26/${String(nextInvoiceNumber).padStart(3, '0')}`;
                
                // Rest of the code for creating the invoice and uploading it...
                

                const doc = new jsPDF();

                // Header Section
                doc.setFontSize(20);
        
                doc.text('TAX INVOICE', 100, 15, null, null, 'center');
                
                // Company Information
                doc.setFontSize(10);
                doc.setFont("Helvetica", "normal");
                doc.text("UNITEE STUDIOS PRIVATE LIMITED", 10, 20);
                doc.text("Maharashtra, India", 10, 25);
                doc.text("GSTIN 27AADCU3575G1Z5", 10, 30);
                doc.text("rajprit@uniteeculture.com", 10, 35);
                doc.text("1ST FLOOR, PLOT 304/E/, 3, WAKHARIA BUILDING Vithalbhai Patel Road", 10, 40);
                doc.text("MUMBAI", 10, 45);
                
                // Invoice Details
                doc.text("Invoice Details", 150, 25, null, null, 'center');
                doc.setFontSize(10);
                doc.text(`Invoice Date: ${invoiceDate}`, 140, 30);
                doc.text(`Invoice Number: ${invoiceNumber}`, 140, 35);

                
                // Billing and Shipping Information
                doc.text("Bill To", 10, 60);
                doc.text(`Name: ${brandInfo.brandName}`, 10, 65);
                doc.text(`Address: ${brandInfo.address}`, 10, 70);
                doc.text(`State: ${brandInfo.state}`, 10, 75);
                doc.text(`Pin Code: ${brandInfo.pinCode}`, 10, 80);
                doc.text(`GST Number: ${brandInfo.gstNumber}`, 10, 85);
                
                doc.text("Ship To", 140, 60);
                doc.text(`Name: ${CustomerInfo.Name}`, 140, 65);
                doc.text(`Address: ${CustomerInfo.address}`, 140, 70);
                doc.text(`City: ${CustomerInfo.city}`, 140, 75);
                doc.text(`State: ${CustomerInfo.state}`, 140, 80);           
                doc.text(`Pin Code: ${CustomerInfo.pincode}`, 140, 85);


    const rtoItems = [];
const normalItems = [];

// Process each item to check if it's RTO
await Promise.all(selectedOrder.order_items.map(async (item) => {
    // Check if this specific item is in RTO inventory
    const isItemRTO = await checkSKUInRTOInventory([item]);
    if (isItemRTO) {
        rtoItems.push(item);
    } else {
        normalItems.push(item);
    }
}));

// Initialize variables for total calculations
let totalTaxableValue = 0;
let totalCGST = 0;
let totalSGST = 0;
let totalIGST = 0;
let totalRTOHandlingCharge = rtoItems.length * 50; // 50 per RTO item

// Start position for first table
const itemsTableY = 90;
let currentY = itemsTableY;

// Generate regular items table if any exist
if (normalItems.length > 0) {
    autoTable(doc, {
        startY: currentY,
        head: [
            ['#', 'Item & Description', 'HSN/SAC', 'Qty', 'Rate', 
             brandInfo.state === "Maharashtra" ? 'CGST %' : 'IGST %', 
             brandInfo.state === "Maharashtra" ? 'CGST Amt' : 'IGST Amt', 
             brandInfo.state === "Maharashtra" ? 'SGST %' : '', 
             brandInfo.state === "Maharashtra" ? 'SGST Amt' : '', 
             brandInfo.state !== "Maharashtra" ? 'IGST %' : '', 
             brandInfo.state !== "Maharashtra" ? 'IGST Amt' : '', 
             'Amount'
            ]
        ],
        body: normalItems.map((item, index) => {
            // Calculate the individual item price
            const itemPrice = (item.design?.totalPrice || 0);
            let cgstAmt = 0;
            let sgstAmt = 0;
            let igstAmt = 0;
            let taxAmount = 0;
    
            if (brandInfo.state === "Maharashtra") {
                // CGST and SGST for Maharashtra
                cgstAmt = (itemPrice * 0.025).toFixed(2);  // 2.5% CGST
                sgstAmt = (itemPrice * 0.025).toFixed(2);  // 2.5% SGST
                taxAmount = (parseFloat(cgstAmt) + parseFloat(sgstAmt)).toFixed(2);
            } else {
                // IGST for other states
                igstAmt = (itemPrice * 0.05).toFixed(2);  // 5% IGST
                taxAmount = igstAmt;
            }
    
            // Calculate total amount per item (including tax)
            const amount = (itemPrice + parseFloat(cgstAmt || igstAmt) + parseFloat(sgstAmt || 0)).toFixed(2);
            
            // Add to totals for final summary
            const taxableValue = itemPrice * item.units;
            totalTaxableValue += taxableValue;
            
            if (brandInfo.state === "Maharashtra") {
                totalCGST += taxableValue * 0.025;
                totalSGST += taxableValue * 0.025;
            } else {
                totalIGST += taxableValue * 0.05;
            }
    
            return [
                index + 1,
                item.design?.designName || 'Unknown',
                '610910',  // Example HSN code
                item.units,
                itemPrice.toFixed(2),
                brandInfo.state === "Maharashtra" ? '2.5%' : '', 
                brandInfo.state === "Maharashtra" ? cgstAmt : '',
                brandInfo.state === "Maharashtra" ? '2.5%' : '', 
                brandInfo.state === "Maharashtra" ? sgstAmt : '',
                brandInfo.state !== "Maharashtra" ? '5%' : '', 
                brandInfo.state !== "Maharashtra" ? igstAmt : '',
                amount,
            ];
        }),
        theme: 'grid',
        styles: { fontSize: 10 },
    });
    
    // Update current Y position after this table
    currentY = doc.autoTable.previous.finalY + 10;
}

// Generate RTO items table if any exist
if (rtoItems.length > 0) {
    autoTable(doc, {
        startY: currentY,
        head: [['#', 'RTO Item & Description', 'Handling & Packaging Charge']],
        body: rtoItems.map((item, index) => {
            return [
                index + 1,
                item.design?.designName || 'Unknown RTO Item',
                '50.00'  // Fixed handling charge per RTO item
            ];
        }),
        theme: 'grid',
        styles: { fontSize: 10 },
    });
    
    // Update current Y position after this table
    currentY = doc.autoTable.previous.finalY + 10;
}

// Generate tax summary for normal items if any exist
if (normalItems.length > 0) {
    autoTable(doc, {
        startY: currentY,
        head: [['HSN/SAC', 'Taxable Value', 
            brandInfo.state === "Maharashtra" ? 'CGST %' : 'IGST %', 
            brandInfo.state === "Maharashtra" ? 'CGST Amt' : 'IGST Amt', 
            brandInfo.state === "Maharashtra" ? 'SGST %' : '', 
            brandInfo.state === "Maharashtra" ? 'SGST Amt' : '', 
            brandInfo.state !== "Maharashtra" ? 'IGST %' : '', 
            brandInfo.state !== "Maharashtra" ? 'IGST Amt' : '',
            'Total Tax Amount']],
        body: [
            ['610910', totalTaxableValue.toFixed(2),
                brandInfo.state === "Maharashtra" ? '2.5%' : '', 
                brandInfo.state === "Maharashtra" ? totalCGST.toFixed(2) : '',
                brandInfo.state === "Maharashtra" ? '2.5%' : '', 
                brandInfo.state === "Maharashtra" ? totalSGST.toFixed(2) : '',
                brandInfo.state !== "Maharashtra" ? '5%' : '', 
                brandInfo.state !== "Maharashtra" ? totalIGST.toFixed(2) : '',
                (totalCGST + totalSGST + totalIGST).toFixed(2)]
        ],
        theme: 'grid',
        styles: { fontSize: 10 },
    });
    
    // Update current Y position after this table
    currentY = doc.autoTable.previous.finalY + 10;
}

// Calculate shipping charges - applies to the entire order
const shippingBase = calculateShippingCharge(selectedOrder);
const shippingTax = shippingBase * 0.18;
const totalShippingCharge = calculateShippingWithGST(selectedOrder);

// Generate shipping charges table
autoTable(doc, {
    startY: currentY,
    head: [['SAC', 'Shipping Charge', 'Rate (18%)', 'Total Shipping Charge']],
    body: [
        ['9965', shippingBase.toFixed(2), shippingTax.toFixed(2), totalShippingCharge.toFixed(2)],
    ],
    theme: 'grid',
    styles: { fontSize: 10 },
});

// Final Amount Summary - Include both normal items, RTO handling charges, and shipping
const totalAmount = totalTaxableValue + totalCGST + totalSGST + totalIGST + totalRTOHandlingCharge + totalShippingCharge;
const finalAmountY = doc.autoTable.previous.finalY + 10;
doc.text(`Amount Chargeable (in words): ${toWords.convert(totalAmount)}`, 10, finalAmountY);
doc.text(`Total: ${totalAmount.toFixed(2)}`, 150, finalAmountY + 5);
doc.text(`Balance Due: ${totalAmount.toFixed(2)}`, 150, finalAmountY + 10);

// Footer Notes
doc.text("Thanks for your business.", 10, finalAmountY + 25);
doc.text("Authorized Signature", 150, finalAmountY + 25);

            const invoiceFileName = `invoice_${nextInvoiceNumber}.pdf`;  // Updated to use nextInvoiceNumber
            const pdfBlob = doc.output('blob');  // Generate the PDF blob as usual


            // Upload the invoice to Firebase Storage
            const invoiceRef = ref(Storage, `invoices/${invoiceFileName}`);
            const uploadTask = uploadBytesResumable(invoiceRef, pdfBlob);
    
            // Observe the upload task
            uploadTask.on(
                'state_changed',
                snapshot => {
                    // You can handle upload progress here (e.g., using a progress bar)
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(`Upload is ${progress}% done`);
                },
                error => {
                    console.error('Error uploading invoice:', error);
                    toast.error('Error uploading invoice');
                },
                async () => {
                    try {
                        // After upload is complete, get the download URL
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        console.log('Invoice uploaded, download URL:', downloadURL);
    
                        // Fetch order data for Shiprocket
                        const orderRef = firestoreDoc(db, 'users', user.uid, 'orders', selectedOrder.id);
                        const orderSnapshot = await getDoc(orderRef);
                        const orderData = { id: selectedOrder.id, ...orderSnapshot.data() };

                        const orderWithPickupLocation = await Promise.all(
                            orderData.order_items.map(async (item) => {
                                const designData = await fetchDesignDetailsBySKU(item.sku);
                                return { ...item, design: designData };
                            })
                        );

                     
                
                        // Create the updated order object with pickup location and other necessary details
                        const updatedOrder = {
                            ...orderData,
                            user_uid: auth.currentUser.uid,
                            order_items: orderWithPickupLocation,
                            pickup_location: orderWithPickupLocation[0]?.design?.pickupLocation || 'Default Location', // Set the pickup location for the order
                        };
    


// changes according to brands 

// Create a function to generate abbreviation from brand name
function getAbbreviation(name) {
    // Split the name into words
    const words = name.split(' ');
    // Take the first letter of each word and convert to uppercase
    return words.map(word => word.charAt(0).toUpperCase()).join('');
}

// Get state abbreviation
function getStateAbbreviation(state) {
    const stateAbbreviations = {
        'Andhra Pradesh': 'AP',
        'Arunachal Pradesh': 'AR',
        'Assam': 'AS',
        'Bihar': 'BR',
        'Chhattisgarh': 'CG',
        'Goa': 'GA',
        'Gujarat': 'GJ',
        'Haryana': 'HR',
        'Himachal Pradesh': 'HP',
        'Jharkhand': 'JH',
        'Karnataka': 'KA',
        'Kerala': 'KL',
        'Madhya Pradesh': 'MP',
        'Maharashtra': 'MH',
        'Manipur': 'MN',
        'Meghalaya': 'ML',
        'Mizoram': 'MZ',
        'Nagaland': 'NL',
        'Odisha': 'OD',
        'Punjab': 'PB',
        'Rajasthan': 'RJ',
        'Sikkim': 'SK',
        'Tamil Nadu': 'TN',
        'Telangana': 'TS',
        'Tripura': 'TR',
        'Uttar Pradesh': 'UP',
        'Uttarakhand': 'UK',
        'West Bengal': 'WB',
        'Andaman and Nicobar Islands': 'AN',
        'Chandigarh': 'CH',
        'Dadra and Nagar Haveli and Daman and Diu': 'DN',
        'Lakshadweep': 'LD',
        'Delhi': 'DL',
        'Puducherry': 'PY',
        'Jammu and Kashmir': 'JK',
        'Ladakh': 'LA'
        // Add more states as needed
    };
    
    return stateAbbreviations[state] || state.substring(0, 2).toUpperCase();
}

// Generate brand abbreviation
const brandAbbreviation = getAbbreviation(brandInfo.brandName);
const stateAbbreviation = getStateAbbreviation(brandInfo.state);
const financialYear = '25-26'; // You may want to calculate this dynamically

// Create the brand folder path
const brandFolderPath = `Sales_invoice/${brandAbbreviation}2026/`;
const salesInvoiceStorageRef = ref(Storage, brandFolderPath);

// Get the list of items (sales invoices) in this brand's folder
const salesListResult = await listAll(salesInvoiceStorageRef);
let nextSalesInvoiceNumber = 1;  // Default to 1 if no invoices exist

if (salesListResult.items.length > 0) {
    // Extract the numeric part of each file name (e.g., 'MOS_MH_24-25_001.pdf')
    const salesInvoiceNumbers = salesListResult.items
        .map(item => item.name.match(new RegExp(`${brandAbbreviation}_${stateAbbreviation}_${financialYear}_(\\d+)\\.pdf$`)))
        .filter(match => match)
        .map(match => parseInt(match[1], 10));

    // Find the highest invoice number and increment it
    if (salesInvoiceNumbers.length > 0) {
        nextSalesInvoiceNumber = Math.max(...salesInvoiceNumbers) + 1;
    }
}

// Format the sales invoice number with the new pattern
const salesInvoiceNumber = `${brandAbbreviation}/${stateAbbreviation}/${financialYear}/${String(nextSalesInvoiceNumber).padStart(3, '0')}`;
const salesInvoiceFileName = `${brandAbbreviation}_${stateAbbreviation}_${financialYear}_${String(nextSalesInvoiceNumber).padStart(3, '0')}.pdf`;

// Create a new PDF for the sales invoice - use 112mm width for thermal printer
const salesDoc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: [100, 150]  // 112mm thermal receipt width with variable height
});

// format: [100, 150]

// Set margins for thermal printer
const margin = 3; // 4mm margins
const width = 94; // 112mm - 2*4mm margins
const startX = margin;
const startY = margin;
let currentY = startY;
const lineHeight = 3.5; // line height for thermal receipt

// Header Section for Sales Invoice - centered
salesDoc.setFontSize(10);
salesDoc.setFont("Helvetica", "bold");
salesDoc.text('TAX Invoice', width/2 + startX, currentY += lineHeight, null, null, 'center');
currentY += lineHeight;

// Invoice Details - side by side due to wider format
salesDoc.setFontSize(7);
salesDoc.setFont("Helvetica", "normal");
salesDoc.text(`Date: ${invoiceDate}`, startX, currentY += lineHeight);
salesDoc.text(`Invoice #: ${salesInvoiceNumber}`, startX + width/2, currentY);
currentY += lineHeight/2;

// Add a divider line
salesDoc.line(startX, currentY, startX + width, currentY);
currentY += lineHeight/2;

// Bill From and Ship From side by side
const halfWidth = width / 2 - 2; // Leave a small gap between columns


function addWrappedText(doc, text, x, y, maxWidth, lineHeight) {
    const words = text.split(' ');
    let line = '';
    let currentY = y;
    
    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + ' ';
      const testWidth = doc.getStringUnitWidth(testLine) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      
      if (testWidth > maxWidth && i > 0) {
        doc.text(line, x, currentY);
        line = words[i] + ' ';
        currentY += lineHeight;
      } else {
        line = testLine;
      }
    }
    
    if (line.trim() !== '') {
      doc.text(line.trim(), x, currentY);
    }
    
    return currentY;
  }


// Bill From (Brand Info)
salesDoc.setFont("Helvetica", "bold");
salesDoc.text("Bill From:", startX, currentY += lineHeight);
salesDoc.setFont("Helvetica", "normal");
salesDoc.text(`${brandInfo.brandName}`, startX, currentY += lineHeight);
// salesDoc.text(`${brandInfo.address}`, startX, currentY += lineHeight);

const addressMaxWidth = halfWidth - 10;
currentY = addWrappedText(salesDoc, `${brandInfo.address}`, startX, currentY += lineHeight, addressMaxWidth, lineHeight);

salesDoc.text(`${brandInfo.state}, ${brandInfo.pinCode}`, startX, currentY += lineHeight);
salesDoc.text(`GST: ${brandInfo.gstNumber}`, startX, currentY += lineHeight);


const billFromMaxY = currentY;

// Reset Y position for Ship From
const shipFromStartY = currentY - (lineHeight * 4);

// Ship From
salesDoc.setFont("Helvetica", "bold");
salesDoc.text("Ship From:", startX + halfWidth + 5, shipFromStartY);
salesDoc.setFont("Helvetica", "normal");
salesDoc.text("Jalandhar", startX + halfWidth + 5, shipFromStartY + lineHeight);
salesDoc.text("Punjab", startX + halfWidth + 5, shipFromStartY + lineHeight * 2);
salesDoc.text("144004", startX + halfWidth + 5, shipFromStartY + lineHeight * 3);

// Add a divider line
currentY += lineHeight/2;
salesDoc.line(startX, currentY, startX + width, currentY);
currentY += lineHeight/2;

const billToShipToStartY = currentY;

// Bill To section
salesDoc.setFont("Helvetica", "bold");
salesDoc.text("Bill To:", startX, billToShipToStartY + lineHeight);
salesDoc.setFont("Helvetica", "normal");
salesDoc.text(`${CustomerInfo.Name}`, startX, billToShipToStartY + lineHeight * 2);

let billToY = billToShipToStartY + lineHeight * 3;
billToY = addWrappedText(salesDoc, `${CustomerInfo.address}`, startX, billToY, addressMaxWidth, lineHeight);
billToY = addWrappedText(salesDoc, `${CustomerInfo.city}, ${CustomerInfo.state}`, startX, billToY += lineHeight, addressMaxWidth, lineHeight);
salesDoc.text(`${CustomerInfo.pincode}`, startX, billToY += lineHeight);

// Ship To section - starts at the same Y level as Bill To
salesDoc.setFont("Helvetica", "bold");
salesDoc.text("Ship To:", startX + halfWidth + 5, billToShipToStartY + lineHeight);
salesDoc.setFont("Helvetica", "normal");
salesDoc.text(`${CustomerInfo.Name}`, startX + halfWidth + 5, billToShipToStartY + lineHeight * 2);

let shipToY = billToShipToStartY + lineHeight * 3;
shipToY = addWrappedText(salesDoc, `${CustomerInfo.address}`, startX + halfWidth + 5, shipToY, addressMaxWidth, lineHeight);
shipToY = addWrappedText(salesDoc, `${CustomerInfo.city}, ${CustomerInfo.state}`, startX + halfWidth + 5, shipToY += lineHeight, addressMaxWidth, lineHeight);
salesDoc.text(`${CustomerInfo.pincode}`, startX + halfWidth + 5, shipToY += lineHeight);

// Find the maximum Y position between the two sections
currentY = Math.max(billToY, shipToY) + lineHeight;

// currentY = Math.max(billToMaxY, shipToY) + lineHeight;
// Add a divider line
currentY += lineHeight/2;
salesDoc.line(startX, currentY, startX + width, currentY);
currentY += lineHeight/2;

// Fetch shipping charge and total discount from the order
const shippingCharge = Number(selectedOrder.shipping_charge || 0);
const totalDiscount = Number(selectedOrder.total_discount || 0);

// Calculate the total units and initial subtotal across all items to determine distribution ratios
const totalUnits = selectedOrder.order_items.reduce((sum, item) => sum + item.units, 0);
const initialTotalValue = selectedOrder.order_items.reduce((sum, item) => {
    const basePrice = item.selling_price || 0;
    return sum + (basePrice * item.units);
}, 0);

// Items header
salesDoc.setFont("Helvetica", "bold");
salesDoc.text("ITEMS", startX, currentY += lineHeight);
currentY += lineHeight/2;

// Add a divider line
salesDoc.line(startX, currentY, startX + width, currentY);
currentY += lineHeight;

// Column headers for items - more columns possible with 112mm width
salesDoc.setFontSize(6);
salesDoc.text("Item", startX, currentY += lineHeight);
salesDoc.text("HSN", startX + 35, currentY, null, null, 'center');
salesDoc.text("Qty", startX + 50, currentY, null, null, 'center');
salesDoc.text("Price", startX + 65, currentY, null, null, 'center');
salesDoc.text("Tax", startX + 80, currentY, null, null, 'center');
salesDoc.text("Amount", startX + width, currentY, null, null, 'right');
currentY += lineHeight/2;

// Add a divider line
salesDoc.line(startX, currentY, startX + width, currentY);
currentY += lineHeight/2;

// Process items
let totalSubtotal = 0;
let totalTaxableValue = 0;
let totalCGST = 0;
let totalSGST = 0;
let totalIGST = 0;
let totalTaxAmount = 0;

// Use item listing with more details for the wider 112mm format
salesDoc.setFont("Helvetica", "normal");
selectedOrder.order_items.forEach((item, index) => {
    // Fetch the original selling_price for the item
    const originalSellingPrice = item.selling_price || 0;
    const itemUnits = item.units;
    
    // Calculate the proportion of this item in the total order value
    const itemValueRatio = (originalSellingPrice * itemUnits) / initialTotalValue;
    
    // Distribute discount and shipping charge proportionally
    const itemDiscount = totalDiscount * itemValueRatio;
    const itemShippingCharge = shippingCharge * itemValueRatio;
    
    // Calculate the adjusted selling price (with discount and shipping)
    const adjustedUnitPrice = originalSellingPrice - (itemDiscount / itemUnits) + (itemShippingCharge / itemUnits);
    
    // Calculate the subtotal based on adjusted price
    const subtotal = adjustedUnitPrice * itemUnits;
    totalSubtotal += subtotal;
    
    // Determine tax rate based on item's individual adjusted selling price
    const taxRate = (adjustedUnitPrice >= 1000) ? 0.12 : 0.05; // 12% if selling price >= 1000, 5% otherwise
    
    // Calculate taxable value (selling price excluding tax)
    const taxMultiplier = 1 + taxRate;
    const taxableValue = subtotal / taxMultiplier;
    totalTaxableValue += taxableValue;
    
    let cgstAmt = 0;
    let sgstAmt = 0;
    let igstAmt = 0;
    let taxAmount = 0;
    let taxDisplay = "";
    
    // Apply tax based on the rule
    if (CustomerInfo.state === "Maharashtra") {
        cgstAmt = (taxableValue * (taxRate/2));
        sgstAmt = (taxableValue * (taxRate/2));
        taxAmount = cgstAmt + sgstAmt;
        totalCGST += cgstAmt;
        totalSGST += sgstAmt;
        taxDisplay = `${(taxRate*100).toFixed(0)}% (C+S)`;
    } else {
        igstAmt = (taxableValue * taxRate);
        taxAmount = igstAmt;
        totalIGST += igstAmt;
        taxDisplay = `${(taxRate*100).toFixed(0)}% (IGST)`;
    }
    totalTaxAmount += taxAmount;
    
    // Truncate item name if too long - can be longer with 112mm width
    const designName = (item.design?.designName || 'Unknown').substring(0, 18);
    
    // Print item details
    salesDoc.text(designName, startX, currentY += lineHeight);
    salesDoc.text('610910', startX + 35, currentY, null, null, 'center');
    salesDoc.text(itemUnits.toString(), startX + 50, currentY, null, null, 'center');
    salesDoc.text(adjustedUnitPrice.toFixed(2), startX + 65, currentY, null, null, 'center');
    salesDoc.text(taxDisplay, startX + 80, currentY, null, null, 'center');
    salesDoc.text(subtotal.toFixed(2), startX + width, currentY, null, null, 'right');
    currentY += lineHeight;
});

// Add a divider line
currentY += lineHeight/2;
salesDoc.line(startX, currentY, startX + width, currentY);
currentY += lineHeight/2;

// Summary section - more detailed for 112mm receipt
salesDoc.setFont("Helvetica", "bold");
salesDoc.text("SUMMARY", startX, currentY += lineHeight);
currentY += lineHeight/2;

// Add a divider line
salesDoc.line(startX, currentY, startX + width, currentY);
currentY += lineHeight;

salesDoc.setFontSize(7); 
// Display subtotal and tax details in a more structured format
salesDoc.setFont("Helvetica", "normal");
salesDoc.text("Subtotal (Excl. Tax):", startX, currentY += lineHeight);
salesDoc.text(totalTaxableValue.toFixed(2), startX + width, currentY, null, null, 'right');

// Display appropriate tax summary based on state
// if (CustomerInfo.state === "Maharashtra") {
//     salesDoc.text(`CGST @ ${(totalTaxableValue >= 1000 ? 6 : 2.5)}%:`, startX, currentY += lineHeight);
//     salesDoc.text(totalCGST.toFixed(2), startX + width, currentY, null, null, 'right');
    
//     salesDoc.text(`SGST @ ${(totalTaxableValue >= 1000 ? 6 : 2.5)}%:`, startX, currentY += lineHeight);
//     salesDoc.text(totalSGST.toFixed(2), startX + width, currentY, null, null, 'right');
// } else {
//     salesDoc.text(`IGST @ ${(totalTaxableValue >= 1000 ? 12 : 5)}%:`, startX, currentY += lineHeight);
//     salesDoc.text(totalIGST.toFixed(2), startX + width, currentY, null, null, 'right');
// }


// Display appropriate tax summary based on state
if (CustomerInfo.state === "Maharashtra") {
    // Check if all items have the same tax rate
    const uniqueTaxRates = new Set(selectedOrder.order_items.map(item => {
        const adjustedUnitPrice = item.selling_price || 0;
        return adjustedUnitPrice >= 1000 ? 6 : 2.5;
    }));
    
    if (uniqueTaxRates.size === 1) {
        // All items have the same tax rate
        const rate = [...uniqueTaxRates][0];
        salesDoc.text(`CGST @ ${rate}%:`, startX, currentY += lineHeight);
        salesDoc.text(totalCGST.toFixed(2), startX + width, currentY, null, null, 'right');
        
        salesDoc.text(`SGST @ ${rate}%:`, startX, currentY += lineHeight);
        salesDoc.text(totalSGST.toFixed(2), startX + width, currentY, null, null, 'right');
    } else {
        // Multiple tax rates
        salesDoc.text("CGST:", startX, currentY += lineHeight);
        salesDoc.text(totalCGST.toFixed(2), startX + width, currentY, null, null, 'right');
        
        salesDoc.text("SGST:", startX, currentY += lineHeight);
        salesDoc.text(totalSGST.toFixed(2), startX + width, currentY, null, null, 'right');
    }
} else {
    // Check if all items have the same tax rate
    const uniqueTaxRates = new Set(selectedOrder.order_items.map(item => {
        const adjustedUnitPrice = item.selling_price || 0;
        return adjustedUnitPrice >= 1000 ? 12 : 5;
    }));
    
    if (uniqueTaxRates.size === 1) {
        // All items have the same tax rate
        const rate = [...uniqueTaxRates][0];
        salesDoc.text(`IGST @ ${rate}%:`, startX, currentY += lineHeight);
        salesDoc.text(totalIGST.toFixed(2), startX + width, currentY, null, null, 'right');
    } else {
        // Multiple tax rates
        salesDoc.text("IGST:", startX, currentY += lineHeight);
        salesDoc.text(totalIGST.toFixed(2), startX + width, currentY, null, null, 'right');
    }
}

// Total tax amount
salesDoc.text("Total Tax:", startX, currentY += lineHeight);
salesDoc.text(totalTaxAmount.toFixed(2), startX + width, currentY, null, null, 'right');



// Add a divider line
currentY += lineHeight/2;
salesDoc.line(startX, currentY, startX + width, currentY);
currentY += lineHeight/2;

// Total amount - make it stand out
salesDoc.setFontSize(9);
salesDoc.setFont("Helvetica", "bold");
salesDoc.text("TOTAL:", startX, currentY += lineHeight);
salesDoc.text(totalSubtotal.toFixed(2), startX + width, currentY, null, null, 'right');

// Amount in words
currentY += lineHeight;
salesDoc.setFontSize(6);
salesDoc.setFont("Helvetica", "normal");
const amountInWords = toWords.convert(totalSubtotal);
// Split amount in words into multiple lines if needed
const wordsArray = salesDoc.splitTextToSize(`Amount in words: ${amountInWords}`, width);
wordsArray.forEach(line => {
    salesDoc.text(line, startX, currentY += lineHeight - 1);
});

// Add a divider line
currentY += lineHeight/2;
salesDoc.line(startX, currentY, startX + width, currentY);
currentY += lineHeight/2;

// Footer notes
salesDoc.setFontSize(6);
salesDoc.text("Note: All prices include applicable taxes.", startX, currentY += lineHeight);
salesDoc.text("Thanks for your business.", startX, currentY += lineHeight);

// Signature line
currentY += lineHeight;
salesDoc.line(startX + width/4, currentY, startX + width*3/4, currentY);
currentY += lineHeight;
salesDoc.text("Authorized Signature", startX + width/2, currentY, null, null, 'center');

// Generate the sales invoice PDF file
const salesPdfBlob = salesDoc.output('blob');

// Upload the sales invoice to Firebase Storage in the brand's folder
const salesInvoiceRef = ref(Storage, `${brandFolderPath}${salesInvoiceFileName}`);
const salesUploadTask = uploadBytesResumable(salesInvoiceRef, salesPdfBlob);


let salesDownloadURL = null;

// Observe the upload task
// salesUploadTask.on(
//     'state_changed',
//     snapshot => {
//         // Handle upload progress here if needed
//         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         console.log(`Sales Invoice Upload is ${progress}% done`);
//     },
//     error => {
//         console.error('Error uploading sales invoice:', error);
//         toast.error('Error uploading sales invoice');
//     },
//     async () => {
//         try {
//             // After upload is complete, get the download URL
//             const salesDownloadURL = await getDownloadURL(salesUploadTask.snapshot.ref);
//             console.log('Sales Invoice uploaded, download URL:', salesDownloadURL);
            
//             // Update the order with the sales invoice URL and number
//             const orderRef = firestoreDoc(db, 'users', user.uid, 'orders', selectedOrder.id);
//             await updateDoc(orderRef, {
//                 SalesinvoiceUrl: salesDownloadURL, // Store the sales invoice URL

//             });
            
//             console.log("Order updated with sales invoice URL and number.");
//             toast.success("Sales invoice generated successfully!");
//         } catch (err) {
//             console.error('Error handling completed sales invoice upload:', err);
//             toast.error('Error handling completed sales invoice upload.');
//         }
//     }
// );


salesUploadTask.on(
    'state_changed',
    snapshot => {
        // Handle upload progress here if needed
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Sales Invoice Upload is ${progress}% done`);
    },
    error => {
        console.error('Error uploading sales invoice:', error);
        toast.error('Error uploading sales invoice');
    },
    async () => {
        try {
            // After upload is complete, get the download URL
            salesDownloadURL = await getDownloadURL(salesUploadTask.snapshot.ref);
            console.log('Sales Invoice uploaded, download URL:', salesDownloadURL);
            
            // Don't update the order document here - we'll do it after order confirmation
            console.log("Sales invoice generated successfully!");
            
            // Continue with the order confirmation process
            // The existing code will handle updating the document later
        } catch (err) {
            console.error('Error handling completed sales invoice upload:', err);
            toast.error('Error handling completed sales invoice upload.');
        }
    }
);

                 // Create order on Shiprocket
                        console.log("Creating order on Shiprocket...");
                        const response = await axios.post('https://ufb-1.onrender.com/create-shiprocket-order', updatedOrder, {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        console.log('Order created on Shiprocket successfully:', response.data);
    
                        // Deduct the total cost from the current balance
                        const updatedBalance = currentBalance - (calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder));
    
                        // Update wallet balance
                        console.log("Updating wallet balance...");
                        await updateDoc(walletRef, { balance: updatedBalance });
                        console.log("Wallet balance updated successfully.");
    
                        // Record the transaction
                        const transactionsRef = collection(walletRef, 'transactions');
                        const transactionData = {
                            type: selectedOrder.id,
                            amount: (calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder)),
                            balanceBefore: currentBalance,
                            balanceAfter: updatedBalance,
                            // productName: selectedOrder.order_items.map(item => item.design?.designName).join(', '),
                            timestamp: Timestamp.now(),
                        };

                        
                        console.log("Recording transaction data:", transactionData);
                        await addDoc(transactionsRef, transactionData);
                        console.log("Transaction recorded successfully.");
    
                        // Mark the order as confirmed and paid
                        await updateDoc(orderRef, {
                            UniteeTotalPrice: (calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder)),
                            UniteeShipping: calculateShippingWithGST(selectedOrder),
                            status: 'confirmed',
                            isPaid: true, // Update the order to indicate it's paid
                            invoiceUrl: downloadURL, // Store the invoice URL
                            SalesinvoiceUrl: salesDownloadURL
                        });

                        // if (skuMatchesInventory) {
                        //     await updateDoc(orderRef, {
                        //         RTOinventory: true // Add RTOinventory: true if SKU matches
                        //     });
                        //     console.log("RTOinventory field added successfully.");
                        // }
   
                        const updatedOrderItems = selectedOrder.order_items.map(item => {
                            // Check if the SKU for this item matches the RTO inventory
                            const isRTOInventoryMatched = checkSKUInRTOInventory([item]); // Reuse your existing check function
                        
                            if (isRTOInventoryMatched) {
                                // If the item matches, add the RTOinventory field without modifying the rest of the item
                                item.RTOinventory = true;  // Directly add the RTOinventory field to this item
                            }
                        
                            // Return the item (whether modified or not)
                            return item;
                        });
                        
                        // Step 2: Update the Firestore document
                        await updateDoc(orderRef, {
                            order_items: updatedOrderItems,  // Update only the order_items field with the modified array
                        });
                        
                        console.log("RTOinventory field added to matching items in order_items.");
                      

                        console.log("Order status updated successfully.");
    
                        // Mark the order as confirmed
                        setConfirmedOrders([...confirmedOrders, selectedOrder.id]);
    
                        toast.success('Order confirmed and invoice generated!');
                        setShowConfirmModal(false); // Close modal
                        fetchOrders(); // Refresh the orders
                    } catch (err) {
                        console.error('Error handling completed upload:', err);
                        toast.error('Error handling completed upload.');
                    }
                }
            );
        } catch (error) {
            console.error('Error in confirmOrder function:', error);
    
            // Handle error response specifically for Shiprocket
            if (error.response) {
                console.error('Error response from Shiprocket:', error.response.data);
                toast.error(`Error: ${error.response.data.error || 'An unexpected error occurred.'}`);
            } else {
                console.error('Error confirming order:', error);
                toast.error('An error occurred while confirming the order.');
            }
        }
    };


    


       let totalCost = 0;
    // let shippingCharge = 65;

    if (showConfirmModal && selectedOrder) {
        totalCost = calculateCostWithGST(selectedOrder);
    }




    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <div className='ordersp'>Confirm your orders, and we'll start printing right away!</div>
                    <h1>Orders</h1>

                    {showConfirmModal && selectedOrder && (
                      
                      

                        <div className="modals">
                            <div className="modal-contents">
                                <h3>Confirm Order</h3>
                                <p>
                                    Are you sure you want to confirm the order for{' '}
                                    {selectedOrder.order_items.map(item => item.design?.designName).join(', ')}?
                                </p>
                                <p>
                                    Current Balance: ₹{currentBalance.toFixed(2)}
                                </p>
                                
                                <p>Total Cost: ₹ {(calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder)).toFixed(2)}</p>
                                <p>Balance After Deduction: ₹{(currentBalance - (calculateCostWithGST(selectedOrder) + calculateShippingWithGST(selectedOrder))).toFixed(2)}</p>
                                <button onClick={confirmOrder}>Yes, Confirm</button>
                                <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
                            </div>
                        </div>
                    )}


                    {showCancelModal && (
                        <div className="modals">
                            <div className="modal-contents">
                                <h3>Cancel Order</h3>
                                <p>Are you sure you want to cancel this order?</p>
                                <button onClick={confirmCancelOrder}>Yes, Cancel</button>
                                <button onClick={() => setShowCancelModal(false)}>No</button>
                            </div>
                        </div>
                    )}

                    <ToastContainer />
                    {isModalOpen && <Wallet />}
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th className='hideee'>Unitee Order ID</th>
                                <th>Design Name</th>
                                {/* <th>SKU</th> */}
                                <th className='hideee'>Quantity</th>
                                <th className='hideee'>GSM</th>
                                <th className='hideee'>Selling Price</th>
                                <th className='hideee'>Items Ordered</th>
                                <th>Front Image</th>
                                <th>Back Image</th>
                                <th className='hideee'>Base Cost</th>
                                <th className='hideee'>Print Cost</th>
                                <th className='hideee'>Product Tax</th>
                                <th className='hideee'>Shipping Cost</th>
                                <th className='hideee'>Shipping Tax</th>
                                <th>RTO Charges</th> {/* New Column */}
                                <th>Total Cost</th>
                              
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {paginatedOrders.map((order) => (
                                <tr key={order.id}>
                                    {/* <td>{order.Storeorder_id || 'NA'}</td> */}

                                    <td 
                                        className="order-id-cell" 
                                        onClick={() => openOrderModal(order)}
                                    >
                                        {order.Storeorder_id || 'NA'}
                                    </td>

                                    <td className='hideee'>{order.id}</td> 
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.design?.designName || 'Unknown'}</p>
                                        ))}
                                    </td>
                                    
                                    <td className='hideee'>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.units}</p>
                                        ))}
                                    </td>
                                    <td className='hideee'>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.design?.productGSM || 'N/A'}</p>
                                        ))}
                                    </td>
                                    <td className='hideee'> 
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.selling_price}</p>
                                        ))}
                                    </td>
                                    <td className='hideee'>{order.order_items.reduce((sum, item) => sum + item.units, 0)}</td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>
                                                {item.design?.frontImage ? (
                                                    <img src={item.design.frontImage} alt="Front Design" style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(item.design.frontImage)} />
                                                ) : (
                                                    'N/A'
                                                )}
                                            </p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>
                                                {item.design?.backImage ? (
                                                    <img src={item.design.backImage} alt="Back Design" style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(item.design.backImage)} />
                                                ) : (
                                                    'N/A'
                                                )}
                                            </p>
                                        ))}
                                    </td>

<td className="hideee">
    {order.order_items.map((item, index) => (
        <p key={index}>
            {
                checkSKUInRTOInventory([item]) ? 0 : (item.design?.baseCost || 'N/A')
            }
        </p>
    ))}
</td>


<td className="hideee">
    {order.order_items.map((item, index) => (
        <p key={index}>
            {
                checkSKUInRTOInventory([item]) ? 0 : (item.design?.PrintCost || 'N/A')
            }
        </p>
    ))}
</td>


<td className="hideee">
    {order.order_items.map((item, index) => (
        <p key={index}>
            {
                checkSKUInRTOInventory([item]) ? 0 : ((calculateTotalCostForItem(item) * 0.05).toFixed(2)  || 'N/A')
            }
        </p>
    ))}
</td>



                                    <td className='hideee'>
                                    {calculateShippingCharge(order)}
                                        {/* {order.order_items.map((item) => (
                                            <p key={item.sku}>{calculateShippingCharge(order)}</p> // Static shipping cost
                                        ))} */}
                                    </td>

                                    <td className='hideee'>
                                      {calculateShippingCharge(order) * 0.18}
                                    </td>

                                    
                                    <td>
                                        {checkSKUInRTOInventory(order.order_items) ? 50 : 0}
                                    </td>


                                    <td>
                                        {/* {order.order_items.reduce((sum, item) => sum + (item.design?.totalPrice || 0) * item.units + shippingCharge, 0)}  */}
                                        {(calculateCostWithGST(order) + calculateShippingWithGST(order)).toFixed(2)}
                                    </td>


                                    <td>
                                        <button className="confirm-button" onClick={() => handleConfirmOrder(order.id)} disabled={order.isPaid}>
                                            {order.isPaid ? 'Paid' : 'Confirm'}
                                        </button>
                                        <button className="cancel-button" onClick={() => handleCancelOrderClick(order.id)}>Cancel</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-controls">
                        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </div>
 <ImageModal imageSrc={selectedImage} onClose={handleCloseModal} />
                </div>
                {isOrderModalOpen && selectedOrder && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Order Details</h2>
                            <button className="close-btn" onClick={closeModal}>×</button>
                        </div>
                        
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Store Order ID:</label>
                                <input 
                                    type="text" 
                                    value={editedOrderData.Storeorder_id || ''} 
                                    // onChange={(e) => handleOrderDataChange('Storeorder_id', e.target.value)}
                                />
                            </div>
                            
                            <div className="form-group">
                                <label>Unitee Order ID:</label>
                                <span>{selectedOrder.id}</span>
                            </div>
                            
                            <div className="form-row">
                                {/* <div className="form-group">
                                    <label>Shipping:</label>
                                    <input 
                                        type="number" 
                                        value={editedOrderData.UniteeShipping || 0} 
                                        onChange={(e) => handleOrderDataChange('UniteeShipping', Number(e.target.value))}
                                    />
                                </div> */}
                                
                                {/* <div className="form-group">
                                    <label>Total Price:</label>
                                    <input 
                                        type="number" 
                                        value={editedOrderData.UniteeTotalPrice || 0} 
                                        onChange={(e) => handleOrderDataChange('UniteeTotalPrice', Number(e.target.value))}
                                    />
                                </div> */}
                            </div>
                            
                            <h3>Billing Information</h3>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Customer Name:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_customer_name || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_customer_name', e.target.value)}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>Last Name:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_last_name || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_last_name', e.target.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Email:</label>
                                    <input 
                                        type="email" 
                                        value={editedOrderData.billing_email || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_email', e.target.value)}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>Phone:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_phone || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_phone', e.target.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <label>Address:</label>
                                <input 
                                    type="text" 
                                    value={editedOrderData.billing_address || ''} 
                                    onChange={(e) => handleOrderDataChange('billing_address', e.target.value)}
                                />
                            </div>
                            
                            <div className="form-row">
                                <div className="form-group">
                                    <label>City:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_city || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_city', e.target.value)}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>State:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_state || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_state', e.target.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Pincode:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_pincode || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_pincode', e.target.value)}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>Country:</label>
                                    <input 
                                        type="text" 
                                        value={editedOrderData.billing_country || ''} 
                                        onChange={(e) => handleOrderDataChange('billing_country', e.target.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <label>Payment Method:</label>
                                <input 
                                    type="text" 
                                    value={editedOrderData.payment_method || ''} 
                                    // onChange={(e) => handleOrderDataChange('payment_method', e.target.value)}
                                />
                            </div>
                            
                            {/* <h3>Order Items</h3>
                            {editedOrderData.order_items && editedOrderData.order_items.map((item, index) => (
                                <div key={index} className="order-item">
                                    <h4>Item {index + 1}</h4>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>SKU:</label>
                                            <input 
                                                type="text" 
                                                value={item.sku || ''} 
                                                // onChange={(e) => handleLineItemChange(index, 'sku', e.target.value)}
                                            />
                                        </div>
                                        
                                        <div className="form-group">
                                            <label>Name:</label>
                                            <input 
                                                type="text" 
                                                value={item.name || ''} 
                                                // onChange={(e) => handleLineItemChange(index, 'name', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>Units:</label>
                                            <input 
                                                type="number" 
                                                value={item.units || 0} 
                                                // onChange={(e) => handleLineItemChange(index, 'units', e.target.value)}
                                            />
                                        </div>
                                        
                                        <div className="form-group">
                                            <label>Price:</label>
                                            <input 
                                                type="text" 
                                                value={item.selling_price || ''} 
                                                // onChange={(e) => handleLineItemChange(index, 'selling_price', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))} */}
                        </div>
                        
                        <div className="modal-footer">
                            <button className="cancel-btn" onClick={closeModal}>Cancel</button>
                            <button className="save-btn" onClick={saveOrderChanges}>Save Changes</button>
                        </div>
                    </div>
                    </div>
            )}


            </div>






        </div>
    );
};
export default OrdersPage;

